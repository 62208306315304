import {
  NUMBER_OF_SECONDS_IN_TEN_HOURS,
  NUMBER_OF_SECONDS_IN_THIRTY_DAYS
} from "./magicNumbers";
import timeUtils from "./timeUtils";

const widgetColourStatus = ({
  remaining,
  title,
  calendarDaysAmberLimit = 30,
  calendarSecondsAmberLimit = 36000,
  isDateTime = false,
  nextDue = null,
}: {
  remaining: number;
  title: string;
  calendarDaysAmberLimit?: number;
  calendarSecondsAmberLimit?: number;
  isDateTime?: boolean;
  nextDue?: string;
}): string => {
  if (remaining === null || title === null) return '';

  let colourStatus = 'open';

  if (isDateTime && !nextDue) return 'open';

  if (remaining <= 0) {
    colourStatus = 'overdue';
  } else if (title === 'Cycles' && remaining <= 10) {
    colourStatus = 'critical';
  } else if (
    (title === 'Hours' || title === 'APU Hours') &&
    remaining <= NUMBER_OF_SECONDS_IN_TEN_HOURS
  ) {
    colourStatus = 'critical';
  } else if (title === 'Days') {
    const daysRemaining = remaining;
    const daysLimit = calendarDaysAmberLimit ?? 30;

    if (isDateTime && nextDue) {
      const daysLimitInSeconds =
        (calendarSecondsAmberLimit ?? NUMBER_OF_SECONDS_IN_THIRTY_DAYS);
      const daysRemainingInSeconds = timeUtils.getSecondsFromNow(nextDue);

      if (daysRemainingInSeconds <= daysLimitInSeconds) {
        colourStatus = 'critical';
      }
    } else if (daysRemaining <= daysLimit) {
      colourStatus = 'critical';
    }
  }
  return colourStatus;
};

export default widgetColourStatus;

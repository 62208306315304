import { useQuery } from "@tanstack/react-query";
import { fetchImage } from "../../api";

const useFetchImage = ({ id }: { id: string }): { data: void | Blob; isFetching: boolean } => {
  const { data, isFetching } = useQuery({
    queryKey: ['fetchImage', id],
    queryFn: () => fetchImage(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return { data, isFetching };
};

export default useFetchImage;

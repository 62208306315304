import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { DamageReport } from '../../models/damageReports';
import { Divider, Header, Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import FileViewer from '../../components/TFFileViewer/fileViewer';
import { DamageMap } from '../../models/damageMaps';
import servers from '../../utils/servers';
import DamageReportMarker from '../../components/DamageReportMarker/DamageReportMarker';
import Card from '../../components/DefectDrawer/components/Card';

interface ViewDamageReportDrawerProps {
  damageReport: DamageReport;
  dateFormat: string;
  foundDamageMap: DamageMap | undefined;
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
`;

const SectionHeader = styled.div`
  font-weight: 600;
  color: #000000;
  margin-bottom: 20px;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: ${({ columns }) => (columns ? `${columns}` : '1fr 1fr')};
`;

const ReportedByText = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colours.black};
  margin-bottom: ${({ marginBottom }) => marginBottom ? `${marginBottom}` : '0'};
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const SchematicImage = styled.img`
  width: 100%;
  height: auto;
  opacity: 0.5;
  object-fit: contain;
`;

const ViewDamageReportDrawer: React.FC<ViewDamageReportDrawerProps> = ({
  damageReport,
  dateFormat,
  foundDamageMap
}) => {
  const { formatMessage } = useIntl();

  const imageRef = useRef<HTMLImageElement>(null);

  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  window.addEventListener('resize', () => {
    if (imageRef.current) {
      setImageWidth(imageRef.current.width);
      setImageHeight(imageRef.current.height);
    }
  });

  return (
    <>
      <HeaderWrapper>
        <div data-testid="ViewDamageReportDrawer--Type">
          <Header>{formatMessage({ id: 'text.type' })}</Header>
          <Text capitalize>{damageReport?.type}</Text>
        </div>
        <div data-testid="ViewDamageReportDrawer--Category">
          <Header>{formatMessage({ id: 'text.category' })}</Header>
          <Text capitalize>{damageReport?.category}</Text>
        </div>
        <div data-testid="ViewDamageReportDrawer--Status">
          <Header>{formatMessage({ id: 'text.status' })}</Header>
          <Text capitalize>{damageReport?.status}</Text>
        </div>
        <div data-testid="ViewDamageReportDrawer--ReportedBy">
          <Header>{formatMessage({ id: 'text.reportedBy' })}</Header>
          <Text>{damageReport?.reported_by}</Text>
        </div>
        <div data-testid="ViewDamageReportDrawer--ReportedOn">
          <Header>{formatMessage({ id: 'text.reportedOn' })}</Header>
          <Text>{damageReport?.created_at ? moment(damageReport.created_at).format(dateFormat) : '-'}</Text>
        </div>
      </HeaderWrapper>
      <Card marginBottom={20} identifier="DamageReportWrapper">
        <SectionHeader data-testid="ViewDamageReportDrawer--SectionHeader">
          {formatMessage({ id: 'text.damageReportDetails' })}
        </SectionHeader>
        <SectionWrapper>
          <div data-testid="ViewDamageReportDrawer--Details">
            <Header>{formatMessage({ id: 'text.details' })}</Header>
            <Text>{damageReport?.details}</Text>
          </div>
        </SectionWrapper>
        <SectionWrapper columns="1fr" data-testid="ViewDamageReportDrawer--Attachments">
          <div>
            <Header>{formatMessage({ id: 'text.attachments' })}:</Header>
            <FileViewer
              attachments={damageReport?.attachments || []}
              allowDelete={false}
              disableAddition
              uploaderVisible={false}
            />
          </div>
        </SectionWrapper>
        <Divider />
        <ReportedByText marginBottom="20px" data-testid="ViewDamageReportDrawer--SchematicHeading">
          {formatMessage({ id: 'text.schematic' })}: {foundDamageMap?.title}
        </ReportedByText>
        <ImageWrapper data-testid="ViewDamageReportDrawer--SchematicImage">
          {damageReport && isImageLoaded ? (
            <DamageReportMarker
              report={damageReport}
              zoomDimensions={{ height: imageHeight, width: imageWidth }}
              onReportCoordinatesChanged={null}
              handlePointClick={null}
              draggable={false}
              clickable={false}
            />
          ) : null}
          <SchematicImage
            ref={imageRef}
            src={`${servers.api}/${foundDamageMap?.attachment?.url}`}
            alt="schematic"
            $isLoaded={isImageLoaded}
            onLoad={() => {
              const image = imageRef.current;
              if (image) {
                setImageWidth(image.width);
                setImageHeight(image.height);
                setIsImageLoaded(true);
              }
            }}
          />
        </ImageWrapper>
      </Card>
    </>
  );
};

export default ViewDamageReportDrawer;

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@arcflight/tf-component-library';
import { useDispatch } from 'react-redux';
import { Divider, SectionHeader } from '../../CommonStyledComponents/CommonStyledComponents';
import { Defect, DefectCategory, DefectType } from '../../../models/defects';
import useDefectDisplayDate from '../../../utils/useDefectDisplayDate';
import { approveDefectProcess, approveMxEventProcess } from '../../../services/apiNew';
import { changeModalVisibility, changeModalContent } from '../../../models/drawer';
import { getDefectForDrawer, update } from '../../../models/defects/actions';
import { addToast, ToastCategories, ToastTypes } from '../../../models/toasts';
import ViewDefectDrawerRectificationLimits from './ViewDefectDrawerRectificationLimits';
import DefectField from './DefectField';
import DisplayMelTable from './DisplayMelTable';

interface ViewDefectDrawerDeferralOptionsProps {
  defectData: Defect;
  requiredSettings: {
    defectRename: string;
    melNameOverride: string;
    cdlNameOverride: string;
    nefNameOverride: string;
    casNameOverride: string;
    otherNameOverride: string;
    showRichTextMel: boolean;
    resolutionRename: string;
    currentLoginId: string;
    currentUserId: string;
  };
}

const Wrapper = styled.div`
  margin-top: 20px;
`;

const ApprovalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 25px 0;
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px 10px;
  margin: 20px 0;
  @media (max-width: 451px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ViewDefectDrawerDeferralOptions: React.FC<ViewDefectDrawerDeferralOptionsProps> = ({
  defectData,
  requiredSettings,
}) => {
  const [defectTypeName, setDefectTypeName] = useState<string>('');
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const newDeferredUntilDate = useDefectDisplayDate({ defect: defectData, ignoreDatetimeDue: false });

  const isDefectTypeCDL = defectData?.defect_type === DefectType.CDL;
  const linkedMaintenanceItemId = defectData?.linked_scheduled_mx_item_with_m_procedure?.id;
  const linkedMaintenanceItemStatus = defectData?.linked_scheduled_mx_item_with_m_procedure?.status;
  const linkedMaintenanceApprovalReleaseRequired =
    defectData?.linked_scheduled_mx_item_with_m_procedure?.approval_release_required;
  const linkedMaintenanceHasApprovalRelease =
    defectData?.linked_scheduled_mx_item_with_m_procedure?.has_approval_release;

  const isApproveDefferalDisabled =
    (linkedMaintenanceItemId && linkedMaintenanceItemStatus !== 'resolved') ||
    (linkedMaintenanceApprovalReleaseRequired && !linkedMaintenanceHasApprovalRelease);

  const fieldsToDisplay = [
    {
      formatMessageId: 'text.defectTypeCaps',
      value: defectData?.deferred
        ? formatMessage({ id: 'text.deferred' })
        : formatMessage({ id: 'text.notDeferred' }),
      formatMessageOverride: requiredSettings.defectRename,
    },
    { formatMessageId: 'text.deferralOptionsCaps', value: defectTypeName },
    { formatMessageId: 'title.categoryCaps', value: defectData?.display_data?.category },
    { formatMessageId: 'title.ataChapterCaps', value: defectData?.display_data?.ata },
    { formatMessageId: 'title.ataSectionCaps', value: defectData?.display_data?.ata_section?.toString() },
    { formatMessageId: 'text.subSection', value: defectData?.book_item?.subsection_number?.toString() || '-' },
    { formatMessageId: 'text.subSubSection', value: defectData?.book_item?.subsubsection?.toString() || '-' },
    { formatMessageId: 'title.referenceCaps', value: defectData?.reference || '-' },
  ];

  if (defectData?.deferred) {
    fieldsToDisplay.push({ formatMessageId: 'title.deferredUntil', value: `${newDeferredUntilDate}z` });
  }

  if (isDefectTypeCDL) {
    fieldsToDisplay.push(
      { formatMessageId: 'text.enrouteClimb', value: defectData?.rectification?.enroute_climb },
      { formatMessageId: 'text.maxDecrementPerWing', value: defectData?.rectification?.max_decrement_per_wing },
      { formatMessageId: 'text.approachAndLanding', value: defectData?.rectification?.approach_and_landing },
      { formatMessageId: 'text.takeoff', value: defectData?.rectification?.takeoff },
    );
  }

  useEffect(() => {
    const defectTypeRename = () => {
      const typeMap: { [key: string]: string } = {
        MEL: requiredSettings.melNameOverride,
        CDL: requiredSettings.cdlNameOverride,
        NEF: requiredSettings.nefNameOverride,
        CAS: requiredSettings.casNameOverride,
        Other: requiredSettings.otherNameOverride,
      };
      return typeMap[defectData?.defect_type] || defectData?.defect_type || '-';
    };
    setDefectTypeName(defectTypeRename());
  }, [
    defectData?.defect_type,
    requiredSettings.casNameOverride,
    requiredSettings.cdlNameOverride,
    requiredSettings.melNameOverride,
    requiredSettings.nefNameOverride,
    requiredSettings.otherNameOverride,
  ]);

  const displayApprovalConfirmation = () => {
    const deferredById = defectData.deferred_by?.id;
    const saveAction = async () => {
      try {
        const response = await approveDefectProcess(defectData.id, 'deferral');

        if (response.status === 200) {
          await dispatch(update({ payload: response.data }));
          dispatch(changeModalVisibility({ payload: false }));
          queryClient.invalidateQueries({ queryKey: ['defectDrawer'] });
        } else {
          throw new Error();
        }
      } catch (error) {
        console.error(error);

        const message = error.response?.data?.error || formatMessage({ id: 'text.genericErrorMessage' });

        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'text.unableToApproveDeferral' }),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLAG,
              message,
            },
          }),
        );
        dispatch(changeModalVisibility({ payload: false }));
      }
    };

    dispatch(changeModalVisibility({ payload: true }));
    if (deferredById === requiredSettings.currentUserId) {
      dispatch(
        changeModalContent({
          payload: {
            title: formatMessage({ id: 'text.unableToApproveDeferral' }),
            text: formatMessage({ id: 'text.youAreUnableToApproveDeferral' }),
            saveButtonText: formatMessage({ id: 'text.close' }),
            saveAction: () => dispatch(changeModalVisibility({ payload: false })),
            hideCancel: true,
          },
        }),
      );
    } else {
      dispatch(
        changeModalContent({
          payload: {
            title: formatMessage({ id: 'text.approveDeferral' }),
            text: formatMessage({ id: 'text.approvalConfirmText' }),
            saveButtonText: formatMessage({ id: 'text.approve' }),
            saveAction,
            cancelAction: () => dispatch(changeModalVisibility({ payload: false })),
          },
        }),
      );
    };
  };

  const displayApprovalResolutionConfirmation = () => {
    const resolvedById = defectData.mx_event_created_by_id;
    const saveAction = async () => {
      try {
        const response = await approveMxEventProcess(defectData?.mx_events[0]?.id, 'completion');

        if (response.status === 200) {
          dispatch(getDefectForDrawer({ id: defectData?.id }));
          dispatch(changeModalVisibility({ payload: false }));
          queryClient.invalidateQueries({ queryKey: ['defectDrawer'] });
        } else {
          throw new Error();
        }
      } catch (error) {
        console.error(error);

        const message = error.response?.data?.error || formatMessage({ id: 'text.genericErrorMessage' });

        dispatch(
          addToast({
            payload: {
              title: formatMessage(
                { id: 'text.unableToApproveResolution' },
                { field: requiredSettings?.resolutionRename?.toLocaleLowerCase() }
              ),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLAG,
              message,
            },
          }),
        );
        dispatch(changeModalVisibility({ payload: false }));
      }
    };

    dispatch(changeModalVisibility({ payload: true }));
    if (resolvedById === requiredSettings.currentLoginId) {
      dispatch(
        changeModalContent({
          payload: {
            title: formatMessage(
              { id: 'text.unableToApproveResolution' },
              { field: requiredSettings?.resolutionRename?.toLocaleLowerCase() }
            ),
            text: formatMessage(
              { id: 'text.youAreUnableToApproveResolution' },
              { field: requiredSettings?.resolutionRename?.toLocaleLowerCase() }
            ),
            saveButtonText: formatMessage({ id: 'text.close' }),
            saveAction: () => dispatch(changeModalVisibility({ payload: false })),
            hideCancel: true,
          },
        })
      );
    } else {
      dispatch(
        changeModalContent({
          payload: {
            title: formatMessage(
              { id: 'text.approveResolution' },
              { field: requiredSettings?.resolutionRename }
            ),
            text: formatMessage(
              { id: 'text.approvalConfirmTextGeneric' },
              { field: requiredSettings?.resolutionRename }
            ),
            saveButtonText: formatMessage({ id: 'text.approve' }),
            saveAction,
            cancelAction: () => dispatch(changeModalVisibility({ payload: false })),
          },
      }),
      );
    };
  };

  return (
    <Wrapper data-testid="ViewDefectDrawerDeferralOptions">
      <SectionHeader data-testid="ViewDefectDrawerDeferralOptions-Header">
        {formatMessage({ id: 'text.deferralOptions' })}
      </SectionHeader>
      <GridSection data-testid="ViewDefectDrawerDeferralOptions-Grid">
        {fieldsToDisplay.map((details) => (
          <DefectField
            key={details.formatMessageId}
            formatMessageId={details.formatMessageId}
            value={details.value}
            formatMessageOverride={details.formatMessageOverride}
          />
        ))}
      </GridSection>
      {defectData?.display_data?.category === DefectCategory.A && (
        <ViewDefectDrawerRectificationLimits defectData={defectData} />
      )}
      {defectData?.show_approve_deferral && (
        <ApprovalButtonWrapper data-testid="ViewDefectDrawerDeferralOptions-ApproveDeferralButtonWrapper">
          <Button onClick={displayApprovalConfirmation} disabled={isApproveDefferalDisabled}>
            {formatMessage({ id: 'text.approveDeferral' })}
          </Button>
        </ApprovalButtonWrapper>
      )}
      {defectData?.show_approve_resolution && (
        <ApprovalButtonWrapper>
          <Button onClick={displayApprovalResolutionConfirmation}>
            {formatMessage({ id: 'text.approveResolution' }, { field: requiredSettings?.resolutionRename })}
          </Button>
        </ApprovalButtonWrapper>
      )}
      <Divider />
      {defectData?.book_item?.id && (
        <DisplayMelTable defectData={defectData} requiredSettings={requiredSettings} />
      )}
    </Wrapper>
  );
};

export default ViewDefectDrawerDeferralOptions;

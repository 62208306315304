import { useQuery } from '@tanstack/react-query';
import { getWorkpack } from '../../services/api';
import { Workpack } from '../../models/workpacks';

const useGetSingleWorkpack = ({ id }: { id: string }): { data: Workpack; isLoading: boolean } => {
  const { data, isLoading } = useQuery({
    queryKey: ['queryWorkpack', id],
    queryFn: () => {
      return getWorkpack(id);
    },
    enabled: !!id,
  });

  return { data, isLoading };
};

export default useGetSingleWorkpack;

import { useQuery } from '@tanstack/react-query';
import { queryFlightsOnly } from '../../api';
import { Flight } from '../../../models/flights';

const useQueryFlightsForAircraft = ({
  payload,
  enabled = true,
  refetchOnWindowFocus = true,
  staleTime = 0,
}: {
  payload: { id: string };
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  staleTime?: number;
}): {
  data: Flight[];
  isFetching: boolean;
  isLoading: boolean;
} => {
  const { data, isFetching, isLoading } = useQuery({
    queryKey: ['flights', payload],
    queryFn: () => queryFlightsOnly(payload),
    refetchOnWindowFocus,
    staleTime,
    enabled,
  });

  return { data, isFetching, isLoading };
};

export default useQueryFlightsForAircraft;

import { Modal, Button as TFButton } from '@arcflight/tf-component-library';
import styled from 'styled-components';
import React, { useState } from 'react';
import { Workpack } from '../../models/workpacks';
import { Aircraft } from '../../models/aircraft';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import SlidingDrawer from '../SlidingDrawer';
import WorkpackDrawerContents from './WorkpackDrawerContents';

interface WorkpackDrawerProps {
  wp: Workpack;
  pendingWps: Workpack[];
  ac: Aircraft[];
  initialMode: string;
  wpId: string;
  handleDeleteWorkpack: () => void;
  toggleDrawer: () => void;
  updateWorkpackid: (id: string) => void;
  handleSetCurrentWorkpack: (workpack: Workpack) => void;
  bannerVisible: boolean;
  handleCRSClicked: () => void;
  crsClicked: boolean;
}

const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  display: block;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
`;

const Text = styled.div`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .cancelButton {
    height: 30px;
    border-radius: 15px;
    margin-left: 20px;
  }
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 20px;
`;

const WorkPackDrawer: React.FC<WorkpackDrawerProps> = ({
  initialMode,
  toggleDrawer,
  wp,
  pendingWps,
  ac,
  wpId,
  handleDeleteWorkpack,
  updateWorkpackid,
  handleSetCurrentWorkpack,
  bannerVisible,
  handleCRSClicked,
  crsClicked,
}) => {
  const [unsavedData, setUnsavedData] = useState(false);
  const [reCloserDrawer, setReCloseDrawer] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleUnsavedData = (value): void => {
    setUnsavedData(value);
  };

  const reCloseDrawer = (): void => {
    setUnsavedData(false);
    setReCloseDrawer(true);
    setTimeout(() => {
      setReCloseDrawer(false);
    }, 200);
  };

  const handleDrawerToggle = (): void => {
    if (unsavedData) {
      setModalVisible(true);
    } else {
      toggleDrawer();
    }
  };

  return (
    <SlidingDrawer
      toggleDrawer={handleDrawerToggle}
      bannerVisible={bannerVisible}
      unsavedData={unsavedData}
      reCloseDrawer={reCloserDrawer}
    >
      <WorkpackDrawerContents
        wp={wp}
        pendingWps={pendingWps}
        ac={ac}
        initialMode={initialMode}
        wpId={wpId}
        handleDeleteWorkpack={handleDeleteWorkpack}
        toggleDrawer={toggleDrawer}
        updateWorkpackid={updateWorkpackid}
        handleUnsavedData={handleUnsavedData}
        setModalVisible={setModalVisible}
        handleSetCurrentWorkpack={handleSetCurrentWorkpack}
        handleCRSClicked={handleCRSClicked}
        crsClicked={crsClicked}
      />
      <Modal width={420} isOpen={modalVisible} handleClose={(): void => setModalVisible(false)}>
        <ModalWrapper>
          <Title>Are you sure you want to cancel?</Title>
          <Text>
            Are you sure you want to leave this page without saving your changes? If you leave before saving, your
            changes will be lost.
          </Text>
          <ButtonWrapper>
            <SubmitButtonWrapper>
              <TFButton padding="0 28px" size={ButtonSize.MEDIUM} onClick={(): void => reCloseDrawer()}>
                Discard changes
              </TFButton>
            </SubmitButtonWrapper>
            <TFButton
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={(): void => setModalVisible(false)}
            >
              Cancel
            </TFButton>
          </ButtonWrapper>
        </ModalWrapper>
      </Modal>
    </SlidingDrawer>
  );
};

export default WorkPackDrawer;

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import chevron from '../../assets/icon-dropdown-arrow-down.svg';
import SearchIcon from '../../assets/icon-search.svg';
import IconClose from '../../assets/icon-button-close.svg';
import TFInput, { Status, StatusIconMap } from '../TFInput/TFInput';
import TFFormLabel from '../TFFormLabel/TFFormLabel';
import TFPortal from '../Portal/Portal';

const DropdownInputWrapper = styled.div`
  height: 40px;
  padding: ${({ readOnly }): string => (readOnly ? '12px 32px 12px 0px' : '12px 32px 12px 8px')};
  border-radius: 2px;
  border: ${({ theme }): string => `solid 1px ${theme.colours.black10Alpha}`};
  background-color: ${({ theme }): string => theme.colours.softGray};
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: -0.4px;
  color: ${({ theme }): string => theme.colours.black90Alpha};
  outline: none;
  width: 100%;
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover {
    border-color: ${({ theme }): string => theme.colours.brandBlue40Alpha};
  }
  &:focus {
    border-color: ${({ theme }): string => theme.colours.blue};
  }
  &.error {
    border-color: ${({ theme }): string => theme.colours.red};
    background-color: ${({ theme }): string => theme.colours.red02Alpha};
    padding-right: 52px;
    &:hover {
      background-color: ${({ theme }): string => theme.colours.red07Alpha};
    }
  }
  &.valid {
    border-color: ${({ theme }): string => theme.colours.black50Alpha};
    padding-right: 52px;
  }
  &.warning {
    border-color: ${({ theme }): string => theme.colours.orange};
    background-color: ${({ theme }): string => theme.colours.orange02Alpha};
    padding-right: 52px;
    &:hover {
      background-color: ${({ theme }): string => theme.colours.orange07Alpha};
    }
  }
  /* Additional styles for disabled prop */
  ${({ disabled, theme }: { disabled?: boolean; theme: any }): string =>
    disabled &&
    `
    cursor: not-allowed;
    background-color: ${theme.colours.black05Alpha};
    color: ${theme.colours.black70Alpha};
    border-color: ${theme.colours.black20Alpha};
    &:hover {
      border-color: ${theme.colours.black10Alpha};
    }
  `}
  /* Additional styles for readOnly prop */
  ${({ readOnly, theme }: { readOnly?: boolean; theme: any }): string =>
    readOnly &&
    `
    border: none;
    background-color: unset;
    &:hover {
      border-color: ${theme.colours.black10Alpha};
    }
    &:focus {
      border-color: ${theme.colours.black10Alpha};
    }
  `}
`;

const DropdownWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

const InvisibleBackground = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 501;
  cursor: pointer;
`;

const DropdownOptions = styled.div`
  position: absolute;
  top: 40px;
  max-height: 200px;
  overflow-y: auto;
  background-color: ${({ theme }): string => theme.colours.snow};
  border: ${({ theme }): string => `solid 2px ${theme.colours.white}`};
  padding: 13px 1px 8px;
  border-radius: 4px;
  box-shadow: ${({ theme }): string => `0 0 10px 0 ${theme.colours.black10Alpha}`};
  z-index: 502;
`;

const DropdownOption = styled.div`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }): string => theme.colours.softGray};
    color: ${({ theme }): string => theme.colours.blue};
  }
  ${({ selected, theme }: { selected?: boolean; theme: any }): string =>
    selected &&
    `
    background-color: ${theme.colours.blue};
    color: ${theme.colours.white};
  `}
`;

const InputFeedback = styled.div`
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.17;
  letter-spacing: -0.4px;
  &.error {
    color: ${({ theme }): string => theme.colours.red};
  }
  &.valid {
    color: ${({ theme }): string => theme.colours.green};
  }
  &.warning {
    color: ${({ theme }): string => theme.colours.orange};
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 36px;
  transform: translateY(-50%);
`;

const ChevronContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
`;

interface DropdownProps {
  options: { title: string; value: string }[];
  onSelect: (value: { title: string; value: string | null }) => void;
  id: string;
  label?: string;
  initialValue?: { title: string; value: string };
  disabled?: boolean;
  readOnly?: boolean;
  status?: Status;
  statusMessage?: string;
  optional?: boolean;
  searchable?: boolean;
  onSearch?: (value) => void;
  placeholder?: string;
  tooltip?: string;
  onClose?: () => void;
}

const ChevronIcon = styled.img`
  transform: ${({ isOpen }): string => (isOpen ? 'rotate(180deg)' : '')};
`;

// Height from the top of the dropdown to where the options are displayed
const HEIGHT_FROM_TOP_OF_DROPDOWN = 44;

const TFDropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  label,
  optional,
  id,
  status,
  statusMessage,
  disabled,
  readOnly,
  initialValue,
  searchable,
  onSearch,
  onClose,
  placeholder,
  tooltip,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState<{ title: string; value: string | null }>(
    initialValue || {
      title: placeholder || 'None',
      value: null,
    },
  );
  const [menuStyle, setMenuStyle] = useState({});
  const [backgroundStyle, setBackgroundStyle] = useState({});

  const buttonRef = useRef(null);

  useEffect(() => {
    if (options) setFilteredOptions(options);
  }, [options]);

  function useOutsideAlerter(ref): void {
    useEffect(() => {
      function handleClickOutside(event): void {
        if (ref.current && !ref.current.contains(event.target)) {
          // Below code checks if users click is on the same dropdown container
          // In this case we dont want to close it or else the onClick function
          // which is called at the same time would set it back to open.
          // If click is anywhere else on screen then setIsOpen to false to close.
          const clickedTestId = event.target.dataset.testid;
          if (clickedTestId === `TFDropdown-Container-${id}`) {
            return;
          }
          if (searchable && onSearch) {
            onClose();
          }
          setSearchTerm('');
          setIsOpen(false);
          setFilteredOptions(options);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return (): void => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleSelectOption = (option: { title: string; value: string | null }): void => {
    onSelect(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleButtonClick = (): void => {
    setIsOpen(!isOpen);
    setSearchTerm('');
    setFilteredOptions(options);
    const rect = buttonRef.current.getBoundingClientRect();
    const { top, left, width } = rect;
    setMenuStyle({
      position: 'absolute',
      top: top + HEIGHT_FROM_TOP_OF_DROPDOWN + window.scrollY,
      left: left + 1 + window.scrollX,
      minWidth: width,
    });
    setBackgroundStyle({ top: 0 + window.scrollY, left: 0 + window.scrollX });
  };

  useEffect(() => {
    if (initialValue) {
      setSelectedOption(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  return (
    <DropdownWrapper>
      {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
      <InputContainer>
        <DropdownInputWrapper
          data-testid={`TFDropdown-Input-Wrapper-${id}`}
          readOnly={readOnly}
          disabled={disabled}
          className={status}
          onMouseEnter={(): void => setIsHovered(true)}
          onMouseLeave={(): void => setIsHovered(false)}
          role="button"
          tabIndex={0}
          ref={buttonRef}
          onClick={
            disabled || readOnly
              ? undefined
              : (): void => {
                  handleButtonClick();
                }
          }
          onKeyDown={(event): void => {
            if (event.key === 'Enter') {
              handleButtonClick();
            }
          }}
        >
          <div data-testid={`TFDropdown-Container-${id}`} className={status}>
            {selectedOption ? selectedOption.title : 'None'}
          </div>
          {status && (
            <IconContainer>
              <img data-testid={`TFDropdown-Icon-${id}`} src={StatusIconMap[status]} alt="info icon" />
            </IconContainer>
          )}
          {((!selectedOption.value && !readOnly) || (!isHovered && !readOnly && selectedOption.value)) && (
            <ChevronContainer>
              <ChevronIcon data-testid={`TFDropdown-Chevron-${id}`} src={chevron} isOpen={isOpen} alt="dropdown icon" />
            </ChevronContainer>
          )}
          {!readOnly && selectedOption.value !== null && selectedOption.value !== undefined && isHovered && (
            <ChevronContainer
              onClick={(): void => {
                setSelectedOption(
                  initialValue || {
                    title: placeholder || 'None',
                    value: null,
                  },
                );
              }}
            >
              <img data-testid={`TFDropdown-X-${id}`} src={IconClose} alt="x icon" />
            </ChevronContainer>
          )}
        </DropdownInputWrapper>
        {isOpen && (
          <TFPortal>
            <InvisibleBackground
              style={backgroundStyle}
              onClick={(e): void => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            />
            <DropdownOptions ref={wrapperRef} style={menuStyle} data-testid={`TFDropdown-Options-${id}`}>
              {searchable && (
                <DropdownOption>
                  <TFInput
                    id={id}
                    placeholder="Search cases..."
                    onChange={(e): void => {
                      setSearchTerm(e.target.value);
                      if (onSearch) {
                        onSearch(e.target.value);
                      } else {
                        setFilteredOptions(
                          options.filter((option) => option.title.toLowerCase().includes(e.target.value.toLowerCase())),
                        );
                      }
                    }}
                    value={searchTerm}
                    leftIcon={SearchIcon}
                  />
                </DropdownOption>
              )}
              {filteredOptions.map((option) => (
                <DropdownOption
                  key={option.value}
                  data-testid={`TFDropdown-Option-${option.value}-${id}`}
                  selected={selectedOption.value === option.value}
                  onClick={(): void => handleSelectOption(option)}
                >
                  {option.title}
                </DropdownOption>
              ))}
            </DropdownOptions>
          </TFPortal>
        )}
      </InputContainer>

      {!isOpen && statusMessage && (
        <InputFeedback data-testid={`TFDropdown-Feedback-${id}`} className={status}>
          {statusMessage}
        </InputFeedback>
      )}
    </DropdownWrapper>
  );
};

TFDropdown.defaultProps = {
  label: '',
  initialValue: null,
  disabled: false,
  readOnly: false,
  status: null,
  statusMessage: '',
  optional: false,
  searchable: false,
  onSearch: null,
  placeholder: 'None',
  tooltip: '',
  onClose: null,
};

export default TFDropdown;

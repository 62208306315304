import React, { ReactElement, ReactNode, useMemo } from 'react';
import { Table } from '@arcflight/tf-component-library';
import styled from 'styled-components';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import TFTag from '../../components/TFTag/TFTag';
import { DashboardState } from '../../models';
import FileViewer from '../../components/TFFileViewer/fileViewer';
import ToolTip from '../../components/NewTooltip/NewTooltip';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import useMutationDeleteDamageReport from '../../services/hooks/damageMaps/useMutationDeleteDamageReport';
import { DamageReport } from '../../models/damageReports';
import { DamageMap } from '../../models/damageMaps';
import { AircraftResource } from '../../models/aircraft';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import DamageReportDrawer from './DamageReportDrawer';

interface DentAndBuckleTableProps {
  data: DamageReport[];
  damageMapsData: DamageMap[];
  total: number;
  pageIndex: number;
  pageSize: number;
  onPaginationChange: (currentPage: number, numberOfItems: number) => void;
  handleSortChange: (value) => void;
  aircraftId: string;
}

const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ status, theme }) => {
    if (status === 'reported') return theme.colours.red;
    if (status === 'approved') return theme.colours.green;
    return theme.colours.amber;
  }};
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  margin-top: 10px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SectionWrapper = styled.div`
  padding: 10px 0 10px 10px;
`;

const Capitalise = styled.span`
  text-transform: capitalize;
`;

const DentAndBuckleTable: React.FC<DentAndBuckleTableProps> = ({
  data,
  damageMapsData,
  total,
  pageIndex,
  pageSize,
  onPaginationChange,
  handleSortChange,
  aircraftId,
}) => {
  const { userSettings: { dateFormat } } = useSelector((state: DashboardState) => state);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const deleteReport = useMutationDeleteDamageReport();

  const handleMakeDrawerVisibleClick = (report: DamageReport, type: string) => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({
      payload: { content: <DamageReportDrawer damageReport={report} damageMaps={damageMapsData} /> },
    }));
    if (type === 'edit') {
      dispatch(changeDrawerMode({ payload: type }));
    }
  }

  const handleDeleteDamageReport = (id: string): void => {
    deleteReport.mutate(id);
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'type',
        Header: formatMessage({ id: 'text.type' }),
        Cell: ({ value, row }): ReactElement => {
          let tagColor = 'amber';
          if (row.original.status === 'reported') {
            tagColor = 'red';
          } else if (row.original.status === 'approved') {
            tagColor = 'green';
          }
          return (
            <SectionWrapper>
              <TypeWrapper status={row.original.status}>
                {value}
                <TFTag colour={tagColor} margin="0 0 0 8px">
                  {row.original.status}
                </TFTag>
              </TypeWrapper>
              <TooltipWrapper>
                <DetailsWrapper>
                  {row.original.details}
                </DetailsWrapper>
                {row.original.details?.length > 34 && <ToolTip text={row.original.details} />}
              </TooltipWrapper>
            </SectionWrapper>
          )
        },
      },
      {
        accessor: 'category',
        Header: formatMessage({ id: 'text.area' }),
        Cell: ({ value }) => {
          return value ?  <Capitalise>{value}</Capitalise> : '-';
        }
      },
      {
        accessor: 'created_at',
        Header: formatMessage({ id: 'text.dateReportedOn' }),
        Cell: ({ value }): string => {
          if (value) {
            return moment(value).format(dateFormat);
          }
          return '-';
        },
      },
      {
        accessor: 'attachments',
        Header: formatMessage({ id: 'text.attachments' }),
        Cell: ({ value }) => {
          return (
            <FileViewer
              attachments={value}
              allowDelete={false}
              disableAddition
              uploaderVisible={false}
            />
          );
        },
      },
      {
        Header: '',
        id: '3dot',
        width: 20,
        Cell: ({ row }): ReactNode => {
          const isReportedStatus = row.original.status === 'reported';
          return (
            <>
              <AuthDropdownMenu
                handleDelete={() => handleDeleteDamageReport(row.original.id)}
                resource={AircraftResource.DAMAGE_MAP}
                editCallback={() => handleMakeDrawerVisibleClick(row.original, 'edit')}
                viewCallback={() => handleMakeDrawerVisibleClick(row.original, 'view')}
                aircraftId={aircraftId}
                options={{ read: true, update: isReportedStatus, delete: isReportedStatus }}
                forTable
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableInstance = useTable({
    columns,
    data,
    manualPagination: true,
    manualSortBy: true,
    initialState: { pageIndex, pageSize },
    pageCount: Math.ceil(total / (pageSize || 10)),
  }, useSortBy, usePagination);

  return (
    <Table
      tableInstance={tableInstance}
      total={total}
      handlePaginationChange={onPaginationChange}
      passedPageSize={pageSize}
      handleSortChange={handleSortChange}
      handleRowClick={(row): void => handleMakeDrawerVisibleClick(row.original, 'view')}
    />
  );
};

export default DentAndBuckleTable;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import TFNumberInput from '../../components/TFNumberInput/TFNumberInput';
import TFAddRemoveDropdown from '../../components/TFAddRemoveDropdown/TFAddRemoveDropdown';
import convertArrayToKeyValuePairs from '../../utils/convertArrayToKeyValuePairs';
import { CustomField } from '../../models/aircraft';
import { DataValue } from '../../utils/updateLocalDataObject';

interface CustomFieldsEditFormattingProps {
  customFieldsData: CustomField;
  updateCustomFieldsData: (changes: { value: DataValue; key: string }[]) => void;
  fieldDisplayArray: string[];
  validationErrors: string[];
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const CustomFieldsEditFormatting: React.FC<CustomFieldsEditFormattingProps> = ({
  customFieldsData,
  updateCustomFieldsData,
  fieldDisplayArray,
  validationErrors,
}) => {
  const [localOptions, setLocalOptions] = useState([]);

  const { formatMessage } = useIntl();

  const handleAddRemoveOption = (option: { title: string; value: string }): void => {
    const newOptions = _.cloneDeep(localOptions);
    const index = newOptions?.findIndex((op) => op.value === option.value);
    if (index === -1) {
      newOptions.push(option);
    } else {
      newOptions.splice(index, 1);
    }
    const optionValues: string[] = newOptions.map((item: { title: string; value: string }) => item.value);
    const formattedOptions = convertArrayToKeyValuePairs(optionValues);
    updateCustomFieldsData([{ key: 'options', value: formattedOptions }]);
  };

  useEffect(() => {
    if (customFieldsData?.options) {
      const formattedOptions = Object.values(customFieldsData?.options);
      const builtOptions = formattedOptions.map((item) => {
        return { title: item, value: item };
      });
      setLocalOptions(builtOptions);
    }
  }, [customFieldsData]);

  return (
    <Wrapper data-testid="CustomFieldsEditFormatting--Wrapper">
      {fieldDisplayArray?.includes('precision') && (
        <TFNumberInput
          id="Formatting-Precision"
          value={customFieldsData?.precision}
          onChange={(value: number): void => updateCustomFieldsData([{ key: 'precision', value }])}
          label="Precision"
          tooltip={formatMessage({ id: 'text.numberOfDecimalPlacesRecorded' })}
          optional
        />
      )}
      {fieldDisplayArray?.includes('options') && (
        <TFAddRemoveDropdown
          id="Formatting-Options"
          options={localOptions}
          initialValues={localOptions}
          onSelect={(value): void => handleAddRemoveOption(value)}
          onAddRemove={(value): void => handleAddRemoveOption(value)}
          label="Options"
          status={validationErrors.includes('options') ? 'error' : null}
          statusMessage={validationErrors.includes('options') ? 'You must select at least one option' : null}
        />
      )}
      {fieldDisplayArray?.includes('min') && (
        <TFNumberInput
          id="Formatting-Min"
          value={customFieldsData?.min}
          onChange={(e): void => updateCustomFieldsData([{ key: 'min', value: e }])}
          label="Minimum value"
          tooltip={formatMessage({ id: 'text.smallestNumberForTheField' })}
          optional
        />
      )}
      {fieldDisplayArray?.includes('max') && (
        <TFNumberInput
          id="Formatting-Max"
          value={customFieldsData?.max}
          onChange={(e): void => updateCustomFieldsData([{ key: 'max', value: e }])}
          label="Maximum value"
          tooltip={formatMessage({ id: 'text.largestNumberForTheField' })}
          optional
        />
      )}
    </Wrapper>
  );
};

export default CustomFieldsEditFormatting;

import React, { ReactElement } from 'react';
import { DefectPartsReplacedFields } from '../../../models/defects';
import { Header } from '../../CommonStyledComponents/CommonStyledComponents';
import TFInput from '../../TFInput/TFInput';
import { stringToTitleCase } from '../../../utils/utils';

interface PartChangesSectionProps {
  requiredSettings: {
    positionEnabled: boolean;
    batchOnEnabled: boolean;
    batchOffEnabled: boolean;
    positionRename: string;
    batchOnRename: string;
    batchOffRename: string;
  },
  mode: string;
  partChanges: Record<string, string>;
  handlePartChange?: (value: string, field: string) => void;
  dataTestId?: string;
}

const PartChangesSection = ({
  requiredSettings,
  mode,
  partChanges,
  handlePartChange,
  dataTestId
}: PartChangesSectionProps): ReactElement => {
  const fields = Object.values(DefectPartsReplacedFields);

  return (
    <>
      {fields.map((field) => {
        let label = stringToTitleCase(field.replace(/_/g, ' '));
        if (field === DefectPartsReplacedFields.PART_DESCRIPTION) {
          return null;
        }
        if (field === DefectPartsReplacedFields.PART_NUMBER_OFF) {
          label = 'Part Number Off';
        }
        if (field === DefectPartsReplacedFields.POSITION) {
          label = requiredSettings?.positionRename || 'Position';
          if (!requiredSettings.positionEnabled) {
            return null;
          }
        }
        if (field === DefectPartsReplacedFields.BATCH_LOT_NUMBER) {
          label = requiredSettings?.batchOnRename || 'Batch/Lot Number';
          if (!requiredSettings.batchOnEnabled) {
            return <div />;
          }
        }
        if (field === DefectPartsReplacedFields.BATCH_OFF) {
          label = requiredSettings?.batchOffRename || 'Trace Off';
          if (!requiredSettings.batchOffEnabled) {
            return <div />;
          }
        }
        if (mode === 'edit') {
          return (
            <TFInput
              id={field}
              key={field}
              value={partChanges?.[field]}
              onChange={(e) => handlePartChange(e.target.value, field)}
              label={label}
            />
          )
        }
        return (
          <div key={field} data-testid={dataTestId ? `${dataTestId}-${field}` : undefined}>
            <Header edit={mode === 'edit'}>
              {label}
            </Header>
            <span>{partChanges?.[field] || '-'}</span>
          </div>
        )
      })}
    </>
  )
};

PartChangesSection.defaultProps = {
  handlePartChange: () => {},
  dataTestId: '',
};

export default PartChangesSection;

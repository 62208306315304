import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import { Label, SectionHeader } from '../../CommonStyledComponents/CommonStyledComponents';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import TFInput from '../../TFInput/TFInput';
import { updateRelease, updateMxEventsDefect } from '../../../services/api';
import TFFilerViewer from '../../TFFileViewer/fileViewer';
import TFRadioInput from '../../TFRadioInput/TFRadioInput';
import { ResolutionTypes } from '../../../models/defects';
import { Release } from '../../../models/workpacks';
import PartChangesSection from './PartChangesSection';

interface EditResolutionDetailsProps {
  release: Release;
  handleResolutionSave: () => void;
  setLoading: (loading: boolean) => void;
  setEdit: (edit: boolean) => void;
  requiredSettings: {
    engineer: string;
    engineerLicenceNumber: string;
    hidePart145Fields: boolean;
    fromWorkpack: boolean;
    descriptionEnabled: boolean;
    positionEnabled: boolean;
    batchOnEnabled: boolean;
    batchOffEnabled: boolean;
    descriptionRename: string;
    positionRename: string;
    batchOnRename: string;
    batchOffRename: string;
    notesRename: string;
  };
}

interface ImageConversionResult {
  attachment: string | ArrayBuffer | null;
  attachment_file_name: string;
}

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }): string => columns || '1fr 1fr 1fr'};
  gap: 20px;
  margin: 20px 0;
`;

const TextBox = styled.textarea`
  width: 100%;
  height: 70px;
  margin-right: 24px;
  border-radius: 2px;
  border-color: rgba(36, 45, 65, 0.1);
  background-color: #f3f7fc;
  padding-left: 8px;
`;

const ColumnSpanWrapper = styled.div`
  grid-column: span ${({ span }): string => span || '1'};
`;

const ButtonRow = styled.div`
  display: flex;
`;

const SaveButtonWrapper = styled.div`
  margin-right: 16px;
`;

const EditResolutionDetails: React.FC<EditResolutionDetailsProps> = ({
  release,
  requiredSettings,
  setLoading,
  handleResolutionSave,
  setEdit,
}) => {
  const [localRelease, setLocalRelease] = useState(null);
  const [localPartChanges, setLocalPartChanges] = useState(null);
  const [localAttachments, setLocalAttachments] = useState([]);

  const { formatMessage } = useIntl();

  const handleDateTimeChange = (value) => {
    const newRelease = { ...localRelease, date: value };
    setLocalRelease(newRelease);
  };

  const handleInputChange = (value: string | boolean, id: string) => {
    const newRelease = { ...localRelease, [id]: value };
    setLocalRelease(newRelease);
  };

  const handlePartChange = (value: string, id: string) => {
    const newPartChanges = { ...localPartChanges, [id]: value };
    setLocalPartChanges(newPartChanges);
  };

  const convertToImgFile = (file: File): Promise<ImageConversionResult> => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const addAttachment = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      const newAttachments = localAttachments?.concat(...base64Attachments);
      setLocalAttachments(newAttachments);
    }
  };

  const handleDeleteClick = ({ fileName, fileId }): void => {
    const newAttachmentsList = localAttachments.filter(
      (item) =>
        item?.id !== fileId ||
        (item?.attachment_file_name && item?.attachment_file_name !== fileName) ||
        // eslint-disable-next-line no-underscore-dangle
        item?._destroy,
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    setLocalAttachments(newAttachmentsList);
  };

  const handleSaveResolution = async (): Promise<void> => {
    const releaseID = requiredSettings?.fromWorkpack ? release?.id : release?.release_id;
    const params = {
      id: releaseID,
      body: {
        first_name: localRelease?.first_name,
        last_name: localRelease?.last_name,
        licence_number: localRelease?.licence_number,
        company_name: localRelease?.company_name,
        approval_number: localRelease?.approval_number,
        attachments_attributes: localAttachments,
        version_justification: localRelease?.version_justification,
        date: localRelease?.date,
      },
    };
    setLoading(true);
    if (!requiredSettings?.fromWorkpack) {
      const mxEventParams = {
        first_name: localRelease?.first_name,
        last_name: localRelease?.last_name,
        id: release?.id,
        limitations: localRelease?.limitations,
        date: localRelease?.date,
        work_type: localRelease?.work_type,
        description: localRelease?.description,
        part_changes: localRelease?.work_type === 'replaced' ? [localPartChanges] : [],
      };
      await updateMxEventsDefect(mxEventParams);
    }
    await updateRelease(params);
    setLoading(false);
    if (handleResolutionSave) handleResolutionSave();
    setEdit(false);
  };

  useEffect(() => {
    setLocalRelease(release);
    if (release?.part_changes) {
      const latestPartChanges = release.part_changes[release.part_changes?.length - 1];
      setLocalPartChanges(latestPartChanges);
    }
    if (release?.attachments) {
      setLocalAttachments(release.attachments);
    }
  }, [release]);

  return (
    <div>
      <SectionHeader data-testid="EditResolutionDetails-SectionHeader">
        {formatMessage({ id: 'title.resolutionDetails' })}
      </SectionHeader>
      <StyledGrid>
        <ColumnSpanWrapper span="3" data-testid="EditResolutionDetails-DateTimePickerWrapper">
          <DateTimePicker
            dateTime={release?.date}
            headerDate="Date"
            headerTime="Time"
            handleDateTimeChange={(value) => {
              handleDateTimeChange(value);
            }}
            noFuture
          />
        </ColumnSpanWrapper>
        <TFInput
          id="FirstName"
          value={localRelease?.first_name}
          onChange={(e) => handleInputChange(e.target.value, 'first_name')}
          label={formatMessage({ id: 'text.engineerFirstName' }, { field: requiredSettings?.engineer })}
        />
        <TFInput
          id="LastName"
          value={localRelease?.last_name}
          onChange={(e) => handleInputChange(e.target.value, 'last_name')}
          label={formatMessage({ id: 'text.engineerLastName' }, { field: requiredSettings?.engineer })}
        />
        <TFInput
          id="LicenceNumber"
          value={localRelease?.licence_number}
          onChange={(e) => handleInputChange(e.target.value, 'licence_number')}
          label={requiredSettings?.engineerLicenceNumber}
        />
        {!requiredSettings?.hidePart145Fields && (
          <TFInput
            id="CompanyName"
            value={localRelease?.company_name}
            onChange={(e) => handleInputChange(e.target.value, 'company_name')}
            label={formatMessage({ id: 'text.companyName' })}
          />
        )}
        {!requiredSettings?.hidePart145Fields && (
          <TFInput
            id="ApprovalNumber"
            value={localRelease?.approval_number}
            onChange={(e) => handleInputChange(e.target.value, 'approval_number')}
            label={formatMessage({ id: 'text.approvalNumber' })}
          />
        )}
        {!requiredSettings?.fromWorkpack && (
          <ColumnSpanWrapper span="3" data-testid="EditResolutionDetails-ResolutionTypeWrapper">
            <TFRadioInput
              id="ResolutionType"
              options={ResolutionTypes}
              value={localRelease?.work_type}
              onChange={(value) => handleInputChange(value, 'work_type')}
              label={formatMessage({ id: 'text.resolutionType' })}
            />
          </ColumnSpanWrapper>
        )}
        {!requiredSettings?.fromWorkpack && localRelease?.work_type === 'replaced' &&
          <PartChangesSection
            mode="edit"
            partChanges={localPartChanges}
            handlePartChange={handlePartChange}
            requiredSettings={requiredSettings}
          />
        }
        {!requiredSettings?.fromWorkpack &&
          localRelease?.work_type === 'replaced' &&
          requiredSettings?.descriptionEnabled && (
            <ColumnSpanWrapper span="3" data-testid="EditResolutionDetails-PartDescriptionWrapper">
              <Label edit>{requiredSettings.descriptionRename || formatMessage({ id: 'text.description' })}</Label>
              <TextBox
                value={localPartChanges?.description || ''}
                onChange={(e) => handlePartChange(e.target.value, 'part_description')}
                data-testid="EditResolutionDetails-PartDescriptionInput"
              />
            </ColumnSpanWrapper>
          )}
        {!requiredSettings?.fromWorkpack && (
          <ColumnSpanWrapper span="3" data-testid="EditResolutionDetails-NotesWrapper">
            <Label edit>{requiredSettings?.notesRename}</Label>
            <TextBox
              value={localRelease?.description || ''}
              onChange={(e) => handleInputChange(e.target.value, 'description')}
              data-testid="EditResolutionDetails-NotesInput"
            />
          </ColumnSpanWrapper>
        )}
      </StyledGrid>
      <StyledGrid columns="1fr 1fr">
        <div data-testid="EditResolutionDetails-LimitationsWrapper">
          <Label edit>{formatMessage({ id: 'text.limitations' })}</Label>
          <TextBox
            value={localRelease?.limitations || ''}
            onChange={(e) => handleInputChange(e.target.value, 'limitations')}
            data-testid="EditResolutionDetails-LimitationsInput"
          />
        </div>
        <div data-testid="EditResolutionDetails-AttachmentsWrapper">
          <Label edit>{formatMessage({ id: 'text.attachments' })}</Label>
          <TFFilerViewer
            attachments={localAttachments}
            addAttachment={addAttachment}
            uploaderVisible
            handleDeleteClick={handleDeleteClick}
          />
        </div>
      </StyledGrid>

      <ButtonRow>
        <SaveButtonWrapper>
          <Button height="30px" onClick={handleSaveResolution}>
            {formatMessage({ id: 'text.save' })}
          </Button>
        </SaveButtonWrapper>
        <Button
          primary={false}
          onClick={(): void => {
            setEdit(false);
            setLocalRelease(release);
          }}
          height="30px"
        >
          {formatMessage({ id: 'text.cancel' })}
        </Button>
      </ButtonRow>
    </div>
  );
};

export default EditResolutionDetails;

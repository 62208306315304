import React from 'react';
import PropTypes from 'prop-types';
import AircraftStatusTile from '../../components/AircraftStatusTile/AircraftStatusTile';

const AircraftOverviewTiles = ({ aircraft, dentAndBuckleTitle, eropsTitle }) => {
  const featureFlags = aircraft?.feature_flags?.map((flag) => flag.feature_name);
  const { standard_fields } = aircraft;
  const ingoreEROPSWarnings = standard_fields?.non_erops ? standard_fields?.non_erops.enabled : true;
  const aircraftTiles = aircraft?.tiles;

  if (!featureFlags?.includes('documents')) {
    delete aircraftTiles.documents;
  }
  if (!featureFlags?.includes('damage-reports')) {
    delete aircraftTiles.dent_and_buckle;
  }
  let newAircraftTiles = aircraftTiles;
  if (!Object.keys(aircraftTiles).includes('mx_checks')) {
    newAircraftTiles = { mx_checks: { count: 0, colour: 'grey' }, ...aircraftTiles };
  }
  const tileArray = Object.entries(newAircraftTiles);

  return (
    <div>
      {tileArray.map((tile) => (
        <AircraftStatusTile
          tile={tile}
          id={aircraft.id}
          key={tile[0]}
          ingoreEROPSWarnings={ingoreEROPSWarnings}
          dentAndBuckleTitle={dentAndBuckleTitle}
          eropsTitle={eropsTitle}
        />
      ))}
    </div>
  );
};

AircraftOverviewTiles.propTypes = {
  aircraft: PropTypes.object.isRequired,
  dentAndBuckleTitle: PropTypes.string.isRequired,
  eropsTitle: PropTypes.string.isRequired,
};

export default AircraftOverviewTiles;

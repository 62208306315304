export const makeBase64ToBlob = (base64Data: string): Blob => {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/png' });
  return blob;
};

export const makeBlobUrlIntoBlob = async (url: string): Promise<Blob | null> => {
  let blob = null;
  try {
    const response = await fetch(url);
    blob = await response.blob();
    return blob;
  } catch (err) {
    console.error(err);
  }
  return null;
};

import { useQuery } from '@tanstack/react-query';
import { queryCabinIssues } from '../../../services/api';
import { CabinIssue } from '../../../models/cabinIssues';

export interface QueryCabinIssuesPayload {
  aircraft_id: string;
  page: number;
  limit: number;
  sort_column?: string;
  sort_order?: string;
  search?: string;
  status?: string[];
}

const useQueryCabinIssues = ({
  aircraft_id,
  page,
  limit,
  sortBy,
  searchValue,
  filters,
}: {
  aircraft_id: string;
  page: number;
  limit: number;
  sortBy: { id: string; desc: boolean }[];
  searchValue: string;
  filters: { status: string[] };
}): {
  data: {
    cabin_issues: CabinIssue[];
    count: number;
  };
  isFetching: boolean;
} => {
  const payload = {
    page,
    limit,
    aircraft_id,
  } as QueryCabinIssuesPayload;
  if (sortBy?.length) {
    const sort_column = sortBy[0]?.id;
    const sort_order = sortBy[0]?.desc ? 'desc' : 'asc';
    payload.sort_column = sort_column;
    payload.sort_order = sort_order;
  }
  if (searchValue) {
    payload.search = searchValue;
  }
  if (filters?.status?.length) {
    payload.status = filters.status;
  }
  const { data, isFetching } = useQuery({
    queryKey: ['cabinIssues', aircraft_id, page, limit],
    queryFn: () => queryCabinIssues(payload),
    enabled: !!aircraft_id,
    refetchOnWindowFocus: false,
  });
  return { data, isFetching };
};

export default useQueryCabinIssues;

import React from 'react';
import styled from 'styled-components';
import { Divider } from '../../components/CommonStyledComponents/CommonStyledComponents';
import { DamageMap } from '../../models/damageMaps';
import DamageMapThumbnail from './DamageMapThumbnail';

export interface UploadedItem {
  title: string;
  category: string;
  attachment: string;
}

interface ThumbnailSectionProps {
  data: Array<DamageMap | UploadedItem>;
  handleSchematicChange?: (map: DamageMap) => void;
}

const ThumbnailWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
  min-height: 50px;
  margin-bottom: 30px;
`;

const ThumbnailSection: React.FC<ThumbnailSectionProps> = ({
  data,
  handleSchematicChange,
}) => {
  return (
    <>
      <Divider />
      <ThumbnailWrapper>
        {data.map((damageMap) =>
          <DamageMapThumbnail
            damageMap={damageMap}
            key={`${damageMap.title}-${damageMap.category}`}
            onClick={handleSchematicChange}
          />
        )}
      </ThumbnailWrapper>
    </>
  );
};

ThumbnailSection.defaultProps = {
  handleSchematicChange: () => {},
}

export default ThumbnailSection;

/* eslint-disable react/require-default-props */
import { Col, message, Row } from 'antd';
import React, { Component, ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import _, { debounce } from 'lodash';
import Sticky from 'react-stickynode';
import { Button, FilterSideBar, Search } from '@arcflight/tf-component-library';
import { ButtonSize } from '../../components/PaginatedDefectsTable/DefectTableHeader';
import Loading from '../../components/TFLoading';
import MXItemsImporter from '../../components/MXItemsImporter';
import ListWrapper from '../../components/MXManagementList/listWrapper';
import PageOverlayAction from '../../components/PageOverlayAction';
import SlidingDrawer from '../../components/SlidingDrawer';
import CampSyncButton from '../../components/CampSyncButton';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { Aircraft, AircraftPermission, AircraftResource, AircraftState } from '../../models/aircraft';
import { getSingleAircraft } from '../../models/aircraft/actions';
import globalStyles from '../../utils/globalStyles.module.less';
import { getAllMxItems } from '../../models/maintenance/actions';
import { OperatorSetting, UserSettingsState } from '../../models/userSettings';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import { Maintenance, MaintenanceState } from '../../models/maintenance';
import { addToast, Toast, ToastCategories, ToastTypes } from '../../models/toasts';
import TFCard from '../../components/TFCard/TFCard';
import plusIcon from '../../assets/plus.svg';
import filtersIcon from '../../assets/filters.svg';
import { getWorkpacks } from '../../services/api';
import MaintenanceDrawer from '../../components/MaintenanceDrawer/MaintenanceDrawer';
import { syncWithCamp, getMxItemsTotals } from '../../services/apiNew';
import MXModalTolerance from '../../components/Modals/Maintenance/MXModalTolerance';
import MXDraftQueue from '../../components/Modals/Maintenance/MXDraftQueue/MXDraftQueue';
import BooleanFilter from '../../components/FiltersSidebar/BooleanFilter';
import MxMultipleToleranceModal from '../../components/Modals/Maintenance/MxMultipleToleranceModal';
import { Workpack } from '../../models/workpacks';
import { AircraftAuthenticationWrapper } from '../../components/_utils/AuthenticationWrapper';
import styles from './MaintenanceManagement.module.less';

// MX Periods to be shown in dropdown
export enum MX_LIST_PERIODS {
  CRITICAL = 'crit',
  OVERDUE = 0,
  NEXT_DAY = 1,
  NEXT_3_DAYS = 3,
  NEXT_10_DAYS = 10,
  NEXT_30_DAYS = 30,
  NEXT_60_DAYS = 60,
  NEXT_120_DAYS = 120,
  NEXT_365_DAYS = 365,
  ALL_RECORDS = 1000000,
}

const MX_PERIODS = [
  {
    days: 3,
    listPeriods: [MX_LIST_PERIODS.OVERDUE, MX_LIST_PERIODS.NEXT_DAY, MX_LIST_PERIODS.NEXT_3_DAYS],
  },
  {
    days: 10,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
    ],
  },
  {
    days: 30,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
    ],
  },
  {
    days: 60,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
    ],
  },
  {
    days: 120,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
      MX_LIST_PERIODS.NEXT_120_DAYS,
    ],
  },
  {
    days: 1000000,
    listPeriods: [
      MX_LIST_PERIODS.OVERDUE,
      MX_LIST_PERIODS.NEXT_DAY,
      MX_LIST_PERIODS.NEXT_3_DAYS,
      MX_LIST_PERIODS.NEXT_10_DAYS,
      MX_LIST_PERIODS.NEXT_30_DAYS,
      MX_LIST_PERIODS.NEXT_60_DAYS,
      MX_LIST_PERIODS.NEXT_120_DAYS,
      MX_LIST_PERIODS.NEXT_365_DAYS,
      MX_LIST_PERIODS.ALL_RECORDS,
    ],
  },
];

const DEFAULT_PAGE = 1;
const DEFAULT_PER = 15;
const DEBOUNCE_MILLISECONDS = 300;

class MaintenanceManagement extends Component<MaintenanceManagementProps, MaintenanceManagementState> {
  handleSearchChange = debounce((searchTerm) => {
    const { filterParams } = this.state;
    const newFilterParams = { ...filterParams };
    newFilterParams.page = 1;
    const formattedSearchTerm = searchTerm === '' ? null : searchTerm;

    newFilterParams.search_term = formattedSearchTerm;
    this.setState({ loading: true, filterParams: newFilterParams });
  }, DEBOUNCE_MILLISECONDS);

  handlePaginationChange = debounce((page, per) => {
    const { filterParams } = this.state;
    const newFilterParams = { ...filterParams };
    const previousPer = newFilterParams.per;

    if (per !== previousPer) {
      newFilterParams.page = 1;
      newFilterParams.per = per;
    } else {
      newFilterParams.page = page;
    }

    // TODO: fix - currently the pagination component triggers an event on blur that
    //  results in the same value being returned
    if (JSON.stringify(newFilterParams) !== JSON.stringify(filterParams)) {
      this.setState({ loading: true, filterParams: newFilterParams, selectAll: false, selectedMXItems: [] });
    }
  }, DEBOUNCE_MILLISECONDS);

  constructor(props) {
    super(props);
    this.state = {
      // default maintenance period for MX filter dropdown in maintenance timeline
      currentOperator: undefined,
      mxPeriodDays: 1000000,
      selectedMXItems: [],
      loading: true,
      mxLoading: true,
      modalToleranceVisible: false,
      modalDraftsVisible: false,
      editedItem: null,
      synchronising: false,
      passedMxItemId: null,
      openMxItems: [],
      filterParams: {
        aircraft_id: this.getAircraft()?.id,
        page: DEFAULT_PAGE,
        per: DEFAULT_PER,
        start_date: null,
        end_date: null,
        search_term: null,
        status: [],
        item_type: [],
        area: [],
        source: [],
        limits: [],
        planning: [],
        tolerance: [],
      },
      filterGroups: [],
      total: 0,
      totalsCount: 0,
      draftCount: 0,
      draftItems: [],
      isForcingRefresh: false,
      expandAll: false,
      workpacks: [],
      showFiltersDrawer: false,
      reset: false,
      itemStatusCount: null,
      selectAll: false,
      multipleModalVisible: false,
      stateUsed: false,
    };
  }

  componentDidMount(): void {
    const { ttl, userSettings } = this.props;

    const aircraft = this.getAircraft();
    const currentOperator = userSettings?.details?.operators?.find((operator) => {
      return operator.id === aircraft?.operator_id;
    });

    if (currentOperator) this.setState({ currentOperator });

    if (!aircraft || Date.now() - aircraft.lastFetched > ttl) {
      this.getAircraft(true);
    }

    this.fetchTotalsAndMxItems();
    this.getWorkpacks();
  }

  componentDidUpdate(prevProps, prevState): void {
    const {
      aircraftMap,
      match: {
        params: { id },
      },
      maintenanceMap,
      location: { state },
    } = this.props;

    aircraftMap.get(id);
    if (aircraftMap.get(id) !== prevProps.aircraftMap.get(id)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: false,
      });
    }

    // TODO: extract to method
    const { isForcingRefresh, filterParams } = this.state;
    const paramsHaveChanged = JSON.stringify(prevState.filterParams) !== JSON.stringify(filterParams);

    if (isForcingRefresh) {
      this.fetchTotalsAndMxItems();
    } else if (paramsHaveChanged) {
      this.fetchOpenMxItems(state);
    }

    const customiser = (objValue, otherValue, key): boolean => {
      if (key === 'lastFetched') return true;
      if (key === 'time_remaining') return true;
      return undefined;
    };

    if (!_.isEqualWith(maintenanceMap, prevProps.maintenanceMap, customiser)) {
      this.setLoading(true);
      this.resetSelectedItems();
      this.fetchTotalsAndMxItems();
    }
  }

  componentWillUnmount(): void {
    this.setPassedMxItemId(null);
  }

  setLoading = (isLoading: boolean): void => {
    this.setState({ loading: isLoading, reset: false, mxLoading: isLoading });
  };

  resetSelectedItems = (): void => {
    this.setState({ selectedMXItems: [] });
  };

  fetchTotalsAndMxItems = (): void => {
    const { addNewToast } = this.props;
    const params = { aircraft_id: this.getAircraft()?.id };
    this.setState({ isForcingRefresh: false });
    getMxItemsTotals(params)
      .then((res) => {
        const totals = res.data;
        const draftCount = totals.draft;
        this.setState({ draftCount, totalsCount: totals.total, itemStatusCount: totals.item_status });
        this.setFilterGroups(totals);
        this.fetchOpenMxItems();
      })
      .catch((err) => {
        addNewToast({
          title: err?.response?.data?.message,
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
          message: '',
        });
        console.error(err);
        this.setLoading(false);
      });
  };

  setFilterGroups = (totals): void => {
    const aircraft = this.getAircraft() as Aircraft;
    const { currentOperator } = this.state;
    const engineCount = aircraft.aircraft_type.engine_count;
    const engineFilters = [];

    const resolutionRename = currentOperator?.operator_setting?.resolution_name;

    for (let i = 1; i <= engineCount; i += 1) {
      const engineFilter = {
        label: currentOperator?.operator_setting[`engine_${i}`] || `Engine ${i}`,
        key: 'area',
        value: `engine_${i}`,
      };

      engineFilters.push(engineFilter);
    }

    const areaFilters = [
      {
        label: 'Airframe',
        key: 'area',
        value: 'airframe',
        count: totals.area.airframe,
        filterFunction: () => {},
      },
      {
        label: 'Engine',
        key: 'area',
        count: totals.area.airframe,
        childFilters: engineFilters,
        filterFunction: () => {},
      },
    ];

    if (aircraft.apu_installed) {
      const apuFilter = {
        label: 'APU',
        key: 'area',
        value: 'apu',
        count: totals.area.apu,
        filterFunction: () => {},
      };

      areaFilters.push(apuFilter);
    }

    const filterGroups = [
      {
        groupLabel: 'Due in',
        filters: [
          {
            label: 'Next 7 days',
            key: 'date',
            value: '7',
            radio: true,
            count: totals.showing.seven,
            filterFunction: () => {},
          },
          {
            label: 'Next 14 days',
            key: 'date',
            value: '14',
            radio: true,
            count: totals.showing.fourteen,
            filterFunction: () => {},
          },
          {
            label: 'Next 30 days',
            key: 'date',
            value: '30',
            radio: true,
            count: totals.showing.thirty,
            filterFunction: () => {},
          },
          {
            label: 'Next 90 days',
            key: 'date',
            value: '90',
            radio: true,
            count: totals.showing.ninety,
            filterFunction: () => {},
          },
        ],
      },
      {
        groupLabel: 'Item Status',
        filters: [
          {
            label: 'Overdue',
            key: 'status',
            value: 'overdue',
            count: totals.item_status.overdue,
            filterFunction: () => {},
          },
          ...(Object.hasOwn(totals.item_status, 'resolution_pending') ? [{
            label: `${resolutionRename} Pending`,
            key: 'status',
            value: 'resolution_pending',
            count: totals.item_status.resolution_pending,
            filterFunction: () => {},
          }] : []),
          {
            label: 'In Tolerance',
            key: 'status',
            value: 'critical',
            count: totals.item_status.in_tolerance,
            filterFunction: () => {},
          },
          {
            label: 'Open',
            key: 'status',
            value: 'open',
            count: totals.item_status.open,
            filterFunction: () => {},
          },
          {
            label: 'Resolved',
            key: 'status',
            value: 'resolved',
            count: totals.item_status.resolved,
            filterFunction: () => {},
          },
        ],
      },
      {
        groupLabel: 'Item Type',
        filters: [
          {
            label: 'Package',
            key: 'item_type',
            value: 'package',
            count: totals.item_type.package,
            filterFunction: () => {},
          },
          {
            label: 'Scheduled',
            key: 'item_type',
            value: 'scheduled',
            count: totals.item_type.scheduled,
            filterFunction: () => {},
          },
          {
            label: 'Out of Phase',
            key: 'item_type',
            value: 'oop',
            count: totals.item_type.oop,
            filterFunction: () => {},
          },
          {
            label: 'Life Limited Parts',
            key: 'item_type',
            value: 'llp',
            count: totals.item_type.llp,
            filterFunction: () => {},
          },
        ],
      },
      {
        groupLabel: 'Area',
        filters: areaFilters,
      },
      {
        groupLabel: 'Source',
        filters: [
          {
            label: 'CAMP',
            key: 'source',
            value: 'camp',
            count: totals.source.camp,
            filterFunction: () => {},
          },
          {
            label: 'TrustFlight',
            key: 'source',
            value: 'trustflight',
            count: totals.source.trustflight,
            filterFunction: () => {},
          },
        ],
      },
      {
        groupLabel: 'Limits',
        filters: [
          {
            label: 'Cycles',
            key: 'limits',
            value: 'cycles',
            count: totals.limits.cycles,
            filterFunction: () => {},
          },
          {
            label: 'Days',
            key: 'limits',
            value: 'days',
            count: totals.limits.days,
            filterFunction: () => {},
          },
          {
            label: 'Flight Hours',
            key: 'limits',
            value: 'flight_hours',
            count: totals.limits.flight_hours,
            filterFunction: () => {},
          },
          {
            label: 'APU Hours',
            key: 'limits',
            value: 'apu_hours',
            count: totals.limits.apu_hours,
            filterFunction: () => {},
          },
        ],
      },
      {
        groupLabel: 'Planning',
        filters: [
          {
            label: 'Planned in Workpack',
            key: 'planning',
            value: 'planned',
            count: totals.planning.planned,
            filterFunction: () => {},
          },
          {
            label: 'Unplanned',
            key: 'planning',
            value: 'unplanned',
            count: totals.planning.unplanned,
            filterFunction: () => {},
          },
        ],
      },
      {
        groupLabel: 'Tolerance',
        filters: [
          {
            label: 'Tolerance Applied',
            key: 'tolerance',
            value: 'applied',
            count: totals.tolerance.applied,
            filterFunction: () => {},
          },
          {
            label: 'Tolerance Not Applied',
            key: 'tolerance',
            value: 'notapplied',
            count: totals.tolerance.notapplied,
            filterFunction: () => {},
          },
        ],
      },
    ];

    this.setState({ filterGroups });
  };

  setPassedMxItemId = (id): void => {
    this.setState({ passedMxItemId: id });
  };

  onItemSelect = (value, itemID): void => {
    const { selectedMXItems: activeMXItems } = this.state;

    if (value) {
      activeMXItems.push(itemID);
    } else {
      const itemIndex = activeMXItems.indexOf(itemID);
      if (itemIndex > -1) {
        activeMXItems.splice(itemIndex, 1);
      }
    }
    this.setState({
      selectedMXItems: [...new Set(activeMXItems)],
    });
  };

  getAircraft = (
    forceRefetch = false,
  ): {
    id: string;
    registration: string;
    lastFetched: number;
    camp_integration_enabled: boolean;
    camp_last_synced: string;
    operator_id: string;
    locked?: boolean;
    billing_status?: string;
  } => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (forceRefetch) {
      this.setState(
        {
          loading: true,
        },
        () => this.props.fetchAircraft(id),
      );
    }
    return this.props.aircraftMap.get(id);
  };

  getWorkpacks = async (): Promise<void> => {
    const { match } = this.props;
    const payload = {
      aircraft_id: match.params.id,
    };
    try {
      const res = await getWorkpacks(payload);
      const completedWorkpacks = res.workpacks.filter((w) => w.status !== 'complete');
      this.setState({ workpacks: completedWorkpacks });
    } catch (err) {
      console.error(err);
    }
  };

  getItemsSeparatedByStatus = (items): {
    overdueItems;
    resolutionPendingItems;
    criticalItems;
    standardItems;
    resolvedItems
  } => {
    const overdueItems = [];
    const resolutionPendingItems = [];
    const criticalItems = [];
    const resolvedItems = [];
    const standardItems = [];

    items.forEach((item) => {
      if (item.status === 'overdue') {
        overdueItems.push(item);
      } else if (item.status === 'resolution_pending') {
        resolutionPendingItems.push(item);
      } else if (item.status === 'critical') {
        criticalItems.push(item);
      } else if (item.status === 'resolved') {
        resolvedItems.push(item);
      } else {
        standardItems.push(item);
      }
    });

    return { overdueItems, resolutionPendingItems, criticalItems, standardItems, resolvedItems };
  };

  onDraftsSuccess = (): void => {
    this.forceRefetchofMxItems();
  };

  deselectAllItems = (): void => {
    this.setState({ selectedMXItems: [], selectAll: false, multipleModalVisible: false });
  };

  modalSuccess = (): void => {
    // const { mxPeriodDays } = this.state;
    // const today = moment().startOf('day');
    // const daysUntilNewMXItem = moment(estimatedDate).diff(today, 'days');
    // let days = mxPeriodDays;

    // if (daysUntilNewMXItem >= 0 && daysUntilNewMXItem <= 3) {
    //   days = 3;
    // } else if (daysUntilNewMXItem > 3 && daysUntilNewMXItem <= 10) {
    //   days = 10;
    // } else if (daysUntilNewMXItem > 10 && daysUntilNewMXItem <= 30) {
    //   days = 30;
    // } else if (daysUntilNewMXItem > 30 && daysUntilNewMXItem <= 60) {
    //   days = 60;
    // } else if (daysUntilNewMXItem > 60 && daysUntilNewMXItem <= 120) {
    //   days = 120;
    // } else {
    //   days = 100000;
    // }
    // TODO: follow the above logic by extracting the date ranges and resetting state

    this.forceRefetchofMxItems();
  };

  forceRefetchofMxItems = (): void => {
    this.setState({ isForcingRefresh: true, loading: true, selectedMXItems: [], selectAll: false });
  };

  hideModal = (): void => {
    this.setState({
      modalToleranceVisible: false,
      modalDraftsVisible: false,
      editedItem: null,
    });
  };

  onToleranceChange = (): void => {
    this.hideModal();
    this.setState({ isForcingRefresh: true, loading: true });
  };

  handleApplyTolerance = (): void => {
    this.setState({ multipleModalVisible: true });
  };

  handleHideMultipleModal = () => {
    this.setState({ multipleModalVisible: false });
  };

  onEdit = (item): void => {
    const { updateDrawerId, updateDrawerContent, openDrawer, updateDrawerMode } = this.props;
    openDrawer(true);
    updateDrawerContent({ content: <MaintenanceDrawer /> });
    updateDrawerId(item.id);
    updateDrawerMode('edit');
  };

  onApplyTolerance = (item): void => {
    this.setState({
      modalToleranceVisible: true,
      editedItem: item,
    });
  };

  showMXDraftQueue = (): void => {
    this.setState({ modalDraftsVisible: true });
  };

  toggleFiltersDrawer = (): void => {
    const { showFiltersDrawer } = this.state;
    this.setState({ showFiltersDrawer: !showFiltersDrawer });
  };

  handleSyncButtonClick = (): void => {
    const { addNewToast } = this.props;
    this.setState({ synchronising: true });
    const {
      match: {
        params: { id },
      },
    } = this.props;
    syncWithCamp(id)
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          this.setState({ synchronising: false, loading: true, isForcingRefresh: true }, () => {
            this.getAircraft(true);
          });
        } else {
          addNewToast({
            title: 'Unable to synchronise. Please try again',
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          });
        }
      })
      .catch(() => {
        addNewToast({
          title: 'Unable to synchronise. Please try again',
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
          message: '',
        });
        this.setState({ synchronising: false });
      });
  };

  handleFilterChange = (filtersArray): void => {
    const filterableKeys = ['item_type', 'area', 'status', 'source', 'limits', 'planning', 'tolerance', 'date'];
    const { filterParams } = this.state;
    const newFilterParams = { ...filterParams };
    newFilterParams.page = 1;
    filterableKeys.forEach((key) => {
      const filterValuesForKey = filtersArray.filter((filter) => filter.key === key).map((filter) => filter.value);

      newFilterParams[key] = filterValuesForKey;
    });
    if (!_.isEqual(filterParams, newFilterParams)) {
      this.setState({ loading: true, filterParams: newFilterParams, selectedMXItems: [], selectAll: false });
    }
  };

  toggleExpandAll = (): void => {
    const { expandAll } = this.state;

    this.setState({ expandAll: !expandAll });
  };

  toggleSelectAll = (): void => {
    const { selectAll, openMxItems } = this.state;
    let newSelectedItems = [];
    if (!selectAll) {
      newSelectedItems =
        openMxItems.filter((item: Maintenance) => item.status !== 'resolved').map((item: Maintenance) => item.id);
    }
    this.setState({ selectAll: !selectAll, selectedMXItems: newSelectedItems });
  };

  handleAddItem = (): void => {
    const { openDrawer, updateDrawerContent, updateDrawerMode } = this.props;
    openDrawer(true);
    updateDrawerContent({ content: <MaintenanceDrawer /> });
    updateDrawerMode('add');
  };

  resetFilters = (): void => {
    this.setState({
      filterParams: {
        aircraft_id: this.getAircraft()?.id,
        page: DEFAULT_PAGE,
        per: DEFAULT_PER,
        start_date: null,
        end_date: null,
        search_term: null,
        status: [],
        item_type: [],
        area: [],
        source: [],
        limits: [],
        planning: [],
        tolerance: [],
      },
      reset: true,
    });
  };

  async fetchOpenMxItems(state?: { id: string; type: string }): Promise<void> {
    const { filterParams, stateUsed } = this.state;
    const { fetchAllMxItems } = this.props;
    let buttonName = '';
    let filterName = '';

    if (state?.type) {
      switch (state.type) {
        case 'Days':
          buttonName = 'Days-filter';
          filterName = 'days';
          break;
        case 'Hours':
          buttonName = 'Flight Hours-filter';
          filterName = 'flight_hours';
          break;
        case 'Cycles':
          buttonName = 'Cycles-filter';
          filterName = 'cycles';
          break;
        case 'APU Hours':
          buttonName = 'APU Hours-filter';
          filterName = 'apu_hours';
          break;
        default:
          break;
      }
    }

    const data = await fetchAllMxItems({
      aircraft_id: filterParams.aircraft_id,
      page: filterParams.page,
      per: filterParams.per,
      start_date: filterParams.start_date,
      end_date: filterParams.end_date,
      search_term: filterParams.search_term,
      status: filterParams.status,
      item_type: filterParams.item_type,
      area: filterParams.area,
      source: filterParams.source,
      limits: filterParams.limits,
      planning: filterParams.planning,
      tolerance: filterParams.tolerance,
    });
    // getMxItems(filterParams)

    try {
      const res = await data;
      const openMxItems = res.scheduled_mx_items;
      const { total } = res;
      const mxLoading = false;
      const loading = false;
      const isForcingRefresh = false;

      const stateUpdate = {
        loading,
        mxLoading,
        openMxItems,
        total,
        isForcingRefresh,
      } as MaintenanceManagementState;

      if (state?.id) {
        stateUpdate.passedMxItemId = state?.id;
        const button = document.querySelector(`[for="${buttonName}"]`) as HTMLLabelElement;
        if (button && !filterParams.limits.includes(filterName) && !stateUsed) {
          button.click();
          stateUpdate.stateUsed = true;
        }
      }
      this.setState(stateUpdate);
    } catch (err) {
      console.error(err);
      this.setLoading(false);
      this.setState({ mxLoading: false });
    }
  }

  render(): object {
    const {
      intl: { formatMessage },
      openDrawer,
      updateDrawerContent,
      updateDrawerMode,
    } = this.props;
    const {
      mxPeriodDays,
      loading,
      mxLoading,
      selectedMXItems,
      editedItem,
      modalToleranceVisible,
      modalDraftsVisible,
      synchronising,
      passedMxItemId,
      total,
      draftCount,
      draftItems,
      filterGroups,
      openMxItems,
      expandAll,
      showFiltersDrawer,
      workpacks,
      currentOperator,
      reset,
      totalsCount,
      itemStatusCount,
      selectAll,
      multipleModalVisible,
    } = this.state;
    const aircraft = this.getAircraft();

    const filteredItems = this.getItemsSeparatedByStatus(openMxItems);

    const onFailedParentToggle = (): void => {
      const { addNewToast } = this.props;
      message.warning(formatMessage({ id: 'message.deselectItems' }));
      addNewToast({
        title: formatMessage({ id: 'message.deselectItems' }),
        type: ToastTypes.ERROR,
        category: ToastCategories.FLAG,
        message: '',
      });
    };
    return (
      <>
        <InnerMenuLayout>
          <Loading contain topPosition={60} loading={loading || mxLoading} />
          <PageOverlayAction
            visible={selectedMXItems ? selectedMXItems.length > 0 : false}
            type="workpack"
            workpacks={workpacks}
            onSuccess={this.forceRefetchofMxItems}
            handleApplyTolerance={this.handleApplyTolerance}
            items={selectedMXItems}
            aircraft={aircraft}
            onCancel={this.deselectAllItems}
            data-test="pageOverlayWorkpack"
            poIntl={{
              po_short_name: currentOperator?.operator_setting?.po_short_name,
              po_long_name: currentOperator?.operator_setting?.po_long_name,
              po_suffix: currentOperator?.operator_setting?.po_suffix,
            }}
          />
          <MXModalTolerance
            aircraft={aircraft}
            visible={modalToleranceVisible}
            hideModal={this.onToleranceChange}
            item={editedItem}
            data-test="mxModalTolerance"
          />
          <MxMultipleToleranceModal
            hideModal={this.handleHideMultipleModal}
            visible={multipleModalVisible}
            items={openMxItems.filter(
              (item) =>
                selectedMXItems.includes(item.id) &&
                ((item?.flight_seconds_due && item?.flight_seconds_tolerance) ||
                  (item?.cycles_due && item?.cycles_tolerance) ||
                  (item?.date_due && item?.unit_of_time === 'days' && item?.days_tolerance) ||
                  (item?.date_due && item?.unit_of_time === 'months' && item?.months_tolerance) ||
                  (item?.apu_seconds_due && item?.apu_seconds_tolerance)),
            )}
            setLoading={this.setLoading}
          />
          <MXDraftQueue
            aircraft={aircraft}
            visible={modalDraftsVisible}
            items={draftItems}
            onSuccess={this.onDraftsSuccess}
            hideModal={this.hideModal}
          />
          <TFCard>
            <div className={styles.layoutDiv}>
              <div className={styles.mainCol}>
                <div className={styles.headerWrapper}>
                  <div className={styles.titleWrapper}>
                    <div className={`${globalStyles.overCardTitle} ${styles.overCardTitle}`}>
                      {formatMessage({ id: 'title.maintenance' })}
                    </div>
                    {aircraft && aircraft.camp_last_synced && (
                      <CampSyncButton
                        camp_last_synced={aircraft.camp_last_synced}
                        synchronising={synchronising}
                        formatMessage={formatMessage}
                        handleSyncButtonClick={this.handleSyncButtonClick}
                      />
                    )}
                  </div>
                  <div className={styles.mobileWrapper}>
                    <div id="searchWrapper" data-test="searchInput">
                      <Search
                        onChange={(e): void => this.handleSearchChange(e.currentTarget.value)}
                        onClear={(): void => this.handleSearchChange('')}
                        reset={reset}
                      />
                    </div>
                    <div className={styles.buttonsWrapper}>
                      <AircraftAuthenticationWrapper
                        aircraftId={aircraft?.id}
                        requiredResource={AircraftResource.SCHEDULED_MX_ITEM}
                        requiredPermissionLevel={AircraftPermission.CREATE}
                      >
                        <div className={styles.addButton}>
                          <Button
                            size={ButtonSize.MEDIUM}
                            onClick={(): void => {
                              openDrawer(true);
                              updateDrawerContent({ content: <MaintenanceDrawer /> });
                              updateDrawerMode('add');
                            }}
                            data-test="addNewButton"
                          >
                            <img src={plusIcon} alt="plus icon" />
                            <span>
                              {window.innerWidth > 450
                                ? formatMessage({ id: 'form.button.addNewItem' })
                                : formatMessage({ id: 'form.button.add' })}
                            </span>
                          </Button>
                        </div>
                      </AircraftAuthenticationWrapper>
                      <div className={styles.filterButton}>
                        <button className={styles.filterButton} type="button" onClick={this.toggleFiltersDrawer}>
                          <img src={filtersIcon} alt="Open filters" className={styles.filterIcon} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  {currentOperator?.operator_setting?.requires_camp_mx_approval &&
                  draftCount &&
                  !aircraft?.locked &&
                  aircraft?.billing_status !== 'Archived' ? (
                    <Col md={24} className={styles.mxItemsImporter}>
                      <MXItemsImporter draftCount={draftCount} onActionClick={this.showMXDraftQueue} />
                    </Col>
                  ) : null}
                  <Col md={24} className={styles.mxListCol}>
                    <ListWrapper
                      items={filteredItems}
                      aircraft={aircraft as Aircraft}
                      selectedItems={selectedMXItems}
                      onItemSelect={(value, itemID): void => this.onItemSelect(value, itemID)}
                      mxPeriods={MX_PERIODS}
                      mxPeriodDays={mxPeriodDays}
                      onEdit={(item): void => this.onEdit(item)}
                      onDeleteSuccess={this.forceRefetchofMxItems}
                      onApplyTolerance={(item): void => this.onApplyTolerance(item)}
                      expandAll={expandAll}
                      expandItem={passedMxItemId}
                      setPassedMxItemId={this.setPassedMxItemId}
                      loading={loading}
                      onPaginationChange={this.handlePaginationChange}
                      total={total}
                      handleAddItem={this.handleAddItem}
                      resetFilters={this.resetFilters}
                      totalsCount={totalsCount}
                      itemStatusCount={itemStatusCount}
                      operatorSettings={currentOperator?.operator_setting}
                    />
                  </Col>
                </Row>
              </div>
              <div className={styles.filtersCol}>
                <Sticky top={80} className={styles.stickyFilters}>
                  <div className={styles.booleanFilter}>
                    <BooleanFilter title="Expand All" valueKey="" value={expandAll} onChange={this.toggleExpandAll} />
                  </div>
                  <div className={styles.booleanFilter} data-test="SelectAllToggle">
                    <BooleanFilter title="Select All" valueKey="" value={selectAll} onChange={this.toggleSelectAll} />
                  </div>
                  <div className={styles.innerScroll}>
                    <FilterSideBar
                      data={[]}
                      filterGroups={filterGroups}
                      onFailedParentToggle={onFailedParentToggle}
                      onChange={this.handleFilterChange}
                      reset={reset}
                    />
                  </div>
                </Sticky>
              </div>
            </div>
            <div id="cardBottomBoundary" className={styles.bottomBoundary} />
          </TFCard>
          {showFiltersDrawer && (
            <SlidingDrawer filterDrawer toggleDrawer={this.toggleFiltersDrawer}>
              <div className={styles.drawerHeader}>
                <span>{formatMessage({ id: 'title.filters' })}</span>
              </div>
              <div className={styles.booleanFilter}>
                <BooleanFilter title="Expand All" valueKey="" value={expandAll} onChange={this.toggleExpandAll} />
              </div>
              <div className={styles.booleanFilter}>
                <BooleanFilter title="Select All" valueKey="" value={selectAll} onChange={this.toggleSelectAll} />
              </div>
              <FilterSideBar
                data={[]}
                filterGroups={filterGroups}
                onFailedParentToggle={onFailedParentToggle}
                onChange={showFiltersDrawer && this.handleFilterChange}
              />
            </SlidingDrawer>
          )}
        </InnerMenuLayout>
      </>
    );
  }
}

const mapStateToProps = ({
  aircraft,
  maintenance,
  userSettings,
}: {
  aircraft: AircraftState;
  maintenance: MaintenanceState;
  userSettings: UserSettingsState;
}): {
  ttl: number;
  aircraftMap: Map<
    string,
    {
      id: string;
      registration: string;
      lastFetched: number;
      camp_integration_enabled: boolean;
      camp_last_synced: string;
      operator_id: string;
      locked?: boolean;
      billing_status?: string;
    }
  >;
  userSettings: UserSettingsState;
  maintenanceMap: Map<string, Maintenance>;
} => {
  return {
    aircraftMap: aircraft.aircraftMap,
    ttl: aircraft.ttl,
    userSettings,
    maintenanceMap: maintenance.maintenanceMap,
  };
};
interface MxItemsResponse {
  scheduled_mx_items: Maintenance[];
  total: number;
}

const mapDispatchToProps = (
  dispatch,
): {
  fetchAircraft: (id: string) => Promise<void>;
  fetchAllMxItems: ({
    aircraft_id,
    page,
    per,
    start_date,
    end_date,
    search_term,
    status,
    item_type,
    area,
    source,
    limits,
    planning,
    tolerance,
  }) => Promise<MxItemsResponse>;
  openDrawer: (payload: boolean) => Promise<void>;
  updateDrawerContent: (payload: { content: ReactNode }) => Promise<void>;
  updateDrawerId: (payload: string) => Promise<void>;
  updateDrawerMode: (payload: string) => Promise<void>;
  addNewToast: (payload: Toast) => Promise<void>;
} => ({
  fetchAircraft: (id): Promise<void> => {
    return dispatch(
      getSingleAircraft({
        payload: id,
      }),
    );
  },
  fetchAllMxItems: ({
    aircraft_id,
    page,
    per,
    start_date,
    end_date,
    search_term,
    status,
    item_type,
    area,
    source,
    limits,
    planning,
    tolerance,
  }): Promise<MxItemsResponse> => {
    return dispatch(
      getAllMxItems({
        payload: {
          aircraft_id,
          page,
          per,
          start_date,
          end_date,
          search_term,
          status,
          item_type,
          area,
          source,
          limits,
          planning,
          tolerance,
        },
        skipDeleteAll: true,
        forceReplace: false,
      }),
    );
  },
  openDrawer: (payload): Promise<void> => {
    return dispatch(changeDrawerVisibility({ payload }));
  },
  updateDrawerContent: (payload): Promise<void> => {
    return dispatch(changeDrawerContent({ payload }));
  },
  updateDrawerId: (payload): Promise<void> => {
    return dispatch(setDrawerId({ payload }));
  },
  updateDrawerMode: (payload): Promise<void> => {
    return dispatch(changeDrawerMode({ payload }));
  },
  addNewToast: (payload): Promise<void> => {
    return dispatch(addToast({ payload }));
  },
});

// Define the props type for MaintenanceManagement component
type MaintenanceManagementProps = RouteComponentProps<{ id: string }> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<{ id: string }, Record<string, unknown>, LocationState>;

interface LocationState {
  id: string;
  type: string;
}

// Connect MaintenanceManagement component with Redux store and withRouter
const ConnectedMaintenanceManagement = connect(mapStateToProps, mapDispatchToProps)(withRouter(MaintenanceManagement));

// Inject intl prop using injectIntl HOC
const EnhancedMaintenanceManagement = injectIntl(ConnectedMaintenanceManagement);

// Export the enhanced component
export default EnhancedMaintenanceManagement;

export type MaintenanceManagementState = {
  currentOperator: {
    operator_setting: OperatorSetting;
  };
  mxPeriodDays: number;
  selectedMXItems: string[];
  loading: boolean;
  mxLoading: boolean;
  modalToleranceVisible: boolean;
  modalDraftsVisible: boolean;
  editedItem: Maintenance | null;
  synchronising: boolean;
  passedMxItemId: string;
  openMxItems: Maintenance[];
  filterGroups: Array<{
    groupLabel: string;
    filters: Array<{
      label: string;
      key: string;
      value?: string;
      radio?: boolean;
      count?: number;
      filterFunction: () => void;
      childFilters?: Array<{
        label: string;
        key: string;
        value: string;
      }>;
    }>;
  }>;
  filterParams: {
    aircraft_id: string;
    page: number;
    per: number;
    start_date: Date | null;
    end_date: Date | null;
    search_term: string | null;
    status: string[];
    item_type: string[];
    area: string[];
    source: string[];
    limits: string[];
    planning: string[];
    tolerance: string[];
  };
  total: number;
  draftCount: number;
  draftItems: Maintenance[];
  isForcingRefresh: boolean;
  expandAll: boolean;
  workpacks: Workpack[];
  showFiltersDrawer: boolean;
  reset: boolean;
  totalsCount: number;
  itemStatusCount: Record<string, number> | null;
  selectAll: boolean;
  multipleModalVisible: boolean;
  stateUsed: boolean;
};

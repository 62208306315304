import 'react-image-crop/dist/ReactCrop.css';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import ReactCrop, { Crop } from 'react-image-crop';
import EXIF from 'exif-js';
import { Upload } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { addToast, ToastCategories, ToastTypes } from '../../models/toasts';
import servers from '../../utils/servers';
import TFLoading from '../../components/TFLoading';
import uploadIcon from '../../assets/upload-doc-icon.svg';
import schematics from '../../assets/emptyState/empty-state-damage-maps.svg';
import { DamageMap } from '../../models/damageMaps';

const { Dragger } = Upload;

interface SchematicUpdateEditorProps {
  damageMapData: DamageMap;
  newDamageMapData?: DamageMap,
  handleDataChange: (changes: { value: string; key: string }[]) => void;
}

const UploadButton = styled.button`
  height: 340px;
  width: 550px;
  font-size: 20px;
  font-weight: 500;
  border: ${({ theme }) => `1px dashed ${theme.colours.brandAccentBlue}`};
  background-color: ${({ theme }) => theme.colours.white};
`;

const SchematicImage = styled.img`
  margin-bottom: 20px;
  height: 125px;
`;

const DefaultText = styled.div`
  color: ${({ theme }) => theme.colours.grayBlue};
`;

const LinkText = styled.div`
  color: ${({ theme }) => theme.colours.brandAccentBlue};
`;


const CropContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 365px;
  position: relative;

  .ReactCrop {
    position: relative;
    z-index: 0;
  }

  .ReactCrop__crop-selection {
    z-index: 1;
    border: 2px solid #0074d9;
  }
`;

const SchematicUpdateEditor: React.FC<SchematicUpdateEditorProps> = ({
  damageMapData: damageMapdata,
  handleDataChange,
  newDamageMapData
}) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    aspect: 1.78
  });
  const [imageURL, setImageURL] = useState(null);

  const imageRef = useRef<HTMLImageElement | null>(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const getCroppedImage = async (passedCrop: Crop): Promise<string> => {
    const canvas = document.createElement('canvas');
    const scaleX = imageRef.current?.naturalWidth / imageRef.current?.width;
    const scaleY = imageRef.current?.naturalHeight / imageRef.current?.height;
    canvas.width = Math.ceil(passedCrop.width * scaleX);
    canvas.height = Math.ceil(passedCrop.height * scaleY);
    const { width, height } = canvas;
    const ctx = canvas.getContext('2d');

     // Rotate image on crop based on EXIF orientation
    // eslint-disable-next-line func-names
    EXIF.getData(imageRef.current, () => {
      const orientation = EXIF.getTag(imageRef.current, 'Orientation');
      if (orientation) {
        switch (orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            break;
        }
      }

      ctx.drawImage(
        imageRef.current,
        passedCrop.x * scaleX,
        passedCrop.y * scaleY,
        passedCrop.width * scaleX,
        passedCrop.height * scaleY,
        0,
        0,
        // use exact width and height to prevent unwanted black borders on the image
        width,
        height,
      );
    });

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const newBlob = blob;
        if (!newBlob) {
          dispatch(addToast({ payload: {
            title: formatMessage({ id: 'message.canvasEmpty' }),
            message: '',
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            }
          }))
          return;
        }
        const file = new File([newBlob], 'newFile.jpeg', { type: 'image/jpeg' });
        const fileUrl = window.URL.createObjectURL(file);
        resolve(fileUrl);
      }, 'image/jpeg');
    });
  };

  const onCropComplete = async (passedCrop: Crop) => {
    if (imageRef.current && passedCrop.width && passedCrop.height) {
      const croppedImageURL = await getCroppedImage(passedCrop);
      handleDataChange([{ value: croppedImageURL, key: 'attachment' }]);
    }
  };

  const handleFileUpload = (info) => {
    const { file } = info
    setFileUploading(true);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      EXIF.getData(file, () => {
        const orientation = EXIF.getTag(file, 'Orientation');
        const can = document.createElement('canvas');
        const ctx = can.getContext('2d');
        const thisImage = new Image();
        let imageType = 'thin';
        thisImage.onload = () => {
          const imageWidth = thisImage.width;
          const imageHeight = thisImage.height;
          const image169Height = imageWidth * 9 / 16;
          if (imageHeight > image169Height) {
            // image is higher than 16x9
            can.height = imageHeight;
            can.width = imageWidth + (imageHeight - image169Height) * 1.78;
            imageType = 'high';
          } else {
            // image is lower than 16x9
            can.width = imageWidth;
            can.height = image169Height;
          }

          ctx.save();
          const { width } = can;
          const styleWidth = can.style.width;
          const { height } = can;
          const styleHeight = can.style.height;
          if (orientation) {
            if (orientation > 4) {
              can.width = height;
              can.style.width = styleHeight;
              can.height = width;
              can.style.height = styleWidth;
            }
            switch (orientation) {
              case 2:
                ctx.transform(-1, 0, 0, 1, width, 0);
                break;
              case 3:
                ctx.transform(-1, 0, 0, -1, width, height);
                break;
              case 4:
                ctx.transform(1, 0, 0, -1, 0, height);
                break;
              case 5:
                ctx.transform(0, 1, 1, 0, 0, 0);
                break;
              case 6:
                ctx.transform(0, 1, -1, 0, height, 0);
                break;
              case 7:
                ctx.transform(0, -1, -1, 0, height, width);
                break;
              case 8:
                ctx.transform(0, -1, 1, 0, 0, width);
                break;
              default:
                break;
            }
          }
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, can.width + 2, can.height + 2);
          let startX = 0;
          let startY = 0;
          if (imageType === 'thin') {
            startY = can.height / 2 - imageHeight / 2;
          } else {
            startX = can.width / 2 - imageWidth / 2;
          }
          ctx.drawImage(thisImage, startX, startY);
          ctx.restore();
          const dataURL = can.toDataURL();
          setUploadedImageUrl(dataURL);
          handleDataChange([
            { value: newDamageMapData?.title || file.name, key: 'title' }, { value: file.name, key: 'fileName' },
          ]);
          setFileUploading(false);
        };
        thisImage.src = URL.createObjectURL(file);
      });
    };
  };

  useEffect(() => {
    if (!newDamageMapData) {
      setUploadedImageUrl(null);
      setImageURL(null);
      imageRef.current = null;
    }
  }, [newDamageMapData]);

  useEffect(() => {
    let newImageURL;
    if (imageRef.current) {
      newImageURL = imageRef.current.src;
    } else if (!imageRef.current && uploadedImageUrl) {
      newImageURL = uploadedImageUrl;
    } else if (!uploadedImageUrl && damageMapdata?.attachment?.url) {
      newImageURL = `${servers.api}/${damageMapdata?.attachment?.url}`;
    }
    if (newImageURL !== imageURL) {
      setImageURL(newImageURL);
    }
  }, [damageMapdata?.attachment?.url, imageURL, uploadedImageUrl]);

  return (
    <>
      <TFLoading loading={fileUploading} contain />
      {imageURL ? (
        <CropContainer>
          <ReactCrop
            src={imageURL}
            crop={crop}
            onImageLoaded={(image: HTMLImageElement) => {
              imageRef.current = image;
              // Set initial crop to cover the whole image
              setCrop(prev => ({
                ...prev,
                width: 100,
                height: 100 / 1.78
              }));
            }}
            onComplete={onCropComplete}
            onChange={(c) => {
              if (c.height !== 0 && c.width !== 0) {
                setCrop(c)
              }
            }}
            imageStyle={{ width: '100%', height: '365px' }}
          />
        </CropContainer>
      ) : (
        <Dragger
          onChange={handleFileUpload}
          beforeUpload={(file) => {
            Promise.resolve(file).then(() => {
              const newList = [...fileList, file];
              setFileList(newList);
          });
            return false;
          }}
          accept=".jpeg,.jpg,.png"
        >
          <UploadButton type="submit">
            <SchematicImage src={schematics} alt={formatMessage({ id: 'text.schematics' })} />
            <div>
              <img src={uploadIcon} alt={formatMessage({ id: 'text.uploadIcon' })} />
            </div>
            <div>{formatMessage({ id: 'text.files' })}</div>
            <DefaultText>
              <div>{formatMessage({ id: 'form.button.youCan' })}</div>
              <LinkText>{formatMessage({ id: 'form.button.clickHere' })}</LinkText>
            </DefaultText>
          </UploadButton>
        </Dragger>
      )}
    </>
  );
};

SchematicUpdateEditor.defaultProps = {
  newDamageMapData: null,
}

export default SchematicUpdateEditor;

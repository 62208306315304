/* eslint-disable react/no-danger */
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import styled from 'styled-components';
import { DashboardState } from '../../../models';
import serialize from '../../../utils/slateSerializer';
import { usePrevious } from '../../../utils/utils';
import StyledCheckbox from '../../StyledCheckbox/StyledCheckbox';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import Label from './Label';

interface ConfirmationCardProps {
  title: string;
  text: any;
  icon: string;
  id: string;
  extraContent?: ReactElement;
  onChange?: (changeObject: object) => void;
}

const Wrapper = styled.div`
  table,
  tr,
  td,
  th {
    border: 1px solid;
    border-collapse: collapse;
  }
  td,
  th {
    padding-left: 4px;
    padding-top: 4px;
  }
  table.header-overview {
    text-align: center;
  }
  th {
    border-bottom: 2px solid;
  }
  h4 {
    text-decoration: underline;
  }
  table {
    width: 100%;
  }
  img {
    max-width: 100%;
  }
`;

const StyledIcon = styled.img`
  width: 24px;
  margin-right: 10px;
`;

const WarningText = styled.span`
  color: ${({ theme }) => theme.colours.amber};
  margin-top: 10px;
`;

const ConfirmationCard: React.FC<ConfirmationCardProps> = ({ title, text, icon, id, extraContent, onChange }) => {
  const [boxChecked, setBoxChecked] = useState(false);

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id: aircraftId } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const previousText = usePrevious(text);

  const showRichTextMel = aircraftMap.get(aircraftId)?.standard_fields?.rich_text_mel?.enabled;

  const handleCheckBoxClick = (): void => {
    setBoxChecked(!boxChecked);

    if (onChange) {
      const changeObject = {};

      changeObject[id] = { isChecked: !boxChecked };

      onChange(changeObject);
    }
  };

  useEffect(() => {
    if (!_.isEqual(previousText, text)) {
      setBoxChecked(false);
    }
  }, [previousText, text]);

  return (
    <Card marginTop={30} id={id} identifier={`ConfirmationCard${title}`}>
      <Wrapper>
        <FlexWrapper>
          <StyledIcon src={icon} alt="speech icon" data-testid="ConfirmationCard--Icon" />
          <Label fontWeight={600} identifier="ConfirmationCardTitle">
            {title}
          </Label>
        </FlexWrapper>
        <FlexWrapper column marginTop={20} marginLeft={34}>
          {text && (
            <>
              {showRichTextMel ? (
                <>
                  {text.map((item) => {
                    const newText = serialize(item);
                    return (
                      <div
                        key={newText}
                        data-testid="ConfirmationCard--Text"
                        dangerouslySetInnerHTML={{ __html: newText }}
                      />
                    );
                  })}
                </>
              ) : (
                <div key={text} data-testid="ConfirmationCard--Text">
                  {text}
                </div>
              )}
            </>
          )}
          {extraContent && extraContent}
          {id === 'maintenanceCard' && (
            <WarningText data-testid="ConfirmationCard--WarningText">
              {formatMessage({ id: 'text.warningSigningOffMProcedure' })}
            </WarningText>
          )}
          <FlexWrapper marginTop={30} alignItems="center">
            <StyledCheckbox
              checked={boxChecked}
              name={`${title}Checked`}
              handleCheckboxClick={handleCheckBoxClick}
              marginRight="10px"
            />
            <span data-testid="ConfirmationCard--Statement">
              {`I have read and reviewed the ${title.toLowerCase()}`}
            </span>
          </FlexWrapper>
        </FlexWrapper>
      </Wrapper>
    </Card>
  );
};

export default ConfirmationCard;

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@arcflight/tf-component-library';
import servers from '../../../utils/servers';
import expandIcon from '../../../assets/expand.svg';
import completeIcon from '../../../assets/icon-complete.svg';
import TFFileViewer from '../../TFFileViewer/fileViewer';
import collapseIcon from '../../../assets/collapse.svg';
import pencilIcon from '../../../assets/icon-edit.svg';
import bin from '../../../assets/delete-red.svg';
import styles from '../workpackDrawer.module.less';
import { DashboardState } from '../../../models';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import { updateMxEventsDefect } from '../../../services/api';
import { getSingleWorkpack } from '../../../models/workpacks/actions';
import { Defect as DefectType } from '../../../models/defects';

const TitleWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
  row-gap: 20px;
`;

const Header = styled.div`
  color: ${({ edit }): string => (edit ? '#242d41' : '#9f9f9f')};
  font-size: 12px;
  text-transform: ${({ edit }): string => (edit ? 'none' : 'uppercase')};
  margin-bottom: 5px;
`;

const Text = styled.div`
  letter-spacing: 0.46px;
  color: rgba(36, 45, 65, 0.7);
`;

const StyledIcon = styled.img`
  margin-right: 4px;
`;

const ResTypeWrapper = styled.div`
  grid-column: ${({ edit }): string => (edit ? '3 span' : '1 span')};
  @media (max-width: 450px) {
    grid-column: span 3;
  }
`;

const DetailsWrapper = styled.div`
  grid-column: 2 span;
  display: ${({ edit }): string => (edit ? 'flex' : 'inline-block')};
  flex-direction: column;
  @media (max-width: 450px) {
    grid-column: span 3;
  }
`;

const LimitationsRadioWrapper = styled.div`
  @media (max-width: 450px) {
    grid-column: span 3;
  }
`;

const LimitationsWrapper = styled.div`
  grid-column: 3 span;
  display: ${({ edit }): string => (edit ? 'flex' : 'inline-block')};
  flex-direction: column;
`;

const RadioWrapper = styled.div`
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const TextBox = styled.textarea`
  width: ${({ width }): string => width || 'auto'};
  height: ${({ height }): string => height || '80px'};
  margin-right: 24px;
  border-radius: 2px;
  border-color: rgba(36, 45, 65, 0.05);
  background-color: #f6f8fb;
  padding-left: 8px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const SaveButtonWrapper = styled.div`
  margin-right: 16px;
`;

const SpanDiv = styled.div`
  grid-column: 3 span;
`;

const PartWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledInput = styled.input`
  width: 140px;
  height: 34px;
  padding: ${({ padding }): string => (padding ? `${padding}` : `9px 10px`)};
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
  ::placeholder {
    color: rgba(36, 45, 65, 0.3);
  }
  &:focus {
    outline: none;
  }
`;

const DefectResolution = ({ resolutionInfo, mode, status, wpId }): JSX.Element => {
  const { formatMessage } = useIntl();
  const Dispatch = useDispatch();

  const [editResolution, setEditResolution] = useState(false);
  const [limitations, setLimitations] = useState(false);
  const [limitationsText, setLimitationsText] = useState('');
  const [resType, setResType] = useState('');
  const [notesText, setNotesText] = useState('');
  const [parts, setParts] = useState([]);

  let defectResolutionWrapper = styles.defectResolution;
  if (mode === 'edit') defectResolutionWrapper = styles.defectResolutionEdit;
  if (status === 'resolved') defectResolutionWrapper = `${styles.defectResolutionResolved}`;

  const handlePartChange = (value, key: string, index: number): void => {
    const newParts = [...parts];
    newParts[index][key] = value;
    setParts([...newParts]);
  };

  const partChanges = () => {
    return parts.map((part, i) => (
      <SpanDiv>
        <PartWrapper key={part?.id}>
          <div className={styles.rowItem}>
            <Header edit={editResolution}>Part Number Off</Header>
            {editResolution ? (
              <StyledInput
                value={part?.part_number}
                onChange={(e): void => handlePartChange(e.target.value, 'part_number', i)}
              />
            ) : (
              <Text>{(part && part.part_number) || '-'}</Text>
            )}
          </div>
          <div className={styles.rowItem}>
            <Header edit={editResolution}>Part Number On</Header>
            {editResolution ? (
              <StyledInput
                value={part?.part_number_on}
                onChange={(e): void => handlePartChange(e.target.value, 'part_number_on', i)}
              />
            ) : (
              <Text>{(part && part.part_number_on) || '-'}</Text>
            )}
          </div>
          <div className={styles.rowItem}>
            <Header edit={editResolution}>{formatMessage({ id: 'form.labels.serialOff' })}</Header>
            {editResolution ? (
              <StyledInput
                value={part?.serial_off}
                onChange={(e): void => handlePartChange(e.target.value, 'serial_off', i)}
              />
            ) : (
              <Text>{(part && part.serial_off) || '-'}</Text>
            )}
          </div>
          <div className={styles.rowItem}>
            <Header edit={editResolution}>{formatMessage({ id: 'form.labels.serialOn' })}</Header>
            {editResolution ? (
              <StyledInput
                value={part?.serial_on}
                onChange={(e): void => handlePartChange(e.target.value, 'serial_on', i)}
              />
            ) : (
              <Text>{(part && part.serial_on) || '-'}</Text>
            )}
          </div>
          <div className={styles.rowItem}>
            <Header edit={editResolution}>Batch</Header>
            {editResolution ? (
              <StyledInput value={part?.batch} onChange={(e): void => handlePartChange(e.target.value, 'batch', i)} />
            ) : (
              <Text>{(part && part.batch) || '-'}</Text>
            )}
          </div>
        </PartWrapper>
      </SpanDiv>
    ));
  };

  const handleResolutionSave = async (): Promise<void> => {
    const payload = {
      work_type: resType,
      description: notesText,
      limitations: limitationsText,
      id: resolutionInfo?.id,
      part_changes: parts,
    };
    try {
      await updateMxEventsDefect(payload);

      Dispatch(getSingleWorkpack({ payload: { id: wpId } }));
      setEditResolution(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (resolutionInfo) {
      setResType(resolutionInfo?.work_type);
      setNotesText(resolutionInfo?.description);
      setLimitations(!!resolutionInfo.limitations);
      setLimitationsText(resolutionInfo.limitations);
      setParts(resolutionInfo?.part_changes);
    }
  }, [resolutionInfo]);

  let displayWorkType = '-';
  if (resolutionInfo.work_type === 'inspected') displayWorkType = 'Inspected';
  if (resolutionInfo.work_type === 'replaced') displayWorkType = 'Part Replaced';
  if (resolutionInfo.work_type === 'other') displayWorkType = 'Other';

  return (
    <>
      <div className={defectResolutionWrapper}>
        <div className={styles.border} />
        <TitleWrapper>
          <span className={styles.resolutionTitle}>{formatMessage({ id: 'title.resolutionDetails' })}</span>
          {editResolution ? null : (
            <Button primary={false} onClick={(): void => setEditResolution(true)} height="24px" padding="0 12px">
              <StyledIcon src={pencilIcon} alt="pencil" />
              Edit resolution
            </Button>
          )}
        </TitleWrapper>
        <StyledRow>
          <ResTypeWrapper edit={editResolution}>
            <Header edit={editResolution}>Resolution type</Header>
            {editResolution ? (
              <RadioWrapper>
                <RadioInputButton
                  text="Inspected"
                  active={resType === 'inspected'}
                  onClick={(): void => {
                    setResType('inspected');
                    setParts([]);
                  }}
                  marginRight="8px"
                />
                <RadioInputButton
                  text="Part Replaced"
                  active={resType === 'replaced'}
                  onClick={(): void => {
                    setResType('replaced');
                    setParts([
                      {
                        part_number: '',
                        part_number_on: '',
                        serial_on: '',
                        serial_off: '',
                        batch: '',
                      },
                    ]);
                  }}
                  marginRight="8px"
                />
                <RadioInputButton
                  text="Other"
                  active={resType === 'other'}
                  onClick={(): void => {
                    setResType('other');
                    setParts([]);
                  }}
                  marginRight="8px"
                />
              </RadioWrapper>
            ) : (
              <Text>{displayWorkType}</Text>
            )}
          </ResTypeWrapper>
          {resType === 'replaced' ? partChanges() : null}
          <DetailsWrapper edit={editResolution}>
            <Header edit={editResolution}>Notes</Header>
            {editResolution ? (
              <TextBox rows={5} cols={20} value={notesText} onChange={(e): void => setNotesText(e.target.value)} />
            ) : (
              <Text>{(resolutionInfo && resolutionInfo.description) || '-'}</Text>
            )}
          </DetailsWrapper>
          {editResolution ? (
            <LimitationsRadioWrapper>
              <Header edit={editResolution}>{formatMessage({ id: 'text.limitations' })}</Header>
              <RadioWrapper>
                <RadioInputButton
                  text="Yes"
                  active={limitations}
                  onClick={(): void => setLimitations(true)}
                  marginRight="8px"
                />
                <RadioInputButton
                  text="No"
                  active={!limitations}
                  onClick={(): void => {
                    setLimitations(false);
                    setLimitationsText('');
                  }}
                  marginRight="8px"
                />
              </RadioWrapper>
            </LimitationsRadioWrapper>
          ) : null}
          {limitations ? (
            <LimitationsWrapper edit={editResolution}>
              <Header edit={editResolution}>{formatMessage({ id: 'text.limitations' })}</Header>
              {editResolution ? (
                <TextBox
                  rows={5}
                  cols={20}
                  value={limitationsText}
                  onChange={(e): void => setLimitationsText(e.target.value)}
                />
              ) : (
                <Text>{(resolutionInfo && resolutionInfo.limitations) || '-'}</Text>
              )}
            </LimitationsWrapper>
          ) : null}
          {!limitations && !editResolution ? (
            <LimitationsWrapper edit={editResolution}>
              <Header edit={editResolution}>{formatMessage({ id: 'text.limitations' })}</Header>
              <Text>-</Text>
            </LimitationsWrapper>
          ) : null}
          {editResolution ? (
            <ButtonsWrapper>
              <SaveButtonWrapper>
                <Button height="32px" onClick={handleResolutionSave}>
                  Save
                </Button>
              </SaveButtonWrapper>
              <Button primary={false} height="32px" onClick={(): void => setEditResolution(false)}>
                Cancel
              </Button>
            </ButtonsWrapper>
          ) : null}
        </StyledRow>
      </div>
    </>
  );
};

const DefectDetails = ({
  melInfo: { display_data, book_item },
  details,
  attachments,
  remaining,
  reportedBy,
  signature,
  date,
  mode,
  status,
}): JSX.Element => {
  const { formatMessage } = useIntl();

  const {
    userSettings: {
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const defectNameOverride = operators.find((op) => op?.id === aircraftMap.get(id)?.operator_id)?.operator_setting
    ?.defect_name;

  let defectDetailsWrapper = styles.MXDetails;
  if (mode === 'edit') defectDetailsWrapper = styles.MXDetailsEdit;
  if (status === 'resolved') defectDetailsWrapper = `${styles.MXDetailsResolution} ${styles.clearBottomBorder}`;
  const addAttachment = (): void => null;

  const displayMelItemNumber = book_item?.mel_number;

  return (
    <div className={defectDetailsWrapper}>
      <div className={styles.defectDetails}>
        <span className={styles.header}>
          {`${defectNameOverride} details` || formatMessage({ id: 'form.labels.defectDetails' })}
        </span>
        <div className={styles.rowGridTwo}>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'form.labels.details' })}</span>
            <span>{details || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'title.attachments' })}</span>
            <TFFileViewer attachments={attachments} addAttachment={addAttachment} uploaderVisible={mode === 'edit'} />
          </div>
        </div>
        <div className={`${styles.rowGridFourByTwo} ${styles.rowPad20}`}>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'title.type' })}</span>
            <span>{(display_data && display_data.type) || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.ata' })}</span>
            <span>{(display_data && display_data.ata) || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.itemNum' })}</span>
            <span>{displayMelItemNumber}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.item' })}</span>
            <span>{(book_item && book_item.item_title) || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.deferralInterval' })}</span>
            <span>{(display_data && display_data.category) || '-'}</span>
          </div>
          <div className={styles.rowItem}>
            <span className={styles.headingGray}>{formatMessage({ id: 'text.quantityRemaining' })}</span>
            <span>{remaining || '-'}</span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.defectReportedBy}>
            <span>{`${formatMessage({ id: 'text.reportedBy' })}:`} </span>
            <span className={styles.bold}>
              {(reportedBy && reportedBy.first_name) || '-'} {(reportedBy && reportedBy.last_name) || null}
            </span>
            {signature && <img src={`${servers.api}${signature}`} alt="signature" className={styles.signatureImage} />}
          </div>
          <div className={styles.defectReportedOn}>
            <span>{formatMessage({ id: 'text.reportedOn:' })} </span>
            <span className={styles.bold}>{date || '-'}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface DefectProps {
  item: { mx_item: DefectType, id: string, mx_item_id: string };
  removeWPItem: (itemId: string, mxItemId: string) => void;
  mode: string;
  wpId: string;
}

const Defect = ({ item: { mx_item, id, mx_item_id }, removeWPItem, mode, wpId }: DefectProps): JSX.Element => {
  const [showDefectDetails, toggleShowDefectDetails] = useState(false);

  const { display_data, book_item } = mx_item;
  const toggleDefectDetails = (): void => toggleShowDefectDetails((state) => !state);

  const defectResolved = mx_item.mx_events.length > 0;

  const {
    userSettings: {
      dateFormat,
      details: { operators },
    },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id: aircraftId } = useParams<{ id: string }>();

  const defectShortNameOverride = operators.find((op) => op?.id === aircraftMap.get(aircraftId)?.operator_id)
    ?.operator_setting?.defect_short_name;

  return (
    <div>
      <div className={styles.wpRowWrapper}>
        {mx_item.status === 'resolved' ? (
          <div className={styles.checkbox}>
            <img src={completeIcon} alt="complete icon" className={styles.tickIcon} />
          </div>
        ) : null}
        <div
          role="button"
          key={id}
          className={showDefectDetails ? styles.wpItemRowExpanded : styles.wpItemRow}
          onClick={toggleDefectDetails}
        >
          <div className={styles.itemDesWrapper}>
            <span className={styles.mxType} style={{ backgroundColor: '#1e589f' }}>
              {defectShortNameOverride.toUpperCase()}{' '}
            </span>
            <span className={showDefectDetails ? styles.mxLabelExpanded : styles.mxLabel}>
              <span className={styles.chapterName}>{mx_item.details}</span>
            </span>
          </div>
          <div className={styles.expandWrapper}>
            {mx_item.status === 'resolved' ? (
              <span className={styles.outcome}>
                {mx_item.mx_events[0].part_changes && mx_item.mx_events[0].part_changes.length > 0
                  ? 'Part Replaced'
                  : 'Complied With'}
              </span>
            ) : (
              <span className={styles.outcome}>
                Created
                {moment(mx_item.created_at).format('DD MMM YYYY')}
              </span>
            )}
            <img src={showDefectDetails ? collapseIcon : expandIcon} alt="expand icon" className={styles.expandIcon} />
          </div>
        </div>
        {(mode === 'edit' || mode === 'new') && (
          <button type="button" onClick={(): void => removeWPItem(id, mx_item_id)} className={styles.bin}>
            <img src={bin} alt="bin" />
          </button>
        )}
      </div>
      {showDefectDetails && (
        <>
          <DefectDetails
            melInfo={{ display_data, book_item }}
            details={mx_item.details}
            attachments={mx_item.attachments}
            remaining={mx_item.item_number_remaining}
            reportedBy={mx_item.reported_by || mx_item.reporter}
            signature={mx_item.public_signature_image_url}
            date={moment(mx_item.date, 'YYYY-MM-DD').format(dateFormat)}
            mode={mode}
            status={mx_item.status}
          />
          {defectResolved && (
            <DefectResolution resolutionInfo={mx_item.mx_events[0]} mode={mode} status={mx_item.status} wpId={wpId} />
          )}
        </>
      )}
    </div>
  );
};

export default Defect;

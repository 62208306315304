import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import completeIcon from '../../../assets/icon-complete.svg';
import collapseIcon from '../../../assets/collapse.svg';
import bin from '../../../assets/delete-red.svg';
import { DashboardState } from '../../../models';
import expandIcon from '../../../assets/expand.svg';
import FileViewer from '../../TFFileViewer/fileViewer';
import { CabinIssue } from '../../../models/cabinIssues';

const RowWrapper = styled.div`
  display: flex;
`;

const Checkbox = styled.div`
  margin-top: 15px;
  align-self: center;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #7bc88d, #4aa91c);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-right: 10px;
  flex-shrink: 0;
`;

const StyledTick = styled.img``;

const ExpandableRow = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }): string => theme.colors.whiteMedium40};
  width: 100%;
  border-radius: ${({ expanded }): string => (!expanded ? '5px' : '5px 5px 0 0')};
  margin-top: 15px;
  border: 2px solid #fff;
  border-bottom: ${({ expanded }): string => (expanded ? '2px solid transparent' : '')};
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
`;

const MxType = styled.span`
  color: white;
  min-height: 30px;
  height: auto;
  width: 41px;
  border-radius: 5px 0 0 5px;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #11b146;
`;

const MxLabel = styled.span`
  align-self: center;
  margin-left: 12px;
  color: #181818;
  max-width: 400px;
  overflow: hidden;
  white-space: ${({ expanded }): string => (expanded ? 'unset' : 'nowrap')};
  text-overflow: ${({ expanded }): string => (expanded ? 'unset' : 'ellipsis')};
  font-weight: 500;
`;

const ExpandWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Outcome = styled.span`
  margin-left: 5px;
  font-size: 12px;
  color: #181818;
  margin-right: 15px;
  flex-shrink: 0;
`;

const StyledExpandIcon = styled.img`
  margin-right: 15px;
`;

const StyledBin = styled.button`
  cursor: pointer;
  margin-top: 15px;
  border: none;
  background-color: transparent;
  img {
    margin-left: 1px;
    &:hover {
      width: 14px;
      margin-left: 0;
    }
  }
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  background-color: ${({ theme }): string => theme.colors.whiteMedium40};
  padding: 15px;
  border-radius: 0 0 5px 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  margin-left: ${({ marginLeft }): string => (marginLeft ? '26px' : '0')};
  margin-right: ${({ marginRight }): string => (marginRight ? '26px' : '0')};
`;

const Header = styled.div`
  color: rgba(36, 45, 65, 0.4);
  font-size: 12px;
  text-transform: uppercase;
`;

const Text = styled.div`
  color: rgba(36, 45, 65, 1);
`;

interface CabinIssueItemProps {
  item: { mx_item: CabinIssue; mx_item_id: string; id: string };
  mode: string;
  removeWPItem: (itemId: string, mxItemId: string) => void;
}

const CabinIssueItem = ({ item, mode, removeWPItem }: CabinIssueItemProps): ReactElement => {
  const {
    userSettings: { dateFormat },
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const operatorSettings = operators.find((op) => aircraftMap.get(id)?.operator_id === op?.id)?.operator_setting;

  const [showDetails, setShowDetails] = useState(false);

  return (
    <div>
      <RowWrapper>
        {item?.mx_item?.status === 'resolved' ? (
          <Checkbox>
            <StyledTick src={completeIcon} alt="complete icon" />
          </Checkbox>
        ) : null}
        <ExpandableRow
          role="button"
          key={item?.mx_item_id}
          onClick={(): void => setShowDetails((state) => !state)}
          expanded={showDetails}
        >
          <RowWrapper>
            <MxType>{operatorSettings?.cabin_issue_short_name || 'Cabin'}</MxType>
            <MxLabel expanded={showDetails}>
              <span>{item?.mx_item?.details}</span>
            </MxLabel>
          </RowWrapper>
          <ExpandWrapper>
            {item?.mx_item?.status === 'resolved' ? (
              <Outcome>
                Complied With
              </Outcome>
            ) : (
              <Outcome>
                Created
                {moment(item?.mx_item.raised_at).format('DD MMM YYYY')}
              </Outcome>
            )}
            <StyledExpandIcon src={showDetails ? collapseIcon : expandIcon} alt="expand icon" />
          </ExpandWrapper>
        </ExpandableRow>
        {(mode === 'edit' || mode === 'new') && (
          <StyledBin type="button" onClick={(): void => removeWPItem(item?.id, item?.mx_item_id)}>
            <img src={bin} alt="bin" />
          </StyledBin>
        )}
      </RowWrapper>
      {showDetails ? (
        <DetailsWrapper
          marginLeft={item?.mx_item?.status === 'resolved'}
          marginRight={mode === 'edit' || mode === 'new'}
        >
          <div>
            <Header>Details</Header>
            <Text>{item?.mx_item?.details}</Text>
          </div>
          <div>
            <Header>Date</Header>
            <Text>{moment(item?.mx_item?.raised_at).format(dateFormat)}</Text>
          </div>
          <div>
            <Header>Attachments</Header>
            <FileViewer
              attachments={item?.mx_item?.attachments}
              addAttachment={null}
              handleDeleteClick={null}
              uploaderVisible={false}
            />
          </div>
        </DetailsWrapper>
      ) : null}
    </div>
  );
};

export default CabinIssueItem;

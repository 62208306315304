/* eslint-disable react/no-array-index-key */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Button, Loading } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { Remark } from 'react-remark';
import UndoIcon from '../../assets/icon-editor-undo.svg';
import AIIcon from '../../assets/icon-card-ai.svg';
import { sendCustomFieldMessageToChatbot } from '../../services/api';
import TFTextBoxInput from '../../components/TFTextBoxInput/TFTextBoxInput';
import { Label, Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import { DataValue } from '../../utils/updateLocalDataObject';

interface CustomFieldChatbotProps {
  updateCustomFieldsData: (changes: { value: DataValue; key: string }[]) => void;
  setUsingChatBot: (value: boolean) => void;
}

export interface BotMessageResponse {
  db_obj: { [key: string]: DataValue };
  messages: BotMessage[];
}

export interface BotMessage {
  content: string;
  role: string;
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: 100%;
  color: #242d41;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  margin-left: 20px;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${({ role }): string => (role === 'user' ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

const MessageBubble = styled.div`
  padding: 10px 20px;
  background-color: ${({ theme, role }): string => (role === 'user' ? theme.colours.blue : '#f5f5f5')};
  color: ${({ role }): string => (role === 'user' ? 'white' : 'black')};
  border-radius: 20px;
  p {
    margin: 0;
  }
`;

const MessageOuterWrapper = styled.div`
  padding: 10px;
  border: 1px solid rgba(36, 45, 65, 0.1);
  border-radius: 2px;
  min-height: 90px;
`;

const StartWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: ${({ displayBorder }): string => (displayBorder ? '1px solid rgba(36, 45, 65, 0.1)' : 'none')};
  padding: 16px 0 6px;
`;

const StyledImg = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 10px;
  margin-bottom: 3px;
  &:hover {
    cursor: pointer;
  }
`;

const MessageDiv = styled.div`
  min-height: 50px;
`;

const StyledAIIcon = styled.img`
  width: 30px;
  margin-right: 10px;
`;

const PrePopulatedQuestionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin: 20px 0 0;
`;

const StyledButton = styled.button`
  background: transparent;
  border-radius: 16px;
  border: 1px solid rgba(36, 45, 65, 0.1);
  padding: 8px;
  width: 100%;
  cursor: pointer;
  color: rgb(18, 111, 214);
  font-size: 14px;
  font-weight: 500;
  &:hover {
    border-color: rgb(18, 111, 214);
  }
  &:focus {
    outline: none;
  }
`;

const CompletedWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 20px;
  margin: 10px 0 20px;
`;

const PreviewButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

const CustomFieldChatBot: React.FC<CustomFieldChatbotProps> = ({ updateCustomFieldsData, setUsingChatBot }) => {
  const [messages, setMessages] = useState<BotMessage[]>([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const [preQuestionClicked, setPreQuestionClicked] = useState(false);
  const [completedObject, setCompletedObject] = useState(null);
  const [prompts, setPrompts] = useState([]);

  const inputRef = useRef(null);

  const { formatMessage } = useIntl();

  const handleMessageInput = (e): void => {
    setCurrentMessage(e.target.value);
  };

  const handleSendMessage = async (): Promise<void> => {
    const params = {
      conversation_id: conversationId,
      message: currentMessage,
    };
    setLoading(true);
    setMessages([...messages, { content: currentMessage, role: 'user' }, { content: null, role: 'loading' }]);
    setCurrentMessage('');
    const response = new Promise((resolve, reject) => {
      try {
        resolve(sendCustomFieldMessageToChatbot(params));
      } catch (err) {
        reject(err);
        setLoading(false);
      }
    });
    response.then((res: BotMessageResponse) => {
      setMessages(res.messages);
      if (res.db_obj) {
        setCompletedObject(res.db_obj);
      }
      setLoading(false);
      if (res?.db_obj) {
        const changes = Object.entries(res?.db_obj).map(([key, value]) => {
          return { value, key } as { value: DataValue; key: string };
        });
        updateCustomFieldsData(changes);
      }
      inputRef.current.focus();
      setPreQuestionClicked(false);
    });
  };

  const handleStartNewConversation = (): void => {
    setMessages([]);
    setCurrentMessage('');
    setConversationId(null);
    setPreQuestionClicked(false);
    setCompletedObject(null);
  };

  const handleKeyPress = (e): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleQuestionClick = (number: number): void => {
    const newQuestion = formatMessage({ id: `text.customFieldPrompt${number}` });
    setCurrentMessage(newQuestion);
    setPreQuestionClicked(true);
  };

  const displayCompletedDetails = () => {
    if (completedObject) {
      const sortedKeys = Object.keys(completedObject).sort((a, b) => {
        const order = ['title', 'heading', 'type', 'view', 'key'];
        const indexA = order.indexOf(a);
        const indexB = order.indexOf(b);
        if (indexA === -1 && indexB === -1) return a.localeCompare(b);
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });

      return sortedKeys.map((key) => {
        let newValue = completedObject[key];
        if (newValue === true) newValue = 'Yes';
        if (newValue === false) newValue = 'No';
        let newKey = key;
        if (key === 'view') newKey = 'Location';
        if (key === 'options') {
          if (typeof newValue === 'object') {
            newValue = Object.values(newValue).join(', ');
          }
        }
        return (
          <div key={key}>
            <Label>{newKey}</Label>
            <Text>{newValue}</Text>
          </div>
        );
      });
    }
    return null;
  };

  const formatMessages = (): ReactElement[] => {
    return messages.map((message, i) => {
      if (message.content === null && message.role === 'loading') {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <MessageWrapper key={`${message.role}${i}`} role={message.role}>
            <MessageBubble>
              <Loading size={40} loading={loading} contain hasTransparentBackground />
            </MessageBubble>
          </MessageWrapper>
        );
      }

      if (message.content.startsWith('Completed') || message.content.startsWith('Custom field created')) {
        return null;
      }

      const formatGenericMessage = (content: string): ReactElement => {
        return <Remark>{content}</Remark>;
      };

      return (
        // eslint-disable-next-line react/no-array-index-key
        <MessageWrapper key={`${message.role}${i}`} role={message.role}>
          <MessageBubble role={message.role}>{formatGenericMessage(message.content)}</MessageBubble>
        </MessageWrapper>
      );
    });
  };

  useEffect(() => {
    if (preQuestionClicked) {
      handleSendMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preQuestionClicked]);

  useEffect(() => {
    if (!conversationId) {
      const newConversationId = uuidv4();
      setConversationId(newConversationId);
    }
  }, [conversationId]);

  useEffect(() => {
    inputRef.current.focus();

    const buildPrompts = () => {
      const randomNumbers = (min: number, max: number): number[] => {
        const numbers = new Set<number>();
        while (numbers.size < 3) {
          const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
          numbers.add(randomNum);
        }
        return Array.from(numbers);
      };

      return randomNumbers(1, 3).map((number) => {
        return (
          <StyledButton onClick={(): void => handleQuestionClick(number)}>
            {formatMessage({ id: `text.customFieldPrompt${number}` })}
          </StyledButton>
        );
      });
    };

    const newPrompts = buildPrompts();
    setPrompts(newPrompts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TitleWrapper>
        <StyledAIIcon src={AIIcon} alt="ai icon" />
        {formatMessage({ id: 'text.whatCustomFieldCreateToday' })}
      </TitleWrapper>
      <MessageOuterWrapper>
        <MessageDiv>
          {formatMessages()}
          {completedObject && (
            <MessageBubble>
              {formatMessage({ id: 'text.customFieldDetailsAreAsFollows' })}
              <CompletedWrapper>{displayCompletedDetails()}</CompletedWrapper>
              <PreviewButtonWrapper>
                <Button height="30px" onClick={(): void => setUsingChatBot(false)}>
                  {formatMessage({ id: 'text.preview' })}
                </Button>
              </PreviewButtonWrapper>
            </MessageBubble>
          )}
        </MessageDiv>
        {messages.length > 0 && (
          <StartWrapper displayBorder={messages.length === 0}>
            <Button height="30px" primary={false} onClick={handleStartNewConversation}>
              <StyledImg src={UndoIcon} alt="undo icon" />
              {formatMessage({ id: 'text.startOver' })}
            </Button>
          </StartWrapper>
        )}
      </MessageOuterWrapper>
      {messages.length === 0 && !preQuestionClicked ? (
        <PrePopulatedQuestionsWrapper>{prompts}</PrePopulatedQuestionsWrapper>
      ) : null}
      <InputWrapper>
        <TFTextBoxInput
          label=""
          id="chatbot-input"
          onChange={handleMessageInput}
          onKeyPress={handleKeyPress}
          value={currentMessage}
          ref={inputRef}
          disabled={loading}
        />
        <ButtonWrapper>
          <Button height="30px" disabled={loading} onClick={(): Promise<void> => handleSendMessage()}>
            {formatMessage({ id: 'text.send' })}
          </Button>
        </ButtonWrapper>
      </InputWrapper>
    </div>
  );
};

export default CustomFieldChatBot;

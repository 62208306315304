const initialState: DamageReportsState = {
  damageReportsMap: new Map(),
  lastFetched: 0,
};

export enum DamageReportsActionTypes {
  SAVE_ALL = 'damageReports/saveAll',
}

export interface SaveAllDamageReportsActionTypes {
  type: DamageReportsActionTypes.SAVE_ALL;
  payload: DamageReport[];
  aircraftId: string;
}

export default function (state = initialState, action: SaveAllDamageReportsActionTypes): DamageReportsState {
  switch (action.type) {
    case DamageReportsActionTypes.SAVE_ALL: {
      const masterMap = new Map(state.damageReportsMap.entries());
      const damageReportsMap = masterMap.has(action.aircraftId) ? masterMap.get(action.aircraftId) : new Map();
      action.payload.forEach((damageMap) => {
        damageReportsMap.set(damageMap.id, {
          ...damageReportsMap.get(damageMap.id),
          ...damageMap,
          lastFetched: Date.now(),
        });
      });
      masterMap.set(action.aircraftId, damageReportsMap);

      return {
        ...state,
        damageReportsMap: masterMap,
        lastFetched: Date.now(),
      };
    }
    default:
      return state;
  }
}

export interface Attachment {
  id?: string;
  file_name?: string;
  category?: string;
  valid_from?: string;
  valid_to?: string;
  organisation_id?: string;
  status?: string;
  url?: string;
  pdf_url?: string;
  thumbnail_url?: string;
  content_type?: string;
  _destroy?: boolean;
  attachment?: string | ArrayBuffer | null;
  attachment_file_name?: string;
}

export interface DamageReportsState {
  damageReportsMap: Map<string, Map<string, DamageReport>>;
  lastFetched: number;
}

export interface DamageReport {
  id: string;
  damage_map_id: string;
  type: string;
  details: string;
  status: string;
  created_at: string;
  x_coordinate: number;
  y_coordinate: number;
  attachments: Attachment[];
  reported_by: string;
  approved_by: string;
  category: string;
}

export const INITIAL_DAMAGE_REPORT: Readonly<DamageReport> = {
  id: null,
  type: 'dent',
  details: null,
  status: 'reported',
  x_coordinate: 0,
  y_coordinate: 0,
  attachments: []
} as DamageReport;

export const DAMAGE_REPORT_TYPES = [
  { title: 'Crack', value: 'crack' },
  { title: 'Abrasion', value: 'abrasion' },
  { title: 'Dent', value: 'dent' },
];

export interface DamageReportTotals {
  category: Record<string, number>;
  type: Record<string, number>;
  status: Record<string, number>;
  total: number;
}

export enum DamageReportStatus {
  Reported = 'reported',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum DamageReportType {
  Crack = 'crack',
  Abrasion = 'abrasion',
  Dent = 'dent',
}

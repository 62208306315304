const createQueryParts = (
  params: Record<string, string | number | boolean | string[] | number[] | boolean[]>
): string => {
  if (!params) return '';

  const queryParts = [];
  Object.entries(params).forEach(([key, value]) => {
    // Skip if value is a plain object (not an array)
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return;
    }
    if (value && typeof value === 'function') {
      return;
    }
    if (value && typeof value === 'symbol') {
      return;
    }
    if (value === undefined || value === null) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((item) => queryParts.push(`${key}[]=${item}`));
    } else {
      // Convert boolean values to strings
      const stringValue = typeof value === 'boolean' ? value.toString() : value;
      queryParts.push(`${key}=${stringValue}`);
    }
  });

  return queryParts.join('&');
};

export default createQueryParts;

const widgetPercentageCalculator = ({
  remaining = 0,
  nextDueWithTolerance = null,
  forDays = false,
}: {
  remaining?: number;
  nextDueWithTolerance?: number;
  forDays?: boolean;
}): number => {
  if (remaining === null) return 0;

  let percentage: number;

  if (forDays) {
    percentage = (remaining / 365) * 100;
  } else {
    percentage = (remaining / (nextDueWithTolerance || 1)) * 100;
  }
  const roundedPercentage = Math.round(percentage * 1000) / 1000;
  return roundedPercentage;
};

export default widgetPercentageCalculator;

import { Button, Modal } from '@arcflight/tf-component-library';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { MXItemStatusMap } from '../../models/maintenance';
import { FlightStatusMap } from '../../models/flights';

interface TablePopUpProps {
  visible: boolean;
  data: any;
  handleClose: () => void;
  title?: string;
}

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  div {
    margin-right: 4px;
    color: #242d41;
    margin-bottom: 32px;
  }
`;

const HeavyFont = styled.div`
  font-weight: 500;
`;

const BoxTitle = styled.div`
  text-transform: capitalize;
  color: rgba(36, 45, 65, 0.6);
`;

const ChangesRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  color: rgba(36, 45, 65, 0.6);
  padding: 12px 0;
  border-top: 1px solid rgba(36, 45, 65, 0.05);
`;

const Header = styled.div`
  margin-bottom: 4px;
  font-weight: 500;
`;

const FromRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 4px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const ScrollableDiv = styled.div`
  overflow: auto;
`;

const TablePopUp: React.FC<TablePopUpProps> = ({ visible, data, handleClose, title }) => {
  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const { formatMessage } = useIntl();

  const buildRow = (key: string, old: string, updated: string): ReactNode => {
    let newOld = old;
    let newUpdated = updated;
    if (!old && updated === '-') {
      return null;
    }
    if (old === '-' && updated === '-') {
      return null;
    }
    if (!old && !updated) {
      return null;
    }
    if (key === 'status' && title === 'defect') {
      newOld = MXItemStatusMap[old];
      newUpdated = MXItemStatusMap[updated];
    }
    if (key === 'status' && title === 'flight') {
      newOld = FlightStatusMap[old];
      newUpdated = FlightStatusMap[updated];
    }
    return (
      <ChangesRow key={key} data-testid={`TablePopUp--ChangesRow-${key}`}>
        <BoxTitle data-testid={`TablePopUp--Title-${key}`}>{key.replace('_', ' ')}</BoxTitle>
        <div data-testid={`TablePopUp--Old-${key}`}>{newOld || '-'}</div>
        <div data-testid={`TablePopUp--Updated-${key}`}>{newUpdated || '-'}</div>
      </ChangesRow>
    );
  };

  const sanitiseCycles = (entry): ReactNode[] => {
    const oldData = JSON.parse(entry.old);
    const newData = JSON.parse(entry.new);
    const oldKeys = Object.keys(JSON.parse(entry.old));
    const CyclesRows = oldKeys.map((key) => {
      return buildRow(key, oldData[key], newData[key]);
    });
    return CyclesRows;
  };

  const sanitiseTrendMonitoring = (old, updated, prefix?): ReactNode[] => {
    let trendMonitoringRows = [];
    if (updated) {
      const newKeys = Object.keys(updated);
      trendMonitoringRows = newKeys.map((key) => {
        if (typeof updated[key] === 'object') {
          const rows = sanitiseTrendMonitoring(old ? old[key] : {}, updated[key], key);
          return rows;
        }
        const formattedKey = prefix ? `${prefix} - ${key}` : key;
        return buildRow(formattedKey, old ? old[key] : '-', updated[key] || '-');
      });
    }

    return trendMonitoringRows;
  };

  const displayData = (): ReactNode => {
    if (!data) return null;
    return data?.changeset?.map((entry) => {
      const displayField = entry?.field?.replaceAll('_', ' ');
      if (entry.field === 'cycles') {
        return null;
      }
      if (entry.field === 'public_url_keys') {
        return null;
      }
      if (entry.field === 'cycles_json') {
        return sanitiseCycles(entry);
      }
      if (entry.field === 'custom_data') {
        const oldTrendMonitoring = entry?.old?.trend_monitoring;
        const newTrendMonitoring = entry?.new?.trend_monitoring;
        return sanitiseTrendMonitoring(oldTrendMonitoring, newTrendMonitoring);
      }
      return buildRow(displayField, entry?.old, entry?.new);
    });
  };

  const Title = data?.justification
    ? data?.justification.charAt(0).toUpperCase() + data?.justification.slice(1)
    : 'History';

  return (
    <div data-testid="TablePopUp--ModalWrapper">
      <Modal isOpen={visible} handleClose={handleClose}>
        <ScrollableDiv>
          <ModalHeader data-testid="TablePopUp--ModalHeader">
            <HeavyFont>{Title}:</HeavyFont>
            <div>{moment.utc(data?.timestamp).format(`${dateFormat} HH:mmz`)}</div>
          </ModalHeader>
          <FromRow data-testid="TablePopUp--FromRow">
            <div />
            <Header data-testid="TablePopUp--FromHeader">{formatMessage({ id: 'text.from' })}</Header>
            <Header data-testid="TablePopUp--ToHeader">{formatMessage({ id: 'text.to' })}</Header>
          </FromRow>
          {displayData()}
          <ButtonWrapper data-testid="TablePopUp--ButtonWrapper">
            <Button height="32px" onClick={handleClose}>
              {formatMessage({ id: 'text.close' })}
            </Button>
          </ButtonWrapper>
        </ScrollableDiv>
      </Modal>
    </div>
  );
};

TablePopUp.defaultProps = {
  title: '',
}

export default TablePopUp;

const initialState: DamageMapsState = {
  damageMapsMap: new Map(),
  lastFetched: 0,
  image: '',
};

export enum DamageMapsActionTypes {
  SAVE = 'damageMap/save',
  SAVE_ALL = 'damageMap/saveAll',
  SAVE_IMAGE = 'damageMap/saveImage',
}

export interface SaveDamageMapsActionTypes {
  type: DamageMapsActionTypes.SAVE;
  payload: DamageMap;
  aircraftId: string;
}

export interface SaveAllDamageMapsActionTypes {
  type: DamageMapsActionTypes.SAVE_ALL;
  payload: DamageMap[];
  aircraftId: string;
}

export interface SaveImageDamageMapsActionTypes {
  type: DamageMapsActionTypes.SAVE_IMAGE;
  payload: '';
  aircraftId: string;
}

type DamageMapsActions = SaveDamageMapsActionTypes | SaveAllDamageMapsActionTypes | SaveImageDamageMapsActionTypes;

export default function (state = initialState, action: DamageMapsActions): DamageMapsState {
  switch (action.type) {
    case DamageMapsActionTypes.SAVE_ALL: {
      const masterMap = new Map(state.damageMapsMap.entries());
      const damageMapsMap = masterMap.has(action.aircraftId) ? masterMap.get(action.aircraftId) : new Map();
      action.payload.forEach((damageMap) => {
        damageMapsMap.set(damageMap.id, {
          ...damageMapsMap.get(damageMap.id),
          ...damageMap,
          lastFetched: Date.now(),
        });
      });
      masterMap.set(action.aircraftId, damageMapsMap);
      return {
        ...state,
        damageMapsMap: masterMap,
        lastFetched: Date.now(),
      };
    }
    case DamageMapsActionTypes.SAVE: {
      const masterMap = new Map(state.damageMapsMap.entries());
      if (action.payload && action.payload.id) {
        const damageMapsMap = masterMap.has(action.aircraftId) ? masterMap.get(action.aircraftId) : new Map();
        const damageMaps = action.payload;
        damageMapsMap.set(damageMaps.id, {
          ...damageMapsMap.get(damageMaps.id),
          ...damageMaps,
          lastFetched: Date.now(),
        });
        masterMap.set(action.aircraftId, damageMapsMap);
      }
      return {
        ...state,
        damageMapsMap: masterMap,
      };
    }
    case DamageMapsActionTypes.SAVE_IMAGE: {
      return {
        ...state,
        image: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface DamageMapsState {
  damageMapsMap: Map<string, Map<string, DamageMap>>;
  lastFetched: number;
  image: '';
}

export interface DamageMap {
  aircraft_id: string;
  attachment: {
    content_type: string;
    filename: string;
    id: string;
    thumbnail_url: string;
    url: string;
  },
  category: string;
  id: string;
  last_updated: number;
  sort_order: number;
  title: string;
}

export const DAMAGE_MAP_LOCATION_OPTIONS = [
  { title: 'Fuselage', value: 'fuselage' },
  { title: 'Wings', value: 'wings' },
  { title: 'Empennage', value: 'empennage' },
  { title: 'Powerplant', value: 'powerplant' },
  { title: 'Propellor', value: 'propellor' },
  { title: 'Windows', value: 'windows' },
];

export enum DamageMapLocations {
  FUSELAGE = 'fuselage',
  WINGS = 'wings',
  EMPENNAGE = 'empennage',
  POWERPLANT = 'powerplant',
  PROPELLOR = 'propellor',
  WINDOWS = 'windows',
}

import React, { useRef } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import StatusBadge from "../Status/StatusBadge";
import { DamageReport } from "../../models/damageReports";
import { Coordinates, MarkerOffset, ZoomDimensions } from "../DraggableMarker/coordinates";
import DraggableMarker from "../DraggableMarker/DraggableMarker";

export const ReportPoint = styled.button`
  position: absolute;
  height: 24px;
  z-index: 10;
  border: none;
  cursor: ${({ clickable }): string => (!clickable ? "default" : "pointer")};
  background-color: ${({ theme, newReport }) => newReport ? theme.colours.blue : theme.colours.black};
  text-transform: capitalize;
  opacity: 0.85;
  margin-bottom: 0;
  padding: 0 6px 0 0;
  border-radius: 25px;
  font-weight: 500;
`;

export const TextWrapper = styled.span`
  display: flex;
  align-items: center;
  padding: 1px 10px;
  color: ${({ theme }) => theme.colours.white };
  svg {
    margin-right: 10px;
  }
`;

// The margin-left value keeps the marker's point aligned with where it points to on the iPad.
// Don't adjust without communication with the iPad team.
export const TriangleDown = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: ${({ theme, newReport }) => `10px solid ${newReport ? theme.colours.blue : theme.colours.black}`};
  margin-left: 39px;
`;

const markerOffset: MarkerOffset = { width: 49, height: 33 };
// enough time to block the click but allow the user to quickly click after completing a drag
const clickBlockMs = 100;

interface DamageReportMarkerProps {
  report: DamageReport;
  zoomDimensions: ZoomDimensions;
  handlePointClick: (report: DamageReport) => void;
  onReportCoordinatesChanged: (report: DamageReport, coordinates: Coordinates) => void;
  draggable?: boolean;
  clickable?: boolean;
}

const DamageReportMarker: React.FC<DamageReportMarkerProps> = ({
  report,
  zoomDimensions,
  handlePointClick,
  onReportCoordinatesChanged,
  draggable,
  clickable,
}) => {
  const { formatMessage } = useIntl();
  const dragEndTimeRef = useRef<number>(0);
  const newReport = !report.id;

  const handleCoordinatesChanged = (coordinates: Coordinates) => {
    dragEndTimeRef.current = Date.now();
    onReportCoordinatesChanged(report, coordinates);
  };

  const handleReportClick = () => {
    const withinClickBlockWindow = dragEndTimeRef.current > Date.now() - clickBlockMs;
    if (withinClickBlockWindow || !clickable) return;

    handlePointClick(report);
  };

  return (
    <DraggableMarker
      coordinates={{ xCoordinate: report.x_coordinate, yCoordinate: report.y_coordinate }}
      zoomDimensions={zoomDimensions}
      markerOffset={markerOffset}
      onCoordinatesChanged={handleCoordinatesChanged}
      disabled={!draggable}
    >
      <ReportPoint
        data-testid={`report-point-${report.id}`}
        onClick={handleReportClick}
        clickable={clickable || draggable}
        newReport={newReport}
      >
        <TextWrapper data-testid={`text-wrapper-${report.id}`}>
          <StatusBadge status={newReport ? 'draft' : report.status} dataTestId={report.id} />
          {newReport ? formatMessage({ id: 'text.newItem' }) : report.type}
        </TextWrapper>
        <TriangleDown data-testid={`triangle-down-${report.id}`} newReport={newReport} />
      </ReportPoint>
    </DraggableMarker>
  );
};

DamageReportMarker.defaultProps = {
  draggable: true,
  clickable: true,
};

export default DamageReportMarker;

import { useQuery } from "@tanstack/react-query";
import { getDamageReports } from "../../api";
import { DamageReport } from "../../../models/damageReports";
import { SortBy } from "../../../models/userSettings";
import { DamageReportFilters } from "../../../pages/DentAndBuckle/DentAndBuckle";

interface DamageReportPayload {
  page: number;
  limit: number;
  aircraft_id: string;
  sort_column?: string;
  sort_order?: string;
  search?: string;
  category?: string[];
  status?: string[];
  type?: string[];
}

const useQueryGetDamageReports = ({
  aircraft_id,
  page,
  limit,
  sortBy,
  searchValue,
  filters,
}: {
  aircraft_id: string;
  page: number;
  limit: number;
  sortBy?: SortBy[];
  searchValue?: string;
  filters?: DamageReportFilters;
}): {
  data: DamageReport[];
  isFetching: boolean;
} => {
  const payload = {
    page,
    limit,
    aircraft_id,
  } as DamageReportPayload;
  if (sortBy?.length) {
    const sort_column = sortBy[0]?.id;
    const sort_order = sortBy[0]?.desc ? 'desc' : 'asc';
    payload.sort_column = sort_column;
    payload.sort_order = sort_order;
  }
  if (searchValue) {
    payload.search = searchValue;
  }
  if (filters?.category?.length) {
    payload.category = filters.category;
  }
  if (filters?.status?.length) {
    payload.status = filters.status;
  }
  if (filters?.type?.length) {
    payload.type = filters.type;
  }
  const { data, isFetching } = useQuery({
    queryKey: ['damageReports', aircraft_id, payload],
    queryFn: () => getDamageReports(payload),
    enabled: !!aircraft_id,
    refetchOnWindowFocus: false,
  });

  return { data, isFetching };
};

export default useQueryGetDamageReports;

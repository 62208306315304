/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
import Exception403 from '../pages/403';
import { DashboardState } from '../models';
import {
  UserSettingsState,
  User,
  DashboardResource,
  UserPermission,
  PeoplePermissions,
  TechLogModule,
  FeatureFlag
} from '../models/userSettings';
import { AircraftPermission, AircraftResource, Aircraft, BaseAircraft } from '../models/aircraft';
import { updateUserDetails } from '../models/userSettings/actions';
import { saveSingleAircraft } from '../models/aircraft/actions';
import { getMe, queryAircraft } from '../services/apiNew';
import { getOauthUrl } from '../utils/oauth';
import Loading from '../components/TFLoading';
import { checkAdminRequired, hasAircraftPermission } from '../components/_utils/AuthenticationWrapper';
import { PersonCapabilities } from '../models/people';

const cookies = new Cookies();

export const useAuthenticationValidation = (
  resourceRequired?: DashboardResource,
  permissionRequired?: UserPermission,
  flag?: FeatureFlag,
  requiredModule?: TechLogModule,
  capabilityRequired?: PersonCapabilities[],
): AuthenticationValidation => {
  const { pathname } = useLocation();
  const redirectToLogin = useCallback(() => {
    const url = getOauthUrl(pathname);
    window.location.href = url;
  }, [pathname]);

  useEffect(() => {
    if (window.location.hostname === 'dashboard.trustflight.io') {
      try {
        const trackingId = 'UA-180545911-1';
        ReactGA.initialize(trackingId);
        ReactGA.pageview(`Production ${pathname.replace(/\/*?([a-zA-Z0-9]*-[a-zA-Z0-9]*)/gm, '')}`);
      } catch (e) {
        console.error(e);
      }
    }
  }, [pathname]);

  const [authenticationObject, setAuthenticationObject] = useState<AuthenticationValidation>({
    loading: true,
    authenticated: false,
    lastChecked: Date.now(),
  });
  const {
    id,
    people: peoplePermissions,
    featureFlags,
    enabledModules,
    capabilities,
  } = useSelector<
    DashboardState,
    {
      id: string;
      people: PeoplePermissions[];
      featureFlags: FeatureFlag[];
      enabledModules: TechLogModule[];
      capabilities: PersonCapabilities[];
    }
  >(({ userSettings }) => ({
    id: userSettings.details?.id,
    people: userSettings.details?.people,
    enabledModules: userSettings.details?.operators?.reduce((modules, op) => {
      if (op.enabled_tech_log_modules) {
        op.enabled_tech_log_modules.forEach((module) => {
          if (!modules.includes(module.module_name.toLowerCase())) {
            modules.push(module.module_name.toLowerCase());
          }
        });
      }
      return modules;
    }, []),
    featureFlags: userSettings.details?.operators?.reduce((flags, op) => {
      if (op.feature_flags) {
        op.feature_flags.forEach((f) => {
          if (!flags.includes(f.feature_name)) {
            flags.push(f.feature_name);
          }
        });
      }
      return flags;
    }, []),
    capabilities: userSettings.details?.people?.reduce((capability_keys, person) => {
      if (person.capability_keys) {
        person.capability_keys.forEach((c) => {
          if (!capability_keys.includes(c as PersonCapabilities)) {
            capability_keys.push(c as PersonCapabilities);
          }
        });
      }
      return capability_keys;
    }, [] as PersonCapabilities[]),
  }));

  const dispatch = useDispatch();
  const [waitingForMe, setWaitingForMe] = useState(false);
  const { auth, userId } = cookies.getAll();
  useEffect(() => {
    if (authenticationObject.loading && id === userId && auth) {
      // Got valid user in the Redux State, let's update the authenticated object and call it a day
      // I have short circuiting global permissions, until we get org level permissions
      // to re-enabled add hasDashboardPermission(peoplePermissions, resourceRequired, permissionRequired)
      // as value on authenticated prop in setAuthenticationObject below.
      const hasRequiredModule = requiredModule ? enabledModules.includes(requiredModule) : true;
      const hasFeatureFlag = flag ? featureFlags.includes(flag) : true;
      const hasCapability = capabilityRequired ? capabilities.some((cap) => capabilityRequired.includes(cap)) : true;

      setAuthenticationObject({
        loading: false,
        authenticated: hasRequiredModule && hasFeatureFlag && hasCapability,
        lastChecked: Date.now(),
      });
    } else if (authenticationObject.loading && auth && !peoplePermissions && !waitingForMe) {
      setWaitingForMe(true);
      // Got auth token but no user in state, let's go get one and dispatch it to Redux once that's done
      getMe()
        .then(({ data }: { data: User }) => {
          dispatch(updateUserDetails({ details: data }));
          setWaitingForMe(false);
        })
        .catch(() => {
          setWaitingForMe(false);
          redirectToLogin();
        });
    } else if (!auth) {
      // No login data found on the client, let's send them to CoreAPI for authentication
      redirectToLogin();
    }
  }, [
    authenticationObject.loading,
    userId,
    auth,
    dispatch,
    id,
    peoplePermissions,
    resourceRequired,
    permissionRequired,
    redirectToLogin,
  ]);

  return authenticationObject;
};

export const useAircraftAuthenticationValidation = (
  resourceRequired?: AircraftResource,
  permissionRequired?: AircraftPermission,
  flag?: FeatureFlag,
  onlyAdmin?: boolean,
): AuthenticationValidation => {
  const { pathname } = useLocation();
  const redirectToLogin = useCallback(() => {
    const url = getOauthUrl(pathname);
    window.location.href = url;
  }, [pathname]);

  const [authenticationObject, setAuthenticationObject] = useState<AuthenticationValidation>({
    loading: true,
    authenticated: false,
    lastChecked: Date.now(),
  });

  interface ParamTypes {
    id: string;
  }
  const { id: aircraftId } = useParams<ParamTypes>();
  const { auth } = cookies.getAll();
  const {
    aircraftMap,
    people: peoplePermissions,
    userSettings: settings,
  } = useSelector<
    DashboardState,
    { aircraftMap: Map<string, Aircraft | BaseAircraft>; people: PeoplePermissions[]; userSettings: UserSettingsState }
  >(({ aircraft, userSettings }) => ({
    aircraftMap: aircraft.aircraftMap,
    people: userSettings.details?.people,
    userSettings,
  }));
  const requiredAircraft = aircraftMap.get(aircraftId);

  const personWithPermissions =
    peoplePermissions && peoplePermissions.find((person) => person.permission_groups[1].aircraft.includes(aircraftId));

  const aircraftPermissions =
    personWithPermissions && personWithPermissions.permission_groups && personWithPermissions.permission_groups[1];

  let operatorFeatureFlags = [];
  if (flag && settings?.details && requiredAircraft) {
    const currentOp = settings.details?.operators.find((op) => op.id === requiredAircraft.operator_id);
    operatorFeatureFlags = currentOp.feature_flags.map((f) => f.feature_name);
  }

  const dispatch = useDispatch();
  const [waitingForMe, setWaitingForMe] = useState(false);

  useEffect(() => {
    if (authenticationObject.loading && requiredAircraft && auth && aircraftPermissions) {
      const featureFlagsEnabled = flag ? operatorFeatureFlags.includes(flag) : true;
      setAuthenticationObject({
        loading: false,
        authenticated:
          featureFlagsEnabled &&
          checkAdminRequired(personWithPermissions, onlyAdmin) &&
          hasAircraftPermission(requiredAircraft, resourceRequired, permissionRequired, aircraftPermissions),
        lastChecked: Date.now(),
      });
    } else if (authenticationObject.loading && auth && !peoplePermissions && !waitingForMe) {
      setWaitingForMe(true);
      getMe()
        .then(({ data }: { data: User }) => {
          dispatch(updateUserDetails({ details: data }));
          setWaitingForMe(false);
        })
        .catch(() => {
          redirectToLogin();
          setWaitingForMe(false);
        });
      queryAircraft(aircraftId)
        .then(({ data }: { data: Aircraft }) => {
          dispatch(saveSingleAircraft({ payload: data }));
        })
        .catch((error) => {
          console.error(error.message);
          redirectToLogin();
        });
    } else if (!auth) {
      // No login data found on the client, let's send them to CoreAPI for authentication
      redirectToLogin();
    }
  }, [
    authenticationObject.loading,
    auth,
    aircraftId,
    dispatch,
    requiredAircraft,
    resourceRequired,
    permissionRequired,
    redirectToLogin,
    peoplePermissions,
    aircraftPermissions,
  ]);

  return authenticationObject;
};

export const LoadingScreen: React.FC = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      minWidth: '100vw',
    }}
    data-testid="AuthLoadingScreen"
  >
    <Loading loading theme="transparent" />
  </div>
);

interface AuthProviderProps {
  resourceRequired?: DashboardResource;
  permissionRequired?: UserPermission;
  featureFlag?: FeatureFlag;
  moduleRequired?: TechLogModule;
  capabilityRequired?: PersonCapabilities[];
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children, resourceRequired, permissionRequired, featureFlag, moduleRequired, capabilityRequired }) => {
  const { authenticated, loading } = useAuthenticationValidation(
    resourceRequired,
    permissionRequired,
    featureFlag,
    moduleRequired,
    capabilityRequired,
  );

  if (authenticated) {
    return <>{children}</>;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  //  TODO: redirect to a different authenticated route
  return <Exception403 />;
};

AuthProvider.defaultProps = {
  resourceRequired: undefined,
  permissionRequired: undefined,
  featureFlag: undefined,
  moduleRequired: undefined,
  capabilityRequired: undefined,
};

interface AircraftAuthProviderProps {
  resourceRequired?: AircraftResource;
  permissionRequired?: AircraftPermission;
  featureFlag?: FeatureFlag;
  onlyAdmin?: boolean;
}

export const AircraftAuthProvider: React.FC<AircraftAuthProviderProps> = ({
  children, resourceRequired, permissionRequired, featureFlag, onlyAdmin }) => {
  const { authenticated, loading } = useAircraftAuthenticationValidation(
    resourceRequired,
    permissionRequired,
    featureFlag,
    onlyAdmin,
  );
  if (authenticated) {
    return <>{children}</>;
  }
  if (loading) {
    return <LoadingScreen />;
  }
  //  TODO: redirect to a different authenticated route
  return <Exception403 />;
};

AircraftAuthProvider.defaultProps = {
  resourceRequired: undefined,
  permissionRequired: undefined,
  featureFlag: undefined,
  onlyAdmin: undefined,
};

export interface AuthenticationValidation {
  loading: boolean;
  authenticated: boolean;
  lastChecked: number;
}
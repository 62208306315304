import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { updateCabinIssue } from '../../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../../../models/toasts';
import { CabinIssuePayload } from '../CabinIssueDrawer';

const useMutationUpdateCabinIssue = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const updateIssue = useMutation({
    mutationFn: (payload: CabinIssuePayload) => {
      return updateCabinIssue(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cabinIssues'] });
      queryClient.invalidateQueries({ queryKey: ['cabinIssueTotals'] });
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.cabinIssueUpdated' }),
            message: '',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: () => {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.errorUpdatingCabinIssue' }),
            message: '',
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });

  return updateIssue;
};

export default useMutationUpdateCabinIssue;

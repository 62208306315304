import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button } from '@arcflight/tf-component-library';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';

interface EmptyStateProps {
  image?: string;
  text?: string;
  subText?: string | ReactElement;
  button?: string;
  backgroundColor?: string;
  buttonAction?: () => void;
  height?: string;
  subTextWidth?: string;
}

const EmptyStateWrapper = styled.div`
  min-height: ${({ height }): string => (height ? `${height}` : '350px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: ${({ backgroundColor }): string => backgroundColor || '#fff'};
  @media (max-width: 450px) {
    width: auto;
  }
`;

const StyledImage = styled.img`
  width: 120px;
  height: 120px;
`;

const StyledText = styled.div`
  color: rgba(36, 45, 65, 0.5);
  letter-spacing: 0.42px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin: 8px 0;
`;

const StyledSubText = styled.div`
  color: rgba(36, 45, 65, 0.6);
  line-height: 1.38;
  letter-spacing: 0.2px;
  font-size: 13px;
  margin-bottom: 20px;
  max-width: ${({ subTextWidth }): string => (subTextWidth ? `${subTextWidth}` : '260px')};
  text-align: center;
`;

const EmptyState: React.FC<EmptyStateProps> = ({
  image,
  text,
  subText,
  button,
  buttonAction,
  backgroundColor,
  height,
  subTextWidth,
}) => {
  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const isAircraftLocked = aircraftMap.get(id)?.locked;
  const aircraftIsArchived = aircraftMap.get(id)?.billing_status === 'Archived';

  return (
    <EmptyStateWrapper backgroundColor={backgroundColor} height={height}>
      {image ? <StyledImage src={image} alt="empty state image" /> : null}
      {text ? <StyledText>{text}</StyledText> : null}
      {subText && !(isAircraftLocked || aircraftIsArchived) ? (
        <StyledSubText subTextWidth={subTextWidth}>{subText}</StyledSubText>
      ) : null}
      {button && !(isAircraftLocked || aircraftIsArchived) ? (
        <Button height="24px" onClick={(): void => buttonAction()}>
          {button}
        </Button>
      ) : null}
    </EmptyStateWrapper>
  );
};

export default EmptyState;

import { DamageMapLocations } from '../../models/damageMaps';
import { DamageReportStatus, DamageReportTotals, DamageReportType } from '../../models/damageReports';
import { stringToTitleCase } from '../../utils/utils';

interface damageReportFilterModelReturnType {
  groupLabel: string;
  filters: {
    label: string;
    key: string;
    value: string;
    count: number;
    filterFunction: () => void;
  }[];
}

const damageReportFilterModel = ({
  groupLabels,
  totals,
}: {
  groupLabels: Record<string, string>;
  totals?: DamageReportTotals;
}): damageReportFilterModelReturnType[] => {
  const createLocationFilterObjects = () => {
    return Object.values(DamageMapLocations).map((location) => ({
      label: stringToTitleCase(location),
      key: 'category',
      value: location,
      count: Number(totals?.category?.[location] || 0),
      filterFunction: () => {},
    }));
  };

  const createStatusFilterObject = () => {
    return Object.values(DamageReportStatus).map((status) => ({
      label: stringToTitleCase(status),
      key: 'status',
      value: status,
      count: Number(totals?.status?.[status] || 0),
      filterFunction: () => {},
    }));
  }

  const createTypeFilterObject = () => {
    return Object.values(DamageReportType).map((type) => ({
      label: stringToTitleCase(type),
      key: 'type',
      value: type,
      count: Number(totals?.type?.[type] || 0),
      filterFunction: () => {},
    }));
  }

  return [
    {
      groupLabel: groupLabels.location,
      filters: createLocationFilterObjects(),
    },
    {
      groupLabel: groupLabels.status,
      filters: createStatusFilterObject(),
    },
    {
      groupLabel: groupLabels.type,
      filters: createTypeFilterObject(),
    },
  ];
};

export default damageReportFilterModel;

const COORDINATE_SCALE_FACTOR = 0.5;
const MIDDLE_OF_IMAGE_FACTOR = 2;

export interface MarkerOffset {
  width: number;
  height: number;
}

export interface Coordinates {
  xCoordinate: number;
  yCoordinate: number;
}

export interface ZoomDimensions {
  width: number;
  height: number;
}

export interface Position {
  width: number;
  height: number;
}

export const convertCoordinatesToPosition = (
  coordinates: Coordinates,
  zoomedDimensions: ZoomDimensions,
  markerOffset: MarkerOffset,
): Position => {
  const xPercentage = coordinates.xCoordinate / COORDINATE_SCALE_FACTOR;
  const yPercentage = coordinates.yCoordinate / COORDINATE_SCALE_FACTOR;
  const midImageWidth = zoomedDimensions.width / MIDDLE_OF_IMAGE_FACTOR;
  const midImageHeight = zoomedDimensions.height / MIDDLE_OF_IMAGE_FACTOR;
  const widthDistance = midImageWidth * xPercentage;
  const heightDistance = midImageHeight * yPercentage;

  return {
    width: midImageWidth + widthDistance - markerOffset.width,
    height: midImageHeight + heightDistance - markerOffset.height
  };
};

export const convertPositionToCoordinates = (
  position: Position,
  zoomDimensions: ZoomDimensions,
  markerOffset: MarkerOffset,
): Coordinates => {
  const midImageWidth = zoomDimensions.width / MIDDLE_OF_IMAGE_FACTOR;
  const midImageHeight = zoomDimensions.height / MIDDLE_OF_IMAGE_FACTOR;
  const adjustedWidth = position.width + markerOffset.width;
  const adjustedHeight = position.height + markerOffset.height;
  const widthDistance = adjustedWidth - midImageWidth;
  const heightDistance = adjustedHeight - midImageHeight;
  const xPercentage = widthDistance / midImageWidth;
  const yPercentage = heightDistance / midImageHeight;

  return {
    xCoordinate: xPercentage * COORDINATE_SCALE_FACTOR,
    yCoordinate: yPercentage * COORDINATE_SCALE_FACTOR
  };
};


import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Header } from '../../components/CommonStyledComponents/CommonStyledComponents';
import TFRadioInput from '../../components/TFRadioInput/TFRadioInput';
import { Attachment, DAMAGE_REPORT_TYPES, DamageReport } from '../../models/damageReports';
import FileViewer from '../../components/TFFileViewer/fileViewer';
import { convertToImgFile } from '../../utils/utils';

interface EditDamageReportDrawerProps {
  damageReportData: DamageReport;
  updateDamageReportData: (changes: { value: string | Attachment[]; key: string }[]) => void;
}

const TextBox = styled.textarea`
  width: 100%;
  height: 70px;
  margin-right: 24px;
  border-radius: 2px;
  border-color: rgba(36, 45, 65, 0.1);
  background-color: #f3f7fc;
  padding-left: 8px;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
`;

const EditDamageReportDrawer: React.FC<EditDamageReportDrawerProps> = ({
  damageReportData,
  updateDamageReportData,
}) => {
  const { formatMessage } = useIntl();

  const handleTypeChange = (value: string) => {
    updateDamageReportData([{ key: 'type', value }]);
  };

  const handleDetailsChange = (value: string) => {
    updateDamageReportData([{ key: 'details', value }]);
  }

  const handleAttachmentUpload = async (files): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      const originalAttachments = damageReportData?.attachments || [];
      const newAttachments = originalAttachments.concat(...base64Attachments);
      updateDamageReportData([{ value: newAttachments, key: 'attachments' }]);
    }
  };

  const handleAttachmentDelete = ({ fileId, fileName }): void => {
    const newAttachmentsList = damageReportData?.attachments.filter(
      (item) => {
        if ('id' in item) {
          return item.id !== fileId;
        }
        return item.attachment_file_name !== fileName;
      }
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    updateDamageReportData([{ value: newAttachmentsList, key: 'attachments' }]);
  };

  return (
    <>
      <SectionWrapper data-testid="EditDamageReportDrawer--Type">
        <Header>{formatMessage({ id: 'text.type' })}</Header>
        <TFRadioInput
          options={DAMAGE_REPORT_TYPES}
          onChange={handleTypeChange}
          value={damageReportData?.type}
          id="EditDamageReportDrawer"
        />
      </SectionWrapper>
      <SectionWrapper data-testid="EditDamageReportDrawer--Details">
      <Header>{formatMessage({ id: 'text.details' })}</Header>
      <TextBox
        onChange={(e) => handleDetailsChange(e.target.value)}
        value={damageReportData?.details}
        data-testid="EditDamageReportDrawer--DetailsInput"
      />
      </SectionWrapper>
      <SectionWrapper data-testid="EditDamageReportDrawer--Attachments">
        <Header>{formatMessage({ id: 'text.attachments' })}</Header>
        <FileViewer
          attachments={damageReportData?.attachments || []}
          addAttachment={handleAttachmentUpload}
          handleDeleteClick={handleAttachmentDelete}
        />
      </SectionWrapper>
    </>
  )
};

export default EditDamageReportDrawer;

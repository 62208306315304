import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { addToast, ToastCategories, ToastTypes } from "../../models/toasts";

interface RefreshMutationConfig<TPayload> {
  mutationFn: (payload: TPayload) => Promise<unknown>;
  invalidationQueryKeys: string[][];
  successMessage?: {
    title?: string;
    message?: string;
  };
  errorMessage?: {
    title?: string;
    message?: string;
  };
  onSuccessCallback?: (data?: unknown) => void;
}

const useRefreshMutation = <TPayload,>(
  config: RefreshMutationConfig<TPayload>
): UseMutationResult<unknown, Error, TPayload, unknown> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: config.mutationFn,
    onSuccess: (data) => {
      config.invalidationQueryKeys.forEach( key => queryClient.invalidateQueries({ queryKey: key }));
      config.onSuccessCallback?.(data);

      dispatch(
        addToast({
          payload: {
            title: config.successMessage?.title ?? 'Operation successful',
            message: config.successMessage?.message ?? 'The operation completed successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        })
      );
    },
    onError: (error: Error) => {
      dispatch(
        addToast({
          payload: {
            title: config.errorMessage?.title ?? 'Operation failed',
            message: config.errorMessage?.message ? `${config.errorMessage.message}: ${error.message}` : error.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        })
      );
    },
  });
};

export default useRefreshMutation;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { intl } from '../../providers/IntlProvider';
import { addAircraftMaintenance, updateAircraft } from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import {
  queryAircraft,
  queryAircrafts,
  queryAircraftByOperator,
  getAircraftStatus,
  getAircraftPeople,
  querySimpleAircraftByOperator,
} from '../../services/apiNew';
import { AircraftActionTypes } from './index';

export function getAllAircraft() {
  return async (dispatch: any): Promise<any> => {
    const res = await queryAircrafts();
    dispatch({
      type: AircraftActionTypes.SAVE_ALL,
      payload: res.data,
    });
  };
}

export const getAircraftByOperators =
  (operator) =>
  async (dispatch): Promise<any> => {
    const res = await queryAircraftByOperator(operator.id);
    dispatch({
      type: AircraftActionTypes.SAVE_FLEET,
      payload: res.data,
    });
  };

export const updateAircraftInFleet =
  (aircraft) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: AircraftActionTypes.SAVE_FLEET,
      payload: [aircraft],
    });
  };

export const getSimpleAircraftByOperators = (operator) => async (): Promise<any> => {
  const res = await querySimpleAircraftByOperator(operator.id);
  if (res.status >= 199 || res.status <= 400) {
    return res.data;
  }
  return null;
};

export const fetchAircraftStatus =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const res = await getAircraftStatus(payload);
    dispatch({
      type: AircraftActionTypes.SAVE_STATUS,
      payload: {
        maintenance_status: res.data,
        id: payload,
      },
    });
  };

export const saveAllAircraft =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: AircraftActionTypes.SAVE_ALL,
      payload,
    });
  };

export const getSingleAircraft =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await queryAircraft(payload);
    if (response.statusText === 'OK') {
      dispatch({
        type: AircraftActionTypes.SAVE,
        payload: response.data,
      });
    }
  };

export const saveSingleAircraft =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: AircraftActionTypes.SAVE,
      payload,
    });
  };

export const fetch =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    console.error('aircraft/fetch is deprecated please use getSingleAircraft instead');
    const response = await queryAircraft(payload);
      dispatch({
        type: AircraftActionTypes.SAVE,
        payload: response.data,
      });
  };

export const add =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: AircraftActionTypes.SAVE_NEW,
      payload,
    });
  };

export const addMaintenance =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await addAircraftMaintenance(payload);
    if (response.statusCode > 199 && response.statusCode < 400) {
      dispatch({
        type: AircraftActionTypes.SAVE_NEW,
        payload: response.body.aircraft,
      });
    }
  };

export const update =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await updateAircraft(payload);
    if (response.statusCode > 199 && response.statusCode < 400) {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.aircraftUpdated' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
      dispatch({
        type: AircraftActionTypes.SAVE,
        payload: response.body,
      });
    }
  };

export const updateNewAircraft =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: AircraftActionTypes.SAVE_NEW,
      payload,
    });
  };

export const updateSkipAPI =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: AircraftActionTypes.SAVE,
      payload,
    });
  };

export const fetchAircraftPeople =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await getAircraftPeople(payload);
    dispatch({
      type: AircraftActionTypes.ADD_PEOPLE,
      payload: {
        people: response.data,
        id: payload,
      },
    });
  };

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DashboardState } from '../../models';
import { CustomField } from '../../models/aircraft';
import TFDropdown from '../../components/TFDropdown/TFDropdown';
import TFNumberInput from '../../components/TFNumberInput/TFNumberInput';
import TFFormLabel from '../../components/TFFormLabel/TFFormLabel';
import { DataValue } from '../../utils/updateLocalDataObject';

interface CustomFieldsEditPositioningProps {
  customFieldsData: CustomField;
  updateCustomFieldsData: (changes: { value: DataValue; key: string }[]) => void;
  fieldDisplayArray: string[];
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const SelectLocationText = styled.span`
  color: ${({ theme }): string => theme.colours.black70Alpha};
  line-height: 40px;
`;

const INSERT_AFTER_TRIP_VALUES = [
  { title: 'Date', value: 'date' },
  { title: 'Callsign', value: 'callsign' },
  { title: 'Captain ID', value: 'captain_id' },
  { title: 'First Officer ID', value: 'first_officer_id' },
  { title: 'Trip Category', value: 'trip_category' },
];

const INSERT_AFTER_PREFLIGHT_VALUES = [
  { title: 'Preflight MX Check IDs', value: 'preflight_mx_check_ids' },
  { title: 'Departure Airport', value: 'departure_airport' },
  { title: 'Arrival Airport', value: 'arrival_airport' },
  { title: 'Passengers Male', value: 'passengers_male' },
  { title: 'Passengers Female', value: 'passengers_female' },
  { title: 'Passengers Children', value: 'passengers_children' },
  { title: 'Passengers Infants', value: 'passengers_infants' },
  { title: 'Baggage Weight', value: 'baggage_weight' },
  { title: 'Remote Deice', value: 'remote_deice' },
  { title: 'Planned Fuel Burn', value: 'planned_fuel_burn' },
  { title: 'Deice events', value: 'deice_events' },
  { title: 'GPU usage', value: 'gpu_usage' },
];

const INSERT_AFTER_POSTFLIGHT_VALUES = [
  { title: 'Actual Arrival Airport', value: 'actual_arrival_airport' },
  { title: 'Deice events', value: 'deice_events' },
  { title: 'Time Off Blocks', value: 'time_off_blocks' },
  { title: 'Time Takeoff', value: 'time_takeoff' },
  { title: 'Time Landing', value: 'time_landing' },
  { title: 'Time On Blocks', value: 'time_on_blocks' },
  { title: 'Hobbs Departure', value: 'hobbs_departure' },
  { title: 'Hobbs Arrival', value: 'hobbs_arrival' },
  { title: 'APU reading', value: 'apu_reading' },
  { title: 'APU Seconds', value: 'apu_seconds' },
  { title: 'APU Cycles Reading', value: 'apu_cycles_reading' },
  { title: 'APU', value: 'apu' },
  { title: 'Landings Count', value: 'landings_count' },
  { title: 'Airframe', value: 'airframe' },
  { title: 'Max Flight Level', value: 'max_flight_level' },
  { title: 'Pressurised Cycle', value: 'pressurised_cycle' },
  { title: 'Defects Reported', value: 'defects_reported' },
];

export const INSERT_AFTER_VALUES = {
  trip: INSERT_AFTER_TRIP_VALUES,
  preflight: INSERT_AFTER_PREFLIGHT_VALUES,
  postflight: INSERT_AFTER_POSTFLIGHT_VALUES,
};

export const getInsertAfterTitle = (
  customField: CustomField,
  passedArray?: { title: string; value: string }[],
): string => {
  const type = customField?.view;
  return (
    INSERT_AFTER_VALUES[type].concat(passedArray || [])?.find((item) => item.value === customField?.insert_after)
      ?.title || ''
  );
};

const CustomFieldsEditPositioning: React.FC<CustomFieldsEditPositioningProps> = ({
  customFieldsData,
  updateCustomFieldsData,
  fieldDisplayArray,
}) => {
  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const [insertAfterArray, setInsertAfterArray] = useState([]);

  const engineCount = aircraftMap.get(id).aircraft_type.engine_count;

  const buildEngineArray = (count: number): { title: string; value: string }[] => {
    const engineArray = [];
    for (let i = 1; i <= count; i += 1) {
      engineArray.push({ title: `Engine ${i}`, value: `engine_${i}` });
    }
    return engineArray;
  };

  const insertFieldsIntoCorrectPositionInArray = (
    existingArray: { title: string; value: string }[],
    type: string,
  ): { title: string; value: string }[] => {
    const existingCustomFields = Object.values(aircraftMap.get(id)?.custom_fields || {})?.filter(
      (item: CustomField) => item.view === type,
    );
    const existingWithInsertAfter = existingCustomFields.filter((item: CustomField) => {
      return item?.insert_after;
    });
    const existingWithoutInsertAfter = existingCustomFields
      .filter((item: CustomField) => !item?.insert_after)
      .map((item: CustomField) => ({ title: `${item?.title} - ${item?.heading}`, value: item?.key }));
    if (existingWithoutInsertAfter.length > 0) {
      existingWithoutInsertAfter.forEach((item) => existingArray.push(item));
    }
    if (existingWithInsertAfter.length > 0) {
      const builtValues = existingWithInsertAfter.map((item: CustomField) => ({
        title: `${item?.title} - ${item?.heading}`,
        value: item?.key,
        insertAfter: item?.insert_after,
      }));
      builtValues.forEach((item) => {
        const index = existingArray.findIndex((value) => {
          return value.value === item.insertAfter;
        });
        if (index !== -1) {
          existingArray.splice(index + 1, 0, item);
        } else {
          existingArray.push(item);
        }
      });
    }
    setInsertAfterArray(existingArray);
    return existingArray;
  };

  useEffect(() => {
    setInsertAfterArray([]);
    if (customFieldsData?.view === 'postflight') {
      const airframeIndex = INSERT_AFTER_POSTFLIGHT_VALUES.findIndex((value) => value.value === 'airframe');
      const engineArray = buildEngineArray(engineCount);
      const separatedArray = [...INSERT_AFTER_POSTFLIGHT_VALUES];
      separatedArray.splice(airframeIndex + 1, 0, ...engineArray);
      insertFieldsIntoCorrectPositionInArray(separatedArray, 'postflight');
    } else if (customFieldsData?.view === 'preflight') {
      const separatedArray = [...INSERT_AFTER_PREFLIGHT_VALUES];
      insertFieldsIntoCorrectPositionInArray(separatedArray, 'preflight');
    } else if (customFieldsData?.view === 'trip') {
      const separatedArray = [...INSERT_AFTER_TRIP_VALUES];
      insertFieldsIntoCorrectPositionInArray(separatedArray, 'trip');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldsData]);

  return (
    <Wrapper data-testid="CustomFieldsEditPositioning--Wrapper">
      <TFNumberInput
        id="Positioning-Order"
        value={customFieldsData?.order}
        onChange={(value: number): void => updateCustomFieldsData([{ key: 'order', value }])}
        label="Order"
        tooltip={formatMessage({ id: 'text.multipleItemsOnSameRowOrderDisplayedIn' })}
        optional
      />
      {fieldDisplayArray?.includes('process_order') && (
        <TFNumberInput
          id="Positioning-ProcessOrder"
          value={customFieldsData?.process_order}
          onChange={(value: number): void => updateCustomFieldsData([{ key: 'process_order', value }])}
          label="Process order"
          optional
        />
      )}
      {!customFieldsData?.view ? (
        <div>
          <TFFormLabel
            label="Insert after"
            id="Positioning-InsertAfter"
            optional
            tooltip={formatMessage({ id: 'text.newFieldDisplayedAfterThisField' })}
          />
          <SelectLocationText>{formatMessage({ id: 'text.pleaseSelectALocation' })}</SelectLocationText>
        </div>
      ) : (
        <TFDropdown
          id="Positioning-InsertAfter"
          initialValue={{
            title: getInsertAfterTitle(customFieldsData, insertAfterArray),
            value: customFieldsData?.insert_after,
          }}
          onSelect={(item): void => updateCustomFieldsData([{ key: 'insert_after', value: item?.value }])}
          label="Insert after"
          optional
          tooltip={formatMessage({ id: 'text.newFieldDisplayedAfterThisField' })}
          options={insertAfterArray}
        />
      )}
    </Wrapper>
  );
};

export default CustomFieldsEditPositioning;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Accordion, Button } from '@arcflight/tf-component-library';
import { Aircraft, BaseAircraft } from '../../models/aircraft';
import { DataValue } from '../../utils/updateLocalDataObject';
import icon from '../../assets/icon-card-crs-signoff.svg';
import AircraftSettingsCard from './AircraftSettingsCard';
import {
  AccordionWrapper,
  ButtonWrapper,
  ItemWrapper,
  Label,
  SaveWrapper,
  StyledGrid,
  StyledInput,
  Text,
} from './AircraftAdvancedSettings';
import ShowHideToggle from './ShowHideToggle';

interface AircraftCRSResolutionFieldsProps {
  aircraft: Aircraft | BaseAircraft;
  updateAircraftData: (changes: { value: DataValue; key: string }[]) => void;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
  isAdmin: boolean;
}

const Header = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #242d41;
  margin: 0 0 16px 16px;
`;

const Divider = styled.div`
  margin: 0 16px 16px;
  background-color: rgba(36, 45, 65, 0.05);
  height: 1px;
  width: -webkit-fill-available;
`;

const PART_REPLEACEMENT_FIELDS = [
  {
    title: 'partReplacementPositionField',
    key: 'part_info_position',
  },
  {
    title: 'partReplacementDescriptionField',
    key: 'part_info_description',
  },
  {
    title: 'partReplacementTraceOffField',
    key: 'part_info_batch_off',
  },
  {
    title: 'partReplacementBatchOnField',
    key: 'part_info_batch',
  },
]

const AircraftCRSResolutionFields: React.FC<AircraftCRSResolutionFieldsProps> = ({
  aircraft,
  updateAircraftData,
  handleCancelClick,
  handleSaveClick,
  isAdmin,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const { formatMessage } = useIntl();

  const handleLocalCancelClick = (): void => {
    setEditMode(false);
    handleCancelClick();
  };

  const handleLocalSaveClick = (): void => {
    handleSaveClick();
    setEditMode(false);
  };

  const handleToggleChange = (value: boolean, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { enabled: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const handleNameChange = (value: string, key: string): void => {
    const newStandardFields = aircraft?.standard_fields || {};
    newStandardFields[`${key}`] = { ...newStandardFields[`${key}`], name_override: value };
    updateAircraftData([{ value: newStandardFields, key: 'standard_fields' }]);
  };

  const expansionChange = (expansion: boolean): void => {
    if (editMode) {
      setExpanded(true);
    } else {
      setExpanded(expansion);
    }
  };

  const buildPartReplacementFields = () => {
    return PART_REPLEACEMENT_FIELDS.map((field) => {
      return (
        <StyledGrid data-testid={`AircraftCRSResolutionFields--${field.key}`}>
          <ItemWrapper>
            <Label edit={editMode}>{formatMessage({ id: `text.${field.title}` })}</Label>
            {!editMode ? (
              <Text data-testid={`AircraftCRSResolutionFields--${field.key}-enabled`}>
                {aircraft?.standard_fields?.[field.key]?.enabled ? 'Show' : 'Hide'}
              </Text>
            ) : (
              <ShowHideToggle
                value={aircraft?.standard_fields?.[field.key]?.enabled}
                handleChange={(value: boolean): void => handleToggleChange(value, field.key)}
                dataTestId={`${field.key}-enabled`}
              />
            )}
          </ItemWrapper>
          {aircraft?.standard_fields?.[field.key]?.enabled ? (
            <ItemWrapper>
              <Label edit={editMode}>
                {formatMessage({ id: 'text.nameOverride' })}
              </Label>
              {!editMode ? (
                <Text data-testid={`AircraftCRSResolutionFields--${field.key}-nameoverride`}>
                  {aircraft?.standard_fields?.[field.key]?.name_override || '-'}
                </Text>
              ) : (
                <StyledInput
                  value={aircraft?.standard_fields?.[field.key]?.name_override}
                  type="text"
                  onChange={(e): void => handleNameChange(e.target.value, field.key)}
                  data-testid={`AircraftCRSResolutionFields--${field.key}-nameoverride-input`}
                />
              )}
            </ItemWrapper>
          ) : null}
        </StyledGrid>
      );
    });
  };

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={expanded}
        onExpansionChange={expansionChange}
        headerContent={
          <AircraftSettingsCard
            icon={icon}
            title="Resolution workflow configuration"
            buttonText="Edit workflow configuration"
            setEditMode={setEditMode}
            editMode={editMode}
            isAdmin={isAdmin}
            subTitle="Including part replacement fields"
          />
        }
      >
        <Header data-testid="AircraftCRSResolutionFields--Header">
          {formatMessage({ id: 'text.partReplacements' })}
        </Header>
        {buildPartReplacementFields()}
        <Divider />
        <Header>{formatMessage({ id: 'text.crsSignoff' })}</Header>
        <StyledGrid>
          <ItemWrapper span="2">
            <Label edit={editMode}>{formatMessage({ id: 'text.titleForEngineerLicenceAuthorisationField' })}</Label>
            {!editMode ? (
              <Text>{aircraft?.standard_fields?.crs_engineer_licence?.name_override || '-'}</Text>
            ) : (
              <StyledInput
                value={aircraft?.standard_fields?.crs_engineer_licence?.name_override}
                type="text"
                onChange={(e): void => handleNameChange(e.target.value, 'crs_engineer_licence')}
              />
            )}
          </ItemWrapper>
        </StyledGrid>
        {editMode ? (
          <ButtonWrapper>
            <SaveWrapper>
              <Button height="32px" onClick={handleLocalSaveClick}>
                {formatMessage({ id: 'text.save' })}
              </Button>
            </SaveWrapper>
            <Button height="32px" primary={false} onClick={handleLocalCancelClick}>
              {formatMessage({ id: 'text.cancel' })}
            </Button>
          </ButtonWrapper>
        ) : null}
      </Accordion>
    </AccordionWrapper>
  );
};

export default AircraftCRSResolutionFields;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button, Search } from '@arcflight/tf-component-library';
import { Divider } from '../../components/CommonStyledComponents/CommonStyledComponents';
import TFDropdown from '../../components/TFDropdown/TFDropdown';
import plusIcon from '../../assets/plus.svg';
import { stringToTitleCase } from '../../utils/utils';
import { DAMAGE_MAP_LOCATION_OPTIONS, DamageMap } from '../../models/damageMaps';
import {
  changeDrawerContent,
  changeDrawerMode,
  changeDrawerVisibility,
  changeModalContent,
  changeModalVisibility,
  DrawerMode
} from '../../models/drawer';
import DamageMapDrawer from './DamageMapDrawer';

interface DABTableHeaderProps {
  tableView: boolean;
  damageMapsData: DamageMap[];
  handleEditSchematic: () => void;
  setCurrentMapId: (value: string) => void;
  handleNewDamageReport: (resetSchematic?: boolean) => void;
  setSearchValue: (value: string) => void;
  reset: boolean;
}

const HeaderWrapper = styled.div`
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const InnerButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const EditUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const EditButtonWrapper = styled.div`
  margin-right: 10px;
`;

const DABTableHeader: React.FC<DABTableHeaderProps> = ({
  tableView,
  damageMapsData,
  handleEditSchematic,
  setCurrentMapId,
  handleNewDamageReport,
  setSearchValue,
  reset,
}) => {
  const [locationOptions, setLocationOptions] = useState(null);
  const [isMapSelected, setIsMapSelected] = useState(false);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const openDamageMapDrawer = ({ id = null, mode = DrawerMode.ADD }: { id?: string; mode: DrawerMode }) :void => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({
      payload: {
        content: <DamageMapDrawer damageMapId={id} damageMaps={damageMapsData} />
      }
    }));
    dispatch(changeDrawerMode({ payload: mode }));
  };

  const modalSaveAction = () => {
    openDamageMapDrawer({ mode: DrawerMode.ADD });
    dispatch(changeModalVisibility({ payload: false }));
  };

  const handleAddNewItemClick = () => {
    if (tableView) {
      if (damageMapsData?.length) {
        handleNewDamageReport(true);
        setIsMapSelected(true);
      } else {
        dispatch(changeModalVisibility({ payload: true }));
        dispatch(changeModalContent({
          payload: {
            title: formatMessage({ id: 'text.noSchematicsUploaded' }),
            text: formatMessage({ id: 'text.thisAircraftHasNoSchematics' }),
            saveButtonText: formatMessage({ id: 'text.upload' }),
            saveAction: modalSaveAction,
          }
        }));
      }
    } else if (damageMapsData?.length) {
      handleNewDamageReport();
    } else {
      openDamageMapDrawer({ mode: DrawerMode.ADD });
    }
  };

  const handleAddNewSchematicClick = () => {
    openDamageMapDrawer({ id: null, mode: DrawerMode.ADD });
  };

  const buildDropdowns = (data) => {
    if (!data) return null;
    return Object.entries(data).map(([key, value]: [string, { title: string; value: string }[]]) => {
      const options = value;
      const placeholder = `${stringToTitleCase(key)} ${value?.length ? `(${value.length})` : ''}`;
      return (
        <TFDropdown
          key={key}
          id={`DABTableHeader--LocationDropdown-${key}`}
          placeholder={placeholder}
          options={options}
          initialValue={{ title: placeholder, value: null }}
          onSelect={(val) => {
            setCurrentMapId(val.value);
            setIsMapSelected(true);
          }}
        />
      );
    });
  };

  useEffect(() => {
    const sortDamageMapsIntoLocationOptions = (data) => {
      const initialLocations = DAMAGE_MAP_LOCATION_OPTIONS.reduce((acc, { value }) => {
        acc[value] = [];
        return acc;
      }, {});

      const newData = data.reduce((acc, map) => {
        const location = map.category;
        if (location) {
          acc[location].push({ title: map.title, value: map.id });
        }
        return acc;
      }, initialLocations);

      setLocationOptions(newData);
    };

    if (damageMapsData) {
      sortDamageMapsIntoLocationOptions(damageMapsData);
    }
  }, [damageMapsData]);

  return (
    <HeaderWrapper>
      <ButtonWrapper>
        <Search
          onChange={(e): void => setSearchValue(e.target.value)}
          onClear={() => setSearchValue('')}
          reset={reset}
        />
        <InnerButtonWrapper data-testid="DABTableHeader--InnerButtonWrapper">
          <Button height="30px" onClick={handleAddNewItemClick} disabled={!tableView && !isMapSelected}>
            <span><img src={plusIcon} alt="plus" /> {formatMessage({ id: 'text.newItem' })}</span>
          </Button>
          {!tableView ? (
            <EditUploadWrapper data-testid="DABTableHeader--EditUploadButtons">
              <EditButtonWrapper data-testid="DABTableHeader--EditButtonWrapper">
                <Button height="30px" onClick={handleEditSchematic}>
                  <span><img src={plusIcon} alt="plus" /> {formatMessage({ id: 'text.edit' })}</span>
                </Button>
              </EditButtonWrapper>
              <Button height="30px" onClick={handleAddNewSchematicClick}>
                <span><img src={plusIcon} alt="plus" /> {formatMessage({ id: 'text.uploadANewSchematic' })}</span>
              </Button>
            </EditUploadWrapper>
          ) : null}
        </InnerButtonWrapper>
      </ButtonWrapper>
      <Divider />
      {tableView ? null : (
        <DropdownWrapper data-testid="DABTableHeader--DropdownWrapper">
          {buildDropdowns(locationOptions)}
        </DropdownWrapper>
        )}
    </HeaderWrapper>
  );
};

export default DABTableHeader;

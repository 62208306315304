import { UseMutationResult } from "@tanstack/react-query";
import { addDamageReport } from "../../apiNew";
import { DamageReport } from "../../../models/damageReports";
import useRefreshMutation from "../useRefreshMutation";

interface AddDamageReportPayload {
  damageMapId: string;
  damageReport: DamageReport;
}

interface UseMutationAddDamageReportProps {
  handleAddDamageReportOnSuccess: (response: { data: DamageReport }) => void;
}

const useMutationAddDamageReport = ({
  handleAddDamageReportOnSuccess
}: UseMutationAddDamageReportProps): UseMutationResult<unknown, Error, AddDamageReportPayload, unknown> => {
  return useRefreshMutation<AddDamageReportPayload>({
    mutationFn: (payload: AddDamageReportPayload) => addDamageReport(payload.damageMapId, payload.damageReport),
    invalidationQueryKeys: [['damageReports'], ['damage_reports_totals']],
    successMessage: {
      title: 'Damage report added',
      message: 'Damage report was added successfully'
    },
    errorMessage: {
      title: 'Damage report add failed'
    },
    onSuccessCallback: handleAddDamageReportOnSuccess
  });
};

export default useMutationAddDamageReport;

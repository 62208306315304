/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import Loading from '../TFLoading/index';
import {
  putWorkpack,
  postWorkpack,
  completeWorkpack,
  deleteAttachment,
  getPublicShareURL,
} from '../../services/api';
import { Aircraft, AircraftPermission, AircraftResource } from '../../models/aircraft/index';
import { Workpack, WorkpackItem } from '../../models/workpacks/index';
import { DashboardState } from '../../models';
import { getSingleWorkpack, updateReduxWorkpack } from '../../models/workpacks/actions';
import { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import Controls from './Components/Controls';
import NotesAndAttachments from './Components/NotesAndAttachments';
import AircraftTotals from './Components/AircraftTotals';
import WorkpackInfo from './Components/WorkpackInfo';
import AddItemsToWorkpack from './Components/AddItemsToWorkpack';
import ViewWorkpacks from './Components/ViewWorkpacks';
import WorkpackTitle from './Components/WorkpackTitle';
import ResolutionDetails from './Components/ResolutionDetails';
import CRSAcceptance from './Components/CRSAcceptance';
import styles from './workpackDrawer.module.less';
import useGetSingleWorkpack from './useGetSingleWorkpack';

interface ViewWorkpackProps {
  wp: Workpack;
  pendingWps: Workpack[];
  ac: Aircraft[];
  initialMode: string;
  wpId: string;
  handleDeleteWorkpack: () => void;
  toggleDrawer: () => void;
  updateWorkpackid: (id: string) => void;
  setModalVisible: (visible: boolean) => void;
  handleSetCurrentWorkpack: (workpack: Workpack) => void;
  handleUnsavedData: (hasUnsavedData: boolean) => void;
  handleCRSClicked: () => void;
  crsClicked: boolean;
}

// eslint-disable-next-line no-shadow
enum DrawerState {
  EDIT = 'edit',
  VIEW = 'view',
  NEW = 'new',
}

// eslint-disable-next-line no-shadow
enum ItemStatus {
  COMPLETE = 'complete',
  PENDING = 'pending',
  DRAFT = 'draft',
  ACTIVE = 'active',
}

const ViewWorkpack:React.FC<ViewWorkpackProps> = ({
  wp,
  pendingWps,
  ac,
  initialMode,
  wpId,
  handleDeleteWorkpack,
  toggleDrawer,
  updateWorkpackid,
  setModalVisible,
  handleSetCurrentWorkpack,
  handleUnsavedData,
  handleCRSClicked,
  crsClicked,
}) => {
  const [localWorkpackState, updateLocalWorkpackState] = useState<Workpack>();
  const [initialPayload, setInitialPayload] =
    useState<Partial<Workpack>>({ date: moment().format('YYYY-MM-DD'), status: 'draft' });
  const [stagedPayload, updateStagedPayload] = useState(initialPayload);
  const [drawerMode, updateDrawerMode] = useState(initialMode);
  const [acceptanceSig, setAcceptanceSig] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [releaseData, setReleaseData] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    workpack_items: wpItems,
    status,
    date,
    release,
    po_number,
    description,
    aircraft_id,
    attachments,
    notes,
  } = localWorkpackState || {};

  const Dispatch = useDispatch();

  const {
    aircraft: { aircraftMap },
    workpacks: { workpacksMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id: aircraftId } = useParams<{ id: string }>();

  const operatorSettings = operators.find((op) => op.id === aircraftMap.get(aircraftId)?.operator_id)?.operator_setting;

  const customiser = (objValue, otherValue, key): boolean => {
    if (key === 'time_remaining') return true;
    return undefined;
  };
  const editStagedPayload = (newPayload): void => {
    updateStagedPayload((currentPayload) => ({ ...currentPayload, ...newPayload }));
  };

  const changeMode = (newMode: string): void => {
    updateDrawerMode(newMode);
  };

  const { data: workpackData, isLoading } = useGetSingleWorkpack({
    id: wpId,
  });

  useEffect(() => {
    if (drawerMode === 'new') {
      updateLocalWorkpackState(null);
      setInitialPayload({ date: moment().format('YYYY-MM-DD'), status: 'draft' });
    }
  }, []);

  useEffect(() => {
    if (drawerMode !== DrawerState.NEW) {
      updateLocalWorkpackState(wp);
    }

    if (wp) {
      setInitialPayload(wp);
      updateStagedPayload(wp);
      setLoading(false);
    }
  }, [wp?.id, wp?.status, wp?.date, wp?.description, wp?.po_number, wp?.workpack_items]);

  useEffect(() => {
    if (workpackData) {
      updateLocalWorkpackState(workpackData);
      updateStagedPayload(workpackData);
      setInitialPayload(workpackData);
    }
  }, [workpackData]);

  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (localWorkpackState && initialPayload && !_.isEqualWith(localWorkpackState, initialPayload, customiser)) {
      setUnsavedChanges(true);
      handleUnsavedData(true);
    } else {
      setUnsavedChanges(false);
      handleUnsavedData(false);
    }
  }, [localWorkpackState, initialPayload]);

  useEffect(() => {
    window.addEventListener('focus', () => {
      if (crsClicked) Dispatch(getSingleWorkpack({ payload: { id: wpId } }));
    });
  }, [crsClicked]);

  useEffect(() => {
    if (localWorkpackState) {
      const releaseInfo = workpackData?.release;
      if (releaseInfo) setReleaseData(releaseInfo);
    }
  }, [localWorkpackState]);

  const removeWPItem = (itemId: string, mxItemId: string): void => {
    const localItemHasNotBeenSaved = (): boolean => itemId === null;
    let updatedLoclWorkpack;
    let updatedStagedPayload;

    if (localItemHasNotBeenSaved()) {
      updatedStagedPayload = wpItems.filter((item) => item.mx_item_id !== mxItemId);
      updatedLoclWorkpack = { ...localWorkpackState, workpack_items: updatedStagedPayload };
    } else {
      const updatedLoclWpItems = wpItems.filter((item) => item.id !== itemId);
      updatedLoclWorkpack = { ...localWorkpackState, workpack_items: updatedLoclWpItems };
      updatedStagedPayload = stagedPayload.workpack_items.concat({ id: itemId, _destroy: true } as WorkpackItem);
    }
    editStagedPayload({ workpack_items: updatedStagedPayload });
    updateLocalWorkpackState(updatedLoclWorkpack);
  };

  const addWpItem = (mx_item, mxType): void => {
    let itemIsDuplicate;
    if (drawerMode !== 'new' && wpItems) itemIsDuplicate = wpItems.find((item) => mx_item.id === item.id);
    if (!itemIsDuplicate) {
      mx_item.type = mxType;
      const localItem = { mx_item, id: null, mx_item_id: mx_item.id };
      let addItemToLocalWorkpackState = [];
      if (drawerMode === 'new') {
        if (!wpItems) {
          addItemToLocalWorkpackState = [localItem];
        } else {
          addItemToLocalWorkpackState = ((wpItems as WorkpackItem[]) || []).concat(localItem as WorkpackItem);
        }
      } else {
        addItemToLocalWorkpackState = ((wpItems as WorkpackItem[]) || []).concat(localItem as WorkpackItem);
      }

      updateLocalWorkpackState((currentWorkpackState) => ({
        ...currentWorkpackState,
        workpack_items: addItemToLocalWorkpackState,
      }));
      if (drawerMode === 'new') {
        const addWPIdToPayload =
          stagedPayload?.workpack_items && stagedPayload?.workpack_items?.length > 0
            ? stagedPayload.workpack_items.concat({ mx_item_id: mx_item.id } as WorkpackItem)
            : [{ mx_item_id: mx_item.id } as WorkpackItem];
        editStagedPayload({ workpack_items: addWPIdToPayload });
      } else {
        const addWPItemToPayload =
          stagedPayload?.workpack_items && stagedPayload?.workpack_items?.length > 0
            ? stagedPayload.workpack_items.concat({ mx_item_id: mx_item.id } as WorkpackItem)
            : [{ mx_item_id: mx_item.id } as WorkpackItem];
        editStagedPayload({ workpack_items: addWPItemToPayload });
      }
    }
  };

  const resetPayload = (payload = null): void => {
    editStagedPayload(payload || initialPayload);
  };

  const resetDrawer = (): void => {
    changeMode('view');
    resetPayload();
    setInitialPayload(workpacksMap.get(wpId));
    updateLocalWorkpackState(workpacksMap.get(wpId));
    updateStagedPayload(workpacksMap.get(wpId));
  };

  const handleUnsavedItems = (): void => {
    if (!_.isEqualWith(stagedPayload, initialPayload, customiser)) {
      setModalVisible(true);
    } else {
      resetDrawer();
      if (drawerMode === 'new') {
        toggleDrawer();
      }
    }
  };

  const saveWorkpack = async (): Promise<void> => {
    await setLoading(true);
    try {
      if (drawerMode === 'new') {
        delete stagedPayload.id;
        stagedPayload.aircraft_id = ac[0].id;
        const response = await postWorkpack(stagedPayload);
        if (response && response.statusCode > 199 && response.statusCode < 400) {
          updateDrawerMode(DrawerState.VIEW);
          handleSetCurrentWorkpack(response.body);
          updateWorkpackid(response.body.id);
          updateLocalWorkpackState(response.body);
          setInitialPayload(response.body);
          resetPayload(response.body);
        } else {
          console.error(response?.statusCode);
        }
      } else {
        const response = await putWorkpack({ body: stagedPayload, id: wpId });
        if (response && response.statusCode > 199 && response.statusCode < 400) {
          Dispatch(updateReduxWorkpack({ payload: response.body }));
          updateDrawerMode(DrawerState.VIEW);
          handleSetCurrentWorkpack(response.body);
          setInitialPayload(response.body);
          updateStagedPayload(response.body);
          updateLocalWorkpackState(response.body);
          resetPayload(response.body);
        } else {
          console.error(response?.statusCode);
        }
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const haveReleaseInfo = wpItems && wpItems.some((item) => item?.mx_item?.mx_events?.length !== 0);

  const completePendingWorkpack = async (): Promise<void> => {
    if (acceptanceSig.length !== 0 && haveReleaseInfo) {
      try {
        const { id } = localWorkpackState?.release;
        const response = await completeWorkpack({ id, body: { approval_signature_data: acceptanceSig } });

        if (response && response.statusCode > 199 && response.statusCode < 400) {
          Dispatch(getSingleWorkpack({ payload: { id: wpId } }));
          updateDrawerMode(DrawerState.VIEW);
          handleSetCurrentWorkpack(response.body);
          setInitialPayload(response.body);
          updateStagedPayload(response.body);
          resetPayload(response.body);
        } else {
          console.error(response.statusCode);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handlePendingIndexChange = (index: number): void => {
    const activePendingWp = pendingWps[index];
    updateLocalWorkpackState(activePendingWp);
    setAcceptanceSig('');
  };

  const handleDeleteAttachement = async ({ fileId: id }): Promise<void> => {
    if (id) {
      try {
        await deleteAttachment(id);
        Dispatch(getSingleWorkpack({ payload: { id: wpId } }));
      } catch (e) {
        console.error(e);
      }
    }
  };

  let buttonText = `Add ${operatorSettings?.crs_short_name}`;
  if (status === ItemStatus.COMPLETE || status === ItemStatus.PENDING) {
    buttonText = `View ${operatorSettings?.crs_short_name}`;
  }
  if (operatorSettings?.approval_release_mode === 'after_any_work_completed_release') {
    buttonText = 'Submit Resolution';
    if (status === ItemStatus.COMPLETE || status === ItemStatus.PENDING)
      buttonText = `Add ${operatorSettings?.crs_short_name}`;
  }

  const resolutionInfo = wpItems && wpItems.find((item) => item?.mx_item?.mx_events?.length !== 0);
  const numberOfDraftItems = (wpItems && wpItems.filter((item) => item?.mx_item?.status === 'draft')?.length) || 0;
  const hasAnyDraftItems = numberOfDraftItems > 0;

  const CRSButton = ({ workpackId, onCRSClick }) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const [url, setUrl] = useState('');

    const fetchUrl = async () => {
      try {
        const response = await getPublicShareURL({ type: 'workpacks', id: workpackId });
        setUrl(response);
        onCRSClick();
        window.open(response, '_blank');
      } catch (err) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'text.failedToCreatePublicUrl' }),
              type: ToastTypes.ERROR,
              message: err.message || formatMessage({ id: 'text.failedToCreatePublicUrl' }),
              category: ToastCategories.FLAG,
            },
          }),
        );
      }
    };

    return (
      <div className={styles.CRSButtonWrapper}>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <button
            type="button"
            className={styles.addCRSButton}
            onClick={(e) => {
              if (!url) {
                e.preventDefault();
                fetchUrl();
              }
            }}
          >
            {buttonText}
          </button>
        </a>
      </div>
    );
  };

  return (
    <>
      <div id="workpackDrawerWrapper" className={styles.workpackDrawerWrapper}>
        <Loading contain loading={loading && !!wpId} />
        <WorkpackTitle
          mode={drawerMode}
          description={description}
          changeMode={changeMode}
          date={date}
          po={po_number}
          editStagedPayload={editStagedPayload}
          status={status}
          handleDeleteWorkpack={handleDeleteWorkpack}
          wpId={wpId}
          unsavedChanges={unsavedChanges}
          handleUnsavedItems={handleUnsavedItems}
          aircraftId={aircraft_id}
          loading={loading}
          numberOfDraftItems={numberOfDraftItems}
          hasAnyDraftItems={hasAnyDraftItems}
        />
        {drawerMode === DrawerState.VIEW && (
          <WorkpackInfo dateCreated={date} status={status} dateCompleted={release?.date} wpId={wpId} />
        )}
        {false && (
          <NotesAndAttachments
            notes={notes}
            attachments={attachments}
            handleDeleteClick={handleDeleteAttachement}
            editStagedPayload={editStagedPayload}
            id={wpId}
            mode={drawerMode}
          />
        )}
        {ac[0] && ac[0].totals && (
          <AircraftTotals totals={ac[0].totals} showApu={ac[0].apu_installed} operatorSettings={operatorSettings} />
        )}
        {wpItems && wpItems.length > 0 && (
          <ViewWorkpacks
            wpItems={localWorkpackState?.workpack_items || []}
            mode={drawerMode}
            removeWPItem={removeWPItem}
            apuInstalled={ac[0]?.apu_installed}
            wpId={wpId}
          />
        )}
        {drawerMode === DrawerState.VIEW && status !== ItemStatus.DRAFT && wpItems?.length ? (
          <AircraftAuthenticationWrapper
            aircraftId={aircraft_id}
            requiredResource={AircraftResource.WORKPACK}
            requiredPermissionLevel={AircraftPermission.UPDATE}
          >
            <CRSButton workpackId={wpId} onCRSClick={handleCRSClicked} />
          </AircraftAuthenticationWrapper>
        ) : null}
        {(drawerMode === DrawerState.EDIT || drawerMode === DrawerState.NEW) && (
          <AddItemsToWorkpack addWpItem={addWpItem} wpItems={wpItems} />
        )}
        {(status === ItemStatus.COMPLETE || status === ItemStatus.PENDING) &&
          resolutionInfo &&
          drawerMode === DrawerState.VIEW &&
          releaseData && <ResolutionDetails release={releaseData} poNum={po_number} fromWorkpack />}
        {status === ItemStatus.PENDING && (
          <CRSAcceptance setAcceptanceSig={setAcceptanceSig} operatorSettings={operatorSettings} />
        )}
        <Controls
          saveWorkpack={saveWorkpack}
          drawerMode={drawerMode}
          status={status}
          pendingWps={pendingWps}
          activeWPId={wpId}
          handlePendingIndexChange={handlePendingIndexChange}
          removeUnsavedItems={handleUnsavedItems}
          acceptanceSig={acceptanceSig}
          completePendingWorkpack={completePendingWorkpack}
        />
      </div>
    </>
  );
};

export default ViewWorkpack;

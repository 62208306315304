import { useMutation, useQueryClient, UseMutationResult } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { updateDefectUseQuery } from "../../api";
import { addToast, ToastCategories, ToastTypes } from "../../../models/toasts";
import { Defect } from "../../../models/defects";

interface UseMutationUpdateDefectProps {
  handleUpdateDefectOnSuccess: (data: Defect) => void;
}

const useMutationUpdateDefect = ({
  handleUpdateDefectOnSuccess,
}: UseMutationUpdateDefectProps): UseMutationResult<unknown, Error, unknown, unknown> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const updateDefect = useMutation({
    mutationFn: updateDefectUseQuery,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['defectDrawer'] });
      handleUpdateDefectOnSuccess(data);
      dispatch(
        addToast({
          payload: {
            title: 'Defect updated',
            message: 'Defect has been updated successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: (error: Error) => {
      dispatch(
        addToast({
          payload: {
            title: 'Defect update failure',
            message: error.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });

  return updateDefect;
}

export default useMutationUpdateDefect;

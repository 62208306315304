import { Button, Loading, Modal } from '@arcflight/tf-component-library';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { FeatureFlag } from '../../../models/userSettings';
import TFFilerViewer from '../../TFFileViewer/fileViewer';
import errorIcon from '../../../assets/login-error.svg';
import pencilIcon from '../../../assets/icon-edit.svg';
import styles from '../workpackDrawer.module.less';
import { DashboardState } from '../../../models';
import { AircraftAuthenticationWrapper } from '../../_utils/AuthenticationWrapper';
import { Release } from '../../../models/workpacks';
import { AircraftPermission, AircraftResource } from '../../../models/aircraft';
import useGetOperatorSetting from '../../../utils/useGetOperatorSetting';
import useGetAircraftStandardFieldProperty from '../../../services/hooks/aircraft/useGetAircraftStandardFieldProperty';
import EditResolutionDetails from './EditResolutionDetails';
import PartChangesSection from './PartChangesSection';

interface ResolutionDetailsProps {
  release: Release;
  poNum: string;
  noCard?: boolean;
  handleResolutionSave?: () => void;
  fromWorkpack?: boolean;
}

const defaultProps = {
  noCard: false,
  handleResolutionSave: () => {},
  fromWorkpack: false,
};

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

const SubmitButtonWrapper = styled.div`
  margin-right: 16px;
`;

const ModalHeader = styled.div`
  color: #313745;
  font-weight: 500;
  font-size: 18px;
`;

const Header = styled.span`
  color: ${({ edit }): string => (edit ? '#242d41' : 'rgba(36, 45, 65, 0.4)')};
  font-size: 12px;
  text-transform: ${({ edit }): string => (edit ? 'capitalize' : 'uppercase')};
  font-weight: ${({ edit }): string => (edit ? '500' : '400')};
  ${({ noCard }): FlattenSimpleInterpolation => {
    if (noCard) {
      return css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 160px;
      `;
    }
    return null;
  }}
  @media (max-width: 787px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 160px;
  }
`;

const ModalText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  margin-bottom: 16px;
`;

const StyledTextArea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
`;

const WarningText = styled.span`
  color: #f5222d;
  img {
    margin-right: 4px;
  }
`;

const StyledIcon = styled.img`
  margin-right: 4px;
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }): string => columns || '1fr 1fr 1fr'};
  gap: 20px;
  margin-bottom: 20px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ReleaseNotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 3;
  margin-bottom: 20px;
`;

const WorkpackRef = styled.span`
  margin-top: ${({ edit }): string => (edit ? '10px' : '0')};
`;

const PersonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-weight: 500;
  color: #242d41;
`;

const ResolutionDetails: React.FC<ResolutionDetailsProps> = ({
  release,
  poNum,
  noCard,
  handleResolutionSave,
  fromWorkpack,
}) => {
  const [justificationRequired, setJustificationRequired] = useState(false);
  const [edit, setEdit] = useState(false);
  const [warning, setWarning] = useState(false);

  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [licenseNumber, setLicenceNumber] = useState(null);
  const [organisation, setOrganisation] = useState('');
  const [approvalNumber, setApprovalNumber] = useState(null);
  const [localAttachments, setLocalAttachments] = useState([]);
  const [reporterFirstName, setReporterFirstName] = useState('');
  const [reporterLasttName, setReporterLasttName] = useState('');
  const [signatureData, setSignatureData] = useState('');
  const [signatureStatement, setSignatureStatement] = useState('');
  const [localReleaseDate, setLocalReleaseDate] = useState(null);
  const [localLimitations, setLocalLimitations] = useState(false);
  const [resolutionType, setResolutionType] = useState('');
  const [notes, setNotes] = useState('');
  const [signOffNotes, setSignOffNotes] = useState('');
  const [partChanges, setPartChanges] = useState(null);
  const [loading, setLoading] = useState(false);
  const [justification, setJustification] = useState('');

  const {
    aircraft: { aircraftMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const { id: aircraftId } = useParams<{ id: string }>();

  const { formatMessage } = useIntl();

  const operatorId = aircraftMap.get(aircraftId)?.operator_id;
  const operatorSettings = userSettings?.details?.operators.find((op) => op.id === operatorId)?.operator_setting;

  const hidePart145Fields =
    useGetAircraftStandardFieldProperty<boolean>('show_part_145_fields', aircraftId, 'enabled') === false;
  const descriptionEnabled =
    useGetAircraftStandardFieldProperty<boolean>('part_info_description', aircraftId, 'enabled');
  const positionEnabled = useGetAircraftStandardFieldProperty<boolean>('part_info_position', aircraftId, 'enabled');
  const batchOnEnabled =
    useGetAircraftStandardFieldProperty<boolean>('part_info_batch', aircraftId, 'enabled') !== false;
  const batchOffEnabled = useGetAircraftStandardFieldProperty<boolean>('part_info_batch_off', aircraftId, 'enabled');
  const descriptionRename =
    useGetAircraftStandardFieldProperty<string>('part_info_description', aircraftId, 'name_override');
  const positionRename = useGetAircraftStandardFieldProperty<string>('part_info_position', aircraftId, 'name_override');
  const batchOnRename = useGetAircraftStandardFieldProperty<string>('part_info_batch', aircraftId, 'name_override');
  const batchOffRename =
    useGetAircraftStandardFieldProperty<string>('part_info_batch_off', aircraftId, 'name_override');

  const notesRename = useGetOperatorSetting('notes_name', aircraftId);
  const releaseNotesRename = useGetOperatorSetting('release_notes_name', aircraftId);

  const notesOnSignOffFeatureFlagEnabled = aircraftMap
    .get(aircraftId)
    ?.feature_flags?.some((flag) => flag.feature_name === FeatureFlag.NOTESONSIGNOFF);

  const handleJustification = (e): void => {
    setJustification(e.target.value);
    setWarning(false);
  };

  useEffect(() => {
    if (release) {
      let combinedAttachments = [];
      setLocalReleaseDate(release?.date ? moment.utc(release?.date) : moment.utc(release?.date));
      setFirstName(release?.first_name);
      setLastName(release?.last_name);
      setName(`${release?.first_name} ${release?.last_name}`);
      setLicenceNumber(release?.licence_number);
      setOrganisation(release?.company_name);
      setReporterFirstName(release?.reporter_first_name);
      setReporterLasttName(release?.reporter_last_name);
      setSignOffNotes(release?.release_notes);
      combinedAttachments = [...combinedAttachments, ...(release?.attachments || [])];
      setApprovalNumber(release?.approval_number);
      setSignatureData(release?.signature_data);
      setSignatureStatement(release?.signature_statement);
      setLocalAttachments(combinedAttachments);
      if (!fromWorkpack) {
        let displayWorkType = '-';
        if (release?.work_type === 'inspected') displayWorkType = 'Inspected';
        if (release?.work_type === 'replaced') displayWorkType = 'Part Replaced';
        if (release?.work_type === 'other') displayWorkType = 'Other';
        setLocalLimitations(!!release?.limitations);
        setNotes(release?.description);
        setResolutionType(displayWorkType);
        if (release?.part_changes?.length > 0) setPartChanges(release?.part_changes[0]);
      }
    }
  }, [fromWorkpack, release]);

  return (
    <div className={noCard ? styles.noCard : styles.workpackWhiteBoxPad}>
      <Modal isOpen={justificationRequired} handleClose={(): void => setJustificationRequired(false)} width={420}>
        <div>
          <ModalHeader>{formatMessage({ id: 'text.justificationRequired' })}</ModalHeader>
          <ModalText>{formatMessage({ id: 'text.toMakeAnyEditsYouMustProvideJustification' })}</ModalText>
        </div>
        <StyledTextArea type="text" onChange={handleJustification} cols={40} rows={5} />
        {warning ? (
          <div>
            <img src={errorIcon} alt="error icon" />
            <WarningText>{formatMessage({ id: 'text.justificationRequired' })}</WarningText>
          </div>
        ) : null}
        <ButtonWrapper>
          <SubmitButtonWrapper>
            <Button
              height="30px"
              onClick={(): void => {
                if (justification) {
                  setEdit(true);
                  setJustificationRequired(false);
                } else {
                  setWarning(true);
                }
              }}
            >
              {formatMessage({ id: 'text.save' })}
            </Button>
          </SubmitButtonWrapper>
          <Button height="30px" primary={false} onClick={(): void => setJustificationRequired(false)}>
            {formatMessage({ id: 'text.cancel' })}
          </Button>
        </ButtonWrapper>
      </Modal>
      {loading ? (
        <Loading loading={loading} contain />
      ) : (
        <div className={styles.columnNoMarg}>
          {edit ? (
            <EditResolutionDetails
              release={release}
              handleResolutionSave={handleResolutionSave}
              setLoading={setLoading}
              setEdit={setEdit}
              requiredSettings={{
                engineer: operatorSettings?.engineer,
                engineerLicenceNumber: operatorSettings?.engineer_licence_number,
                hidePart145Fields,
                fromWorkpack,
                descriptionEnabled,
                positionEnabled,
                batchOnEnabled,
                batchOffEnabled,
                descriptionRename,
                positionRename,
                batchOnRename,
                batchOffRename,
                notesRename,
              }}
            />
          ) : (
            <>
              <div className={styles.resolutionHeaderWrapper}>
                <div className={styles.minorHeadingPad} data-testid="ResolutionDetails--Title">
                  {formatMessage({ id: 'title.resolutionDetails' })}
                </div>
                <AircraftAuthenticationWrapper
                  aircraftId={aircraftId}
                  requiredResource={AircraftResource.DEFECT}
                  requiredPermissionLevel={AircraftPermission.UPDATE}
                >
                  <Button
                    primary={false}
                    onClick={(): void => setJustificationRequired(true)}
                    height="24px"
                    padding="0 12px"
                  >
                    <StyledIcon src={pencilIcon} alt="pencil" />
                    {formatMessage({ id: 'form.button.editDetails' })}
                  </Button>
                </AircraftAuthenticationWrapper>
              </div>
              <StyledGrid>
                <div className={styles.rowItem} data-testid="ResolutionDetails--DateCompleted">
                  <Header noCard edit={edit}>
                    {formatMessage({ id: 'text.dateCompleted' })}
                  </Header>
                  <span>
                    {localReleaseDate ? moment.utc(localReleaseDate).format(userSettings?.dateFormat) : '-'}
                  </span>
                </div>
                <div className={styles.rowItem} data-testid="ResolutionDetails--TimeCompleted">
                  <Header noCard edit={edit}>
                    {formatMessage({ id: 'text.timeCompleted' })}
                  </Header>
                  <span>{localReleaseDate ? moment.utc(localReleaseDate).format('HH:mm') : '-'}</span>
                </div>
                <div className={styles.rowItem} data-testid="ResolutionDetails--Engineer">
                  <Header noCard edit={edit}>
                    {operatorSettings?.engineer}
                  </Header>
                  <span>{name}</span>
                </div>
                <div className={styles.rowItem} data-testid="ResolutionDetails--EngineerLicenceNumber">
                  <Header noCard edit={edit}>
                    {operatorSettings?.engineer_licence_number}
                  </Header>
                  <span>{licenseNumber || '-'}</span>
                </div>
                {hidePart145Fields ? null : (
                  <>
                    <div className={styles.rowItem} data-testid="ResolutionDetails--Part145Organisation">
                      <Header noCard edit={edit}>
                        {`${operatorSettings?.part_145_name} Organisation`}
                      </Header>
                      <span>{organisation || '-'}</span>
                    </div>
                    <div className={styles.rowItem} data-testid="ResolutionDetails--Part145ApprovalNumber">
                      <Header noCard edit={edit}>
                        {operatorSettings?.part_145_approval_number}
                      </Header>
                      <span>{approvalNumber || '-'}</span>
                    </div>
                  </>
                )}
                <div className={styles.rowItem} data-testid="ResolutionDetails--WorkpackReference">
                  <Header noCard edit={edit}>
                    {formatMessage({ id: 'text.workpackReference' })}
                  </Header>
                  <WorkpackRef edit={edit}>{poNum || '-'}</WorkpackRef>
                </div>
              </StyledGrid>
              {!fromWorkpack ? (
                <SectionWrapper data-testid="ResolutionDetails--Notes">
                  <Header noCard edit={edit}>
                    {notesRename}
                  </Header>
                  <span>{notes || '-'}</span>
                </SectionWrapper>
              ) : null}
              {!fromWorkpack ? (
                <SectionWrapper data-testid="ResolutionDetails--ResolutionType">
                  <Header noCard edit={edit}>
                    {formatMessage({ id: 'title.resolutionType' })}
                  </Header>
                  <span>{resolutionType}</span>
                </SectionWrapper>
              ) : null}
              {!fromWorkpack && resolutionType === 'Part Replaced' ? (
                <StyledGrid data-testid="ResolutionDetails--PartChanges">
                  <PartChangesSection
                    partChanges={partChanges}
                    requiredSettings={{
                      positionEnabled,
                      batchOnEnabled,
                      batchOffEnabled,
                      positionRename,
                      batchOnRename,
                      batchOffRename,
                    }}
                    mode="view"
                    dataTestId='ResolutionDetails--PartSection'
                  />
                </StyledGrid>
              ) : null}
              {!fromWorkpack && resolutionType === 'Part Replaced' && descriptionEnabled ? (
                <SectionWrapper data-testid="ResolutionDetails--PartDescription">
                  <Header noCard edit={edit}>
                    {descriptionRename || formatMessage({ id: 'text.description' })}
                  </Header>
                  <span>{partChanges?.description || '-'}</span>
                </SectionWrapper>
              ) : null}
              {notesOnSignOffFeatureFlagEnabled && (
                  <ReleaseNotesWrapper data-testid="ResolutionDetails--ReleaseNotes">
                    <Header noCard edit={edit}>
                      {releaseNotesRename}
                    </Header>
                    <span>{signOffNotes || '-'}</span>
                  </ReleaseNotesWrapper>
                )}
              <div className={styles.rowGridTwo}>
                {!fromWorkpack ? (
                  <div className={styles.rowItem} data-testid="ResolutionDetails--Limitations">
                    <Header noCard edit={edit}>
                      {formatMessage({ id: 'text.limitations' })}
                    </Header>
                    <span>{localLimitations || '-'}</span>
                  </div>
                ) : null}
                <div className={styles.rowItem} data-testid="ResolutionDetails--Attachments">
                  <Header noCard edit={edit}>
                    {formatMessage({ id: 'text.attachments' })}
                  </Header>
                  <TFFilerViewer attachments={localAttachments} uploaderVisible={false} />
                </div>
              </div>
            </>
          )}
          <PersonRow data-testid="resolutionDetails--ResolvedBy">
            <span>
              {formatMessage({ id: 'text.resolvedBy' })}:{' '}
              <span className={styles.name}>
                {reporterFirstName || firstName || '-'} {reporterLasttName || lastName || '-'}
              </span>
            </span>
            {signatureData ? (
              <img alt="signature" src={`data:image/png;base64, ${signatureData}`} className={styles.signatureImage} />
            ) : null}
            <span>Resolved on: {moment.utc(release?.date).format(userSettings?.dateFormat)}</span>
          </PersonRow>
          {signatureStatement ? (
            <div className={styles.row}>
              <PersonRow>
                <span>{signatureStatement}.</span>
              </PersonRow>
            </div>
          ) : null}
          {release?.edited_by?.id && (
          <PersonRow data-testid="ResolutionDetails--EditedBy">
            <span>
              Edited by:{' '}
              <span className={styles.name}>
                {release?.edited_by?.first_name || '-'} {release?.edited_by?.last_name || '-'}
              </span>
            </span>
            <span>Edited on: {moment.utc(release?.edited_at).format(userSettings?.dateFormat)}</span>
          </PersonRow>
          )}
        </div>
      )}
    </div>
  );
};

ResolutionDetails.defaultProps = defaultProps;

export default ResolutionDetails;

import { useQuery } from "@tanstack/react-query";
import { getDamageReportTotals } from "../../apiNew";
import { DamageReportTotals } from "../../../models/damageReports";

const useQueryDamageReportsTotals = (aircraftId: string): { data: DamageReportTotals; isFetching: boolean } => {
  const { data, isFetching } = useQuery({
    queryKey: ['damage_reports_totals', aircraftId],
    queryFn: () => getDamageReportTotals(aircraftId),
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.data,
    isFetching,
  };
};

export default useQueryDamageReportsTotals;

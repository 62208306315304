import { useQuery } from "@tanstack/react-query";
import { getDamageMaps } from "../../api";
import { DamageMap, DAMAGE_MAP_LOCATION_OPTIONS } from "../../../models/damageMaps";

const CATEGORY_ORDER = DAMAGE_MAP_LOCATION_OPTIONS.map(option => option.value);

const useQueryGetDamageMaps = ({ id }: { id: string }): { data: DamageMap[]; isFetching: boolean } => {
  const { data, isFetching } = useQuery({
    queryKey: ['damageMaps', id],
    queryFn: () => getDamageMaps({ id }),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  // Sorting here to ensure that the damage maps are in the order expected by the category order above
  // So that the first selected map is for the right category and not a random one returned by core
  // At least until core can be amended to return them in the correct order
  const sortedData = data?.sort((a, b) => {
    const categoryComparison = CATEGORY_ORDER.indexOf(a.category) - CATEGORY_ORDER.indexOf(b.category);
    if (categoryComparison !== 0) return categoryComparison;
    return new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime();
  });

  return { data: sortedData, isFetching };
};

export default useQueryGetDamageMaps;

import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { removeDamageReport } from '../../api';
import { addToast, ToastCategories, ToastTypes } from '../../../models/toasts';
import { DamageReport } from '../../../models/damageReports';

interface UseMutationDeleteDamageReportProps {
  handleOnSuccessDeleteReport?: () => void;
}

const useMutationDeleteDamageReport =
  ({ handleOnSuccessDeleteReport }: UseMutationDeleteDamageReportProps = {}):
  UseMutationResult<DamageReport, Error, string, unknown> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const deleteReport = useMutation({
    mutationFn: (id: string) => removeDamageReport(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['damageReports'] });
      queryClient.invalidateQueries({ queryKey: ['damage_reports_totals'] });
      if (handleOnSuccessDeleteReport) handleOnSuccessDeleteReport();
      dispatch(
        addToast({
          payload: {
            title: 'Damage report deleted',
            message: 'Damage report has been deleted successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: (error: Error) => {
      dispatch(
        addToast({
          payload: {
            title: 'Damage report deletion failure',
            message: error.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });

  return deleteReport;
};

export default useMutationDeleteDamageReport;

import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { editDamageMap } from "../../api";
import { addToast, ToastCategories, ToastTypes } from "../../../models/toasts";

interface UseMutationEditDamageMapProps {
  handleOnSuccessEditDamageMap: () => void;
}

type Payload = {
  id: string;
  title: string;
  category: string;
}

const useMutationEditDamageMap = ({
  handleOnSuccessEditDamageMap
}: UseMutationEditDamageMapProps): UseMutationResult<unknown, Error, Payload, unknown> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const editedDamageMap = useMutation({
    mutationFn: (payload: Payload) => editDamageMap(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['damageMaps'] });
      queryClient.invalidateQueries({ queryKey: ['damageReports'] });
      queryClient.invalidateQueries({ queryKey: ['damage_reports_totals'] });
      handleOnSuccessEditDamageMap();
      dispatch(
        addToast({
          payload: {
            title: 'Damage map updated',
            message: 'Damage map has been updated successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: (error: Error) => {
      dispatch(
        addToast({
          payload: {
            title: 'Failed to update damage map',
            message: error.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });

  return editedDamageMap;
};

export default useMutationEditDamageMap;


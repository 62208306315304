import React from 'react';
import styled from 'styled-components';
import servers from '../../utils/servers';
import { DamageMap } from '../../models/damageMaps';
import { UploadedItem } from './ThumbnailSection';

interface DamageMapThumbnailProps {
  damageMap: UploadedItem | DamageMap;
  onClick?: (damageMap: DamageMap) => void;
}

// Type guard to check if the item is a DamageMap
const isDamageMap = (item: UploadedItem | DamageMap): item is DamageMap => {
  return 'id' in item;
};

const Wrapper = styled.div<{ clickable: boolean }>`
  max-width: 160px;
  height: auto;
  background: none;
  border: 1px solid #dbe3ed;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 15px 0 #dbe3ed;
  }
`;

const Title = styled.div`
  text-transform: capitalize;
  margin-bottom: 10px;
  color: #242d41;
`;

const Category = styled.div`
  margin-bottom: 10px;
  text-transform: capitalize;
`;

const Attachment = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const DamageMapThumbnail: React.FC<DamageMapThumbnailProps> = ({ damageMap, onClick }) => {
  if (!damageMap) return null;

  const { title, category, attachment } = damageMap;
  const isClickable = isDamageMap(damageMap) && onClick !== undefined;

  const handleClick = () => {
    if (isClickable) {
      onClick(damageMap);
    }
  };

  return (
    <Wrapper
      key={`${title}-${category}`}
      clickable={isClickable}
      onClick={handleClick}
      data-testid={`DamageMapThumbnail--${title}-${category}`}
    >
      <Category>{category}</Category>
      <Title>{title}</Title>
      <Attachment
        src={typeof attachment === 'string' ? attachment : `${servers.api}/${attachment.url}`}
        alt="attachment"
      />
    </Wrapper>
  )
};

DamageMapThumbnail.defaultProps = {
  onClick: undefined,
};

export default DamageMapThumbnail;

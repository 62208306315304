import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Menu, Dropdown, Icon, Button, Badge, Input } from 'antd';
import { Link } from 'react-router-dom';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import TFLoading from '../../components/TFLoading';
import planeIcon from '../../assets/plane-icon.svg';
import dropdownIcon from '../assets/icon-dropdown.svg';
import styles from './AircraftDropdown.module.less';

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #126fd6;
  border-color: #398fff;
`;

const LoadingContainer = styled.div`
  position: relative;
  width: 38px;
  height: 38px;
`;

const LoadingText = styled.div`
  margin-left: 50px;
  color: #fff;
  height: 100%;
  align-content: center;
`;

const AircraftDropdown = ({
  aircraftList,
  selectedAircraft,
  onSearchChange,
  searchValue,
  toggleDrawer,
  menuOnly,
  userSettings,
  aircraftLoading,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const searchInput = useRef();
  const approvalReleaseMode = useGetOperatorSetting('approval_release_mode', selectedAircraft.id);
  const approvalReleaseName = useGetOperatorSetting('approval_release_name', selectedAircraft.id);
  const crsShortName = useGetOperatorSetting('crs_short_name', selectedAircraft.id);

  const { formatMessage } = useIntl();

  let releaseText = crsShortName;
  if (['after_approval_release_required', 'after_each_approval_release_required'].includes(approvalReleaseMode)) {
    releaseText = approvalReleaseName;
  }

  const getAircraftStatus = (aircraft) => {
    let acStatus = 'default';
    let acStatusText = formatMessage({ id: 'status.unknown' });
    if (aircraft && aircraft.maintenance_status) {
      if (aircraft.maintenance_status === 'ok' || aircraft.maintenance_status === 'serviceable') {
        acStatus = 'success';
        acStatusText = formatMessage({ id: 'status.serviceable' });
      } else if (aircraft.maintenance_status === 'critical') {
        acStatus = 'warning';
        acStatusText = formatMessage({ id: 'status.inTolerance' });
      } else if (aircraft.maintenance_status === 'overdue') {
        acStatus = 'error';
        acStatusText = formatMessage({ id: 'status.overdue' });
      } else if (aircraft.maintenance_status === 'unserviceable') {
        acStatus = 'error';
        if (aircraft.maintenance_status_reason === 'missing_crs') {
          acStatusText = formatMessage({ id: 'status.unserviceable_missing_crs_short' }, { releaseText });
        } else {
          acStatusText = formatMessage({ id: 'status.unserviceable' });
        }
      }
    }
    return {
      status: acStatus,
      text: acStatusText,
    };
  };

  const handleVisibleChange = (flag) => {
    if (window.innerWidth > 576) {
      setDropdownVisible(flag);
      if (flag) {
        // Timeout is required to focus input once it was rendered in dropdown
        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 50);
      }
    } else {
      toggleDrawer();
    }
  };

  const handleMenuClick = (e) => {
    if (e.key !== 'search') {
      setDropdownVisible(false);
    }
  };

  const menu = (
    <Menu className={styles.aircraftMenu} onClick={handleMenuClick} data-testid="AircraftDropdown--Menu">
      <Menu.Item key="search" style={{ position: 'relative' }}>
        {aircraftLoading ? (
          <LoadingWrapper data-testid="AircraftDropdown--LoadingWrapper">
            <LoadingContainer>
              <TFLoading loading={aircraftLoading} contain width={22} height={22} theme="transparentWithWhite" />
              <LoadingText>Loading aircraft...</LoadingText>
            </LoadingContainer>
          </LoadingWrapper>
        ) : (
          <Input
            prefix={<Icon type="search" />}
            placeholder={formatMessage({ id: 'form.placeholder.searchAircraft' })}
            className={styles.inputSearchReg}
            value={searchValue}
            onChange={onSearchChange}
            ref={searchInput}
          />
        )}
      </Menu.Item>
      {aircraftList
        .filter((item) => item.id !== selectedAircraft.id)
        .sort((a, b) => (a?.fleet_number + a?.registration).localeCompare(b?.fleet_number + b?.registration))
        .map((ac) => {
          const cabinIssueEnabled = ac?.standard_fields?.cabin_log?.enabled || false;
          let newPath = ac.path;
          if (!cabinIssueEnabled && newPath?.includes('cabin_log')) {
            newPath = newPath.replace('/cabin_log', '/overview');
          }
          return (
            <Menu.Item key={ac.id}>
              {!userSettings?.details?.dashboard_permissions?.includes('admin') && ac?.locked ? (
                <div className={styles.lockedAircraftWrapper}>
                  {ac.fleet_number ? (
                    <span>
                      <span className={styles.aircraftFleetRegistration}>
                        {`${ac.fleet_number} (${ac.registration})`}
                      </span>
                    </span>
                  ) : (
                    <span className={styles.regType}>
                      <span className={styles.aircraftRegistration}>{ac.registration || '-'}</span>
                      <span className={styles.aircraftType}>
                        {ac.aircraft_type && ac.aircraft_type.designator ? ac.aircraft_type.designator : '-'}
                      </span>
                    </span>
                  )}
                  <span className={styles.aircraftStatus}>
                    <Badge status={getAircraftStatus(ac).status} />
                  </span>
                </div>
              ) : (
                <Link to={newPath} className={styles.aircraftLinkSingleWrapper}>
                  {ac.fleet_number ? (
                    <span>
                      <span className={styles.aircraftFleetRegistration}>
                        {`${ac.fleet_number} (${ac.registration})`}
                      </span>
                    </span>
                  ) : (
                    <span className={styles.regType}>
                      <span className={styles.aircraftRegistration}>{ac.registration || '-'}</span>
                      <span className={styles.aircraftType}>
                        {ac.aircraft_type && ac.aircraft_type.designator ? ac.aircraft_type.designator : '-'}
                      </span>
                    </span>
                  )}
                  <span className={styles.aircraftStatus}>
                    <Badge status={getAircraftStatus(ac).status} />{' '}
                    <span className={styles.aircraftStatusText}>{getAircraftStatus(ac).text}</span>
                  </span>
                </Link>
              )}
            </Menu.Item>
          );
        })}
    </Menu>
  );
  if (menuOnly) {
    return menu;
  }
  return (
    <div data-testid="AircraftDropdown--MenuWrapper">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={dropdownVisible}
        overlayStyle={{ position: 'fixed', maxHeight: '78vh', overflowY: 'auto' }}
      >
        <Button type="link" className={styles.activeAircraftWrapper}>
          <div className={styles.iconWrapper}>
            <img src={planeIcon} alt="planeIcon" />
          </div>
          <div className={styles.acWrapper}>
            <div className={styles.regWrapper}>
              <Badge status={getAircraftStatus(selectedAircraft).status} />
              {selectedAircraft.registration || '-'}
            </div>
            <div className={styles.statusWrapper}>{getAircraftStatus(selectedAircraft).text}</div>
          </div>
          <div className={dropdownVisible ? styles.toggleWrapperDropdownVisible : styles.toggleWrapper}>
            <img src={dropdownIcon} alt="dropdownIcon" />
          </div>
        </Button>
      </Dropdown>
    </div>
  );
};

AircraftDropdown.propTypes = {
  aircraftList: PropTypes.array.isRequired,
  selectedAircraft: PropTypes.object,
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  toggleDrawer: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  menuOnly: PropTypes.bool,
  userSettings: PropTypes.object.isRequired,
  aircraftLoading: PropTypes.bool,
};

AircraftDropdown.defaultProps = {
  selectedAircraft: {},
  searchValue: null,
  toggleDrawer: false,
  menuOnly: false,
  aircraftLoading: false,
};

export default AircraftDropdown;

import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { addDamageMap } from "../../api";
import { addToast, ToastCategories, ToastTypes } from "../../../models/toasts";

interface useMutationAddDamageMapProps {
  handleOnSuccessAddSchematic: () => void;
  handleOnErrorAddSchematic: () => void;
}

interface Payload {
  form: FormData;
  id?: string;
}

const useMutationAddDamageMap =
  ({ handleOnSuccessAddSchematic, handleOnErrorAddSchematic }: useMutationAddDamageMapProps):
  UseMutationResult<unknown, Error, Payload, unknown> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const newDamageMap = useMutation({
    mutationFn: (payload: Payload) => addDamageMap(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['damageMaps'] });
      handleOnSuccessAddSchematic();
      dispatch(
        addToast({
          payload: {
            title: 'Damage map added',
            message: 'Damage map was added successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: (error: Error) => {
      handleOnErrorAddSchematic();
      dispatch(
        addToast({
          payload: {
            title: 'Damage map add failure',
            message: error.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });

  return newDamageMap;
};

export default useMutationAddDamageMap;

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import { AircraftResource } from '../../models/aircraft';
import {
  changeDrawerMode,
  changeDrawerVisibility,
  changeModalContent,
  changeModalVisibility,
  setDrawerId,
} from '../../models/drawer';
import ButtonSection from '../../components/TripDrawer/ButtonSection';
import { AircraftLocationIssues, CabinIssue } from '../../models/cabinIssues';
import CabinIssueDetails from './CabinIssueDetails';
import useMutationAddCabinIssue from './QueryCalls/useMutationAddCabinIssue';
import useMutationDeleteCabinIssue from './QueryCalls/useMutationDeleteCabinIssue';
import useMutationUpdateCabinIssue from './QueryCalls/useMutationUpdateCabinIssue';

interface CabinIssueDrawerProps {
  issue: CabinIssue;
}


export interface CabinIssuePayload extends CabinIssue {
  aircraft_id?: string;
}

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }): string => theme.colors.black};
  margin-bottom: ${({ bannerVisible }): string => (bannerVisible ? '16px' : '24px')};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  img {
    margin: 0 4px;
  }
`;

const TitleText = styled.div`
  width: 110px;
`;

const CabinIssueDrawer: React.FC<CabinIssueDrawerProps> = ({ issue }) => {
  const {
    drawer: { mode, drawerId },
    cabinIssues: { cabinIssues, selectedIssueLocation, cabinIssueSVG },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [cabinIssue, setCabinIssue] =
    useState({} as CabinIssue);
  const [originalCabinIssue, setOriginalCabinIssue] = useState(null);

  const handleAddCabinIssueOnSuccess = (data: { id: string }) => {
    dispatch(setDrawerId({ payload: data.id }));
  };

  const addCabinIssue = useMutationAddCabinIssue({ handleAddCabinIssueOnSuccess });
  const deleteCabinIssue = useMutationDeleteCabinIssue();
  const updateIssue = useMutationUpdateCabinIssue();

  const updateCabinIssue = (changes: { value: string; key: string }[]): void => {
    const newData = cabinIssue ? JSON.parse(JSON.stringify(cabinIssue)) : {};
    changes.forEach(({ value, key }) => {
      newData[key] = value;
    });
    setCabinIssue(newData);
  };

  const handleEditClick = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleDeleteCabinIssue = (): void => {
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title: formatMessage({ id: 'text.deleteCabinIssue' }),
          text: `${formatMessage({
            id: 'form.question.areYouSureDeleteCabinIssue',
          })} ${formatMessage({
            id: 'form.labels.cannotBeUndone',
          })}`,
          saveButtonText: formatMessage({ id: 'text.delete' }),
          saveAction: () => {
            deleteCabinIssue.mutate({ id: cabinIssue?.id, aircraft_id: id });
            dispatch(changeDrawerVisibility({ payload: false }));
            dispatch(changeModalVisibility({ payload: false }));
          },
        },
      }),
    );
  };

  const handleCancelClick = (): void => {
    if (mode === 'add') {
      dispatch(changeDrawerVisibility({ payload: false }));
    } else {
      dispatch(changeDrawerMode({ payload: 'view' }));
      setCabinIssue(originalCabinIssue);
    }
  };

  const dispatchErrorToast = (title: string): void => {
    dispatch(
      addToast({
        payload: { title, message: '', type: ToastTypes.ERROR, category: ToastCategories.FLAG },
      }),
    );
  };

  const validateCabinIssue = (): boolean => {
    if (
      cabinIssue?.aircraft_location_issues_attributes &&
      !cabinIssue?.aircraft_location_issues_attributes?.[0]?.aircraft_location_id &&
      !cabinIssue?.aircraft_location_issues_attributes?.[0]?.position_x
    ) {
      dispatchErrorToast(formatMessage({ id: 'text.pleaseSelectASeat' }));
      return false;
    }
    if (
      cabinIssue?.aircraft_location_issues_attributes &&
      !cabinIssue?.aircraft_location_issues_attributes?.[0]?.issue_type_id
    ) {
      dispatchErrorToast(formatMessage({ id: 'text.pleaseSelectSeverity' }));
      return false;
    }
    if (!cabinIssue?.raised_at) {
      dispatchErrorToast(formatMessage({ id: 'text.pleaseSelectADate' }));
      return false;
    }
    if (!cabinIssue?.details || cabinIssue?.details?.length < 5) {
      dispatchErrorToast(formatMessage({ id: 'text.pleaseEnterDetails' }));
      return false;
    }
    return true;
  };

  const handleSaveClick = (): void => {
    if (validateCabinIssue()) {
      const payload: CabinIssuePayload = cabinIssue;
      payload.aircraft_id = id;
      if (payload?.aircraft_location_issues && !payload?.aircraft_location_issues_attributes) {
        payload.aircraft_location_issues_attributes = payload?.aircraft_location_issues;
      }
      if (!Array.isArray(payload?.aircraft_location_issues_attributes)) {
        payload.aircraft_location_issues_attributes = [payload?.aircraft_location_issues_attributes];
      }
      if (cabinIssue.id) {
        updateIssue.mutate(payload);
      } else {
        addCabinIssue.mutate(payload);
      }
      dispatch(changeDrawerMode({ payload: 'view' }));
    }
  };

  useEffect(() => {
    if (issue) {
      setCabinIssue(issue);
      setOriginalCabinIssue(issue);
    }
  }, [issue]);

  const isXYIssue = (passedIssue: CabinIssue): passedIssue is { x: number; y: number } =>
    'x' in passedIssue && 'y' in passedIssue;
  const isCabinIssue =
    (passedIssue: CabinIssue): passedIssue is CabinIssue => 'aircraft_location_issues' in passedIssue;

  useEffect(() => {
    // useeffect to set the cabin issue dependant on passed data
    if (issue) {
      if (isXYIssue(issue)) {
        const newCabinIssue = {
          aircraft_location_issues_attributes: [
            {
              position_x: selectedIssueLocation?.x,
              position_y: selectedIssueLocation?.y,
              aircraft_drawing_id: cabinIssueSVG?.id,
            } as Partial<AircraftLocationIssues>,
          ],
        } as Partial<CabinIssue>;
        setCabinIssue(newCabinIssue);
        setOriginalCabinIssue(newCabinIssue);
      } else {
        const locationGroups = cabinIssueSVG?.aircraft_location_groups;
        let foundGroup = null;
        const foundIssue = issue;
        for (const group of locationGroups) {
          if (selectedIssueLocation?.id?.startsWith(group?.name?.toLowerCase())) {
            foundGroup = group;
          }
          if (
            cabinIssues
              .find((item) => item.id === drawerId)
              ?.aircraft_location_issues?.filter((item) => item?.aircraft_location_id === group?.id)?.length > 0
          ) {
            foundGroup = group;
          }
        }
        let foundLocation = null;
        if (foundGroup) {
          for (const location of foundGroup?.aircraft_locations) {
            if (location?.element_identifier === selectedIssueLocation?.id) {
              foundLocation = location;
            }
          }
        }
        if (foundIssue && isCabinIssue(foundIssue)) {
          foundLocation = locationGroups
            ?.flatMap((outer) => outer?.aircraft_locations)
            ?.find((inner) => inner?.id === foundIssue.aircraft_location_issues[0]?.aircraft_location_id);
        }
        if (foundLocation || foundIssue) {
          let newCabinIssue;
          if (foundIssue && isCabinIssue(foundIssue)) {
            newCabinIssue = {
              ...newCabinIssue,
              ...foundIssue,
              aircraft_location_issues_attributes: foundIssue?.aircraft_location_issues,
            };
          } else if (
            cabinIssues?.find((cabIssue) =>
              cabIssue?.aircraft_location_issues?.find((iss) => iss?.aircraft_location_id === foundLocation?.id),
            )
          ) {
            newCabinIssue = {
              ...cabinIssues?.find((cabIssue) =>
                cabIssue?.aircraft_location_issues?.find((iss) => iss?.aircraft_location_id === foundLocation?.id),
              ),
            };
          } else if (foundLocation) {
            newCabinIssue = {
              ...cabinIssue,
              aircraft_location_issues_attributes: [
                {
                  ...cabinIssue.aircraft_location_issues,
                  aircraft_location_id: foundLocation.id,
                },
              ],
            };
          }
          setCabinIssue(newCabinIssue);
          setOriginalCabinIssue(newCabinIssue);
        }
      }
    } else {
      const firstLocation = cabinIssueSVG?.aircraft_location_groups[0]?.aircraft_locations[0];
      const newCabinIssue = {
        aircraft_location_issues_attributes: [
          {
            aircraft_location_id: firstLocation?.id,
          } as Partial<AircraftLocationIssues>,
        ],
      } as Partial<CabinIssue>;
      setCabinIssue(newCabinIssue);
      setOriginalCabinIssue(newCabinIssue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIssueLocation, drawerId]);

  return (
    <DrawerWrapper data-testid="CabinIssueDrawer--DrawerWrapper">
      <Title>
        <TitleText>{formatMessage({ id: 'text.cabinIssue' })}</TitleText>
        {mode === 'view' ? (
          <AuthDropdownMenu
            options={{
              read: false,
              update: true,
              delete: true,
              custom: mode === 'view',
              custom2: mode === 'view',
            }}
            menuStyle={{ right: 0, position: 'absolute', zIndex: 310 }}
            resource={AircraftResource.DEFECT}
            aircraftId={id}
            editCallback={(): void => handleEditClick()}
            handleDelete={(): void => handleDeleteCabinIssue()}
          />
        ) : null}
      </Title>
      <CabinIssueDetails cabinIssue={cabinIssue} updateCabinIssue={updateCabinIssue} />
      {mode !== 'view' ? (
        <ButtonSection handleCancelClick={handleCancelClick} handleSaveClick={handleSaveClick} />
      ) : null}
    </DrawerWrapper>
  );
};

export default CabinIssueDrawer;

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import styled from 'styled-components';
import servers from '../../utils/servers';
import { DashboardState } from '../../models';
import {
  changeDrawerMode,
  changeDrawerVisibility,
  changeModalVisibility,
  DrawerMode,
  setDrawerChanges
} from '../../models/drawer';
import { AircraftResource } from '../../models/aircraft';
import { DAMAGE_MAP_LOCATION_OPTIONS, DamageMap } from '../../models/damageMaps';
import AuthDropdownMenu from '../../components/AuthDropdownMenu/AuthDropdownMenu';
import useMutationAddDamageMap from '../../services/hooks/damageMaps/useMutationAddDamageMap';
import useMutationEditDamageMap from '../../services/hooks/damageMaps/useMutationEditDamageMap';
import useMutationDeleteDamageMap from '../../services/hooks/damageMaps/useMutationDeleteDamageMap';
import TFLoading from '../../components/TFLoading';
import TFInput, { ErrorState } from '../../components/TFInput/TFInput';
import TFRadioInput from '../../components/TFRadioInput/TFRadioInput';
import SchematicUpdateEditor from './SchematicUploadEditor';
import { makeBase64ToBlob, makeBlobUrlIntoBlob } from './utils';
import ThumbnailSection from './ThumbnailSection';

interface DamageMapDrawerProps {
  damageMapId?: string;
  damageMaps: DamageMap[];
}

const DrawerWrapper = styled.div`
  padding: 32px 48px;
  @media (max-width: 450px) {
    padding: 1rem;
  }
`;

const Title = styled.div`
  font-size: 18px;
  color: #242d41;
  margin-bottom: 24px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  span {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
`;

const SchematicImage = styled.img`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UploadButtonWrapper = styled.div`
  margin-right: 20px;
`;

const DamageMapDrawer: React.FC<DamageMapDrawerProps> = ({ damageMapId, damageMaps }) => {
  const { drawer: { mode } } = useSelector((state: DashboardState) => state);

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const [originalDamageMapData, setOriginalDamageMapData] = useState<DamageMap>();
  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [uploadedDamageMaps, setUploadedDamageMaps] = useState([]);
  const [newDamageMapData, setNewDamageMapData] = useState<DamageMap>();
  const [formError, setFormError] = useState<{ title?: ErrorState }>({});
  const [schematicsUploading, setSchematicsUploading] = useState(false);

  const handleOnSuccessEditDamageMap = () => {
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const handleOnSuccessAddSchematic = () => {
    setSchematicsUploading(false);
    setUploadedDamageMaps([]);
  };

  const handleOnErrorAddSchematic = () => {
    setSchematicsUploading(false);
  }

  const addSchematic = useMutationAddDamageMap({ handleOnSuccessAddSchematic, handleOnErrorAddSchematic });
  const editSchematic = useMutationEditDamageMap({ handleOnSuccessEditDamageMap });
  const deleteSchematic = useMutationDeleteDamageMap();

  const handleDamageMapDelete = () => {
    // depending on how delete is finished, this should not be damageMapId since you can select a different
    // map from this drawer making the damageMapId prop obsolete
    deleteSchematic.mutate(damageMapId);
    dispatch(changeDrawerVisibility({ payload: false }));
  };

  const handleDataChange = (changes: { value: string | boolean; key: string }[]) => {
    const newData = _.cloneDeep(newDamageMapData || {}) as DamageMap;
    changes.forEach(({ value, key }) => {
      if (key === 'title') {
        if (value && damageMaps.some((map) => map.title === value && map.id !== newDamageMapData?.id)) {
          setFormError({ title: { status: 'error', message: formatMessage({ id: 'text.titleAlreadyExists' }) } });
        } else {
          setFormError({});
        }
      }
      newData[key] = value;
    });
    setNewDamageMapData(newData);
  }

  const saveEditedSchematic = () => {
    const { attachment, ...damageMapWithoutAttachment } = newDamageMapData;
    const payload = {
      aircraft_id: id,
      ...damageMapWithoutAttachment,
    };

    editSchematic.mutate(payload);
  };

  const resetDamageMap = (damageMap: DamageMap) => {
    setOriginalDamageMapData(damageMap);
    setNewDamageMapData(damageMap);
  }

  const handleSchematicSave = () => {
    if (mode === DrawerMode.ADD) {
      const addedDamagedMaps = [...uploadedDamageMaps, newDamageMapData];
      setUploadedDamageMaps(addedDamagedMaps);
      resetDamageMap(null);
    } else {
      saveEditedSchematic();
    }
  }

  const handleUploadSchematics = async () => {
    setSchematicsUploading(true);
    uploadedDamageMaps.forEach(async (map) => {
      const blobObject = map.attachment.startsWith('blob')
        ? await makeBlobUrlIntoBlob(map.attachment)
        : makeBase64ToBlob(map.attachment.split(',')[1]);

      const formData = new FormData();
      if (map.fileName.endsWith('.jpeg') || map.fileName.endsWith('.jpg') || map.fileName.endsWith('.png')) {
        formData.append('attachment[attachment]', blobObject, map.fileName);
      } else {
        const newFileName = `${map.fileName}.png`;
        formData.append('attachment[attachment]', blobObject, newFileName);
      }
      formData.append('title', map.title);
      formData.append('category', map.category);

      const payload = {
        form: formData,
        id,
      };

      addSchematic.mutate(payload);
    });
  };

  const handleSchematicChange = (clickedDamageMap: DamageMap) => {
    resetDamageMap(clickedDamageMap);
  };

  const handleCancelClick = () => {
    if (newDamageMapData || uploadedDamageMaps.length > 0) {
      dispatch(changeModalVisibility({ payload: true }));
    } else {
      dispatch(changeDrawerVisibility({ payload: true }));
    }
  }

  useEffect(() => {
    if(damageMapId && damageMaps) {
      const foundDamageMap = damageMaps.find(damageMap => damageMap.id === damageMapId);
      resetDamageMap(foundDamageMap);
    }
  }, [damageMapId, damageMaps]);

  useEffect(() => {
    if (!_.isEqual(newDamageMapData, originalDamageMapData)) {
      const hasRequiredFields = !!(newDamageMapData?.title &&
        newDamageMapData?.category &&
        newDamageMapData?.attachment);
      setSaveButtonEnabled(hasRequiredFields && !formError?.title);

      const requiredFieldsEmpty = !newDamageMapData?.title &&
      !newDamageMapData?.category &&
      !newDamageMapData?.attachment;
      dispatch(setDrawerChanges({ payload: !requiredFieldsEmpty }));
    } else {
      setSaveButtonEnabled(false);
      dispatch(setDrawerChanges({ payload: false }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDamageMapData]);

  let title = 'Upload New Schematic';
  if (mode === DrawerMode.EDIT) title = 'Editing Schematic';

  return (
    <DrawerWrapper data-testid="DamageMapDrawer--Wrapper">
      <TFLoading loading={addSchematic.isLoading} contain />
      <Title data-testid="DamageMapDrawer--DrawerTitle">
        <span>{title}</span>
        {!!damageMapId && mode === DrawerMode.VIEW && (
          <AuthDropdownMenu
            options={{
              read: false,
              update: true,
              delete: true,
            }}
            menuStyle={{ right: 0, position: 'absolute', zIndex: 10 }}
            resource={AircraftResource.DAMAGE_MAP}
            aircraftId={id}
            handleDelete={handleDamageMapDelete}
            editCallback={() =>
              dispatch(changeDrawerMode({ payload: DrawerMode.EDIT }))
            }
          />
        )}
      </Title>
      <SectionWrapper data-testid="DamageMapDrawer--Title">
        <TFInput
          label="Title"
          id="DamageMap--Title"
          value={newDamageMapData ? newDamageMapData?.title : ''}
          onChange={(e) => handleDataChange([{ value: e.target.value, key: 'title' }])}
          status={formError?.title?.status}
          statusMessage={formError?.title?.message}
        />
      </SectionWrapper>
      <SectionWrapper data-testid="DamageMapDrawer--Location">
        <TFRadioInput
          options={DAMAGE_MAP_LOCATION_OPTIONS}
          id="DamageMapDrawer--Location"
          label="Location"
          value={newDamageMapData?.category}
          onChange={(value) => handleDataChange([{ value, key: 'category' }])}
        />
      </SectionWrapper>
      <SectionWrapper data-testid="DamageMapDrawer--Schematic">
        {mode === DrawerMode.ADD ? (
          <SchematicUpdateEditor
            damageMapData={originalDamageMapData}
            handleDataChange={handleDataChange}
            newDamageMapData={newDamageMapData}
            data-testid="DamageMapDrawer--SchematicUpdateEditor"
          />
        ) : originalDamageMapData && (
          <SchematicImage
            src={`${servers.api}/${originalDamageMapData.attachment?.url}`}
            alt="schematic"
            data-testid="DamageMapDrawer--SchematicImage"
          />
        )}
      </SectionWrapper>
      {mode !== DrawerMode.VIEW && (
        <SectionWrapper data-testid="DamageMapDrawer--SaveButtonWrapper">
          <Button height="30px" onClick={handleSchematicSave} disabled={!saveButtonEnabled}>
            {formatMessage({ id: 'text.save' })}
          </Button>
        </SectionWrapper>
      )}
      {(mode === DrawerMode.ADD && uploadedDamageMaps.length) ? (
        <ThumbnailSection
          data={uploadedDamageMaps}
        />
      ) : null}
      {(mode === DrawerMode.EDIT && damageMaps?.length) ? (
        <ThumbnailSection
          data={damageMaps}
          handleSchematicChange={handleSchematicChange}
        />
      ) : null}
      {mode === DrawerMode.ADD ? <ButtonWrapper data-testId="DamageMapDrawer--ButtonWrapper">
        <UploadButtonWrapper data-testid="DamageMapDrawer--UploadButtonWrapper">
          <Button
            height="30px"
            onClick={handleUploadSchematics}
            disabled={uploadedDamageMaps.length === 0 || newDamageMapData !== null || schematicsUploading}
          >
            {formatMessage({ id: 'text.upload' })}
          </Button>
        </UploadButtonWrapper>
        <Button height="30px" onClick={handleCancelClick}>{formatMessage({ id: 'text.cancel' })}</Button>
      </ButtonWrapper> : null}
    </DrawerWrapper>
  );
};

DamageMapDrawer.defaultProps = {
  damageMapId: null,
};

export default DamageMapDrawer;

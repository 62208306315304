import { useQuery } from '@tanstack/react-query';
import { queryMaintenanceForWorkpacks } from '../../../services/api';
import { Maintenance } from '../../../models/maintenance';

const useQueryMaintenanceForWorkpacks = ({ id }: { id: string }):
  { data: { body: Maintenance[] }; isLoading: boolean } => {
  const { data, isLoading } = useQuery({
    queryKey: ['queryMaintenanceForWorkpacks', id],
    queryFn: () => {
      return queryMaintenanceForWorkpacks({ aircraft_id: id, status: ['open', 'overdue'] });
    },
    enabled: !!id,
  });

  return { data, isLoading };
};

export default useQueryMaintenanceForWorkpacks;

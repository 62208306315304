import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import { CustomField } from '../../models/aircraft';
import { Label, Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import ToolTip from '../../components/NewTooltip/NewTooltip';
import { INSERT_AFTER_VALUES } from './CustomFieldsEditPositioning';

interface CustomFieldsViewPositioningProps {
  fieldDisplayArray: string[];
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const CustomFieldsViewPositioning: React.FC<CustomFieldsViewPositioningProps> = ({ fieldDisplayArray }) => {
  const {
    aircraft: { aircraftMap },
    drawer: { drawerId },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const [currentCustomFieldTitles, setCurrentCustomFieldTitles] = useState<{ title: string; value: string }[]>([]);

  const engineCount = aircraftMap.get(id).aircraft_type.engine_count;

  const buildEngineArray = (): { title: string; value: string }[] => {
    const engineArray = [];
    for (let i = 1; i <= engineCount; i += 1) {
      engineArray.push({ title: `Engine ${i}`, value: `engine_${i}` });
    }
    return engineArray;
  };

  const foundAircraft = aircraftMap.get(id);
  const customFieldsArray: CustomField[] = foundAircraft ? Object.values(foundAircraft?.custom_fields) : [];

  const foundCustomField = customFieldsArray?.find((field) => field?.key === drawerId);

  useEffect(() => {
    if (customFieldsArray.length) {
      const newArray = INSERT_AFTER_VALUES[foundCustomField?.view].concat(buildEngineArray());
      const aircraftCustomFieldTitles = customFieldsArray.map((field) => {
        return { title: field?.title, value: field?.key };
      });
      setCurrentCustomFieldTitles([...newArray, ...aircraftCustomFieldTitles]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundAircraft]);

  return (
    <Wrapper data-testid="CustomFieldsViewPositioning--Wrapper">
      <div data-testid="CustomFieldsViewPositioning--Order">
        <Label>
          {formatMessage({ id: 'text.order' })}
          <ToolTip text={formatMessage({ id: 'text.multipleItemsOnSameRowOrderDisplayedIn' })} />
        </Label>
        <Text>{foundCustomField?.order}</Text>
      </div>
      {fieldDisplayArray?.includes('process_order') && (
        <div data-testid="CustomFieldsViewPositioning--ProcessOrder">
          <Label>{formatMessage({ id: 'text.processOrder' })}</Label>
          <Text>{foundCustomField?.process_order}</Text>
        </div>
      )}
      <div data-testid="CustomFieldsViewPositioning--InsertAfter">
        <Label>
          {formatMessage({ id: 'text.insertAfter' })}
          <ToolTip text={formatMessage({ id: 'text.newFieldDisplayedAfterThisField' })} />
        </Label>
        <Text>
          {currentCustomFieldTitles?.find((item) => item.value === foundCustomField?.insert_after)?.title || ''}
        </Text>
      </div>
    </Wrapper>
  );
};

export default CustomFieldsViewPositioning;

import { useQuery } from '@tanstack/react-query';
import { queryCabinIssues } from '../../../services/api';
import { CabinIssue } from '../../../models/cabinIssues';

const useQueryCabinIssues = ({ id }: { id: string }):
  { data: { count: number; cabin_issues: CabinIssue[] }; isLoading: boolean } => {
  const { data, isLoading } = useQuery({
    queryKey: ['queryCabinIssues', id],
    queryFn: () => {
      return queryCabinIssues({ aircraft_id: id });
    },
    enabled: !!id,
  });

  return { data, isLoading };
};

export default useQueryCabinIssues;

import React from "react";
import styled from "styled-components";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

import {
  convertCoordinatesToPosition,
  convertPositionToCoordinates,
  Coordinates,
  MarkerOffset,
  ZoomDimensions
} from "./coordinates";

const MarkerWrapper = styled.div<{ zoomDimensions: ZoomDimensions }>`
  position: absolute;
  width: ${({ zoomDimensions }) => zoomDimensions.width}px;
  height: ${({ zoomDimensions }) => zoomDimensions.height}px;
`;

interface DraggableMarkerProps {
  children: React.ReactElement;
  coordinates: Coordinates;
  zoomDimensions: ZoomDimensions;
  markerOffset?: MarkerOffset;
  onCoordinatesChanged: (coordinates: Coordinates) => void;
  disabled?: boolean;
}

/**
 * Provides drag functionality for a child component that is bounded to the size of the parent component.
 * The component using {@link DraggableMarker} is responsible for updating the coordinates when the drag stops
 * via the {@link DraggableMarkerProps.onCoordinatesChanged} callback.
 *
 * @param children - React element to be made draggable
 * @param coordinates - Absolute coordinates {x, y} representing marker position
 * @param zoomDimensions - Current zoom level dimensions for scaling calculations
 * @param markerOffset - Optional offset to adjust marker positioning (defaults to {width: 0, height: 0})
 * @param onCoordinatesChanged - Callback fired when marker position changes, provides new coordinates
 * @param disabled - Determines if the marker can be dragged
 *
 * @example
 * <DraggableMarker
 *   coordinates={{ x: 100, y: 100 }}
 *   zoomDimensions={{ width: 1000, height: 1000 }}
 *   markerOffset={{ width: 10, height: 20 }}
 *   onCoordinatesChanged={(newCoords) => handleCoordinateUpdate(newCoords)}
 *   disabled={false}
 * >
 *   <MarkerIcon />
 * </DraggableMarker>
 */
const DraggableMarker: React.FC<DraggableMarkerProps> = ({
  children,
  coordinates,
  zoomDimensions,
  markerOffset,
  onCoordinatesChanged,
  disabled = false,
}) => {
  const position = convertCoordinatesToPosition(coordinates, zoomDimensions, markerOffset);

  const handleOnStart = (event: DraggableEvent) => {
    // prevents underlying draggable components from being dragged at the same time
    event.stopPropagation();
  };

  const handleOnStop = (_event: DraggableEvent, data: DraggableData) => {
    if (data.x === position.width && data.y === position.height) return;

    const updatedCoordinates = convertPositionToCoordinates(
      { width: data.x, height: data.y },
      zoomDimensions,
      markerOffset
    );

    onCoordinatesChanged(updatedCoordinates);
  };

  return (
    <MarkerWrapper zoomDimensions={zoomDimensions} data-testid='marker-wrapper'>
      <Draggable
        position={{ x: position.width, y: position.height }}
        bounds='parent'
        onStart={handleOnStart}
        onStop={handleOnStop}
        disabled={disabled}
      >
        {children}
      </Draggable>
    </MarkerWrapper>
  );
};

DraggableMarker.defaultProps = {
  markerOffset: { width: 0, height: 0 },
  disabled: false,
};

export default DraggableMarker;

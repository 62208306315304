import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { FilterSideBar } from '@arcflight/tf-component-library';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import { SortBy } from '../../models/userSettings';
import { DamageReport, INITIAL_DAMAGE_REPORT } from '../../models/damageReports';
import { addToast, ToastCategories, ToastTypes } from '../../models/toasts';
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import TableGridToggle from '../../components/TableGridToggle/TableGridToggle';
import Card from '../../components/DefectDrawer/components/Card';
import useQueryGetDamageReports from '../../services/hooks/damageMaps/useQueryGetDamageReports';
import useQueryGetDamageMaps from '../../services/hooks/damageMaps/useQueryGetDamageMaps';
import useQueryDamageReportsTotals from '../../services/hooks/damageReports/useQueryGetDamageReportsTotals';
import TFLoading from '../../components/TFLoading';
import { Coordinates } from '../../components/DraggableMarker/coordinates';
import DentAndBuckleTable from './DentAndBuckleTable';
import DentAndBuckleSchematics from './DentAndBuckleSchematics';
import DABTableHeader from './DABTableHeader';
import DamageMapDrawer from './DamageMapDrawer';
import damageReportFilterModel from './damageReportFilterModel';

export interface DamageReportFilters {
  category?: string[];
  type?: string[];
  status?: string[];
}

const Title = styled.div`
  font-size: 16px;
  color: #242d41;
  font-weight: 500;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  > span {
    width: 100%;
  }
  div,
  img,
  svg,
  button,
  input {
    box-sizing: revert !important;
    line-height: normal;
  }
`;

const FilterWrapper = styled.div`
  margin-left: 20px;
`;

const DentAndBuckle: React.FC = () => {
  const [tableView, setTableView] = useState(true);
  const [originalDamageReports, setOriginalDamageReports] = useState([]);
  const [currentMapId, setCurrentMapId] = useState<string>();
  const [newDamageReport, setNewDamageReport] = useState<DamageReport>(null);
  const [filters, setFilters] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<SortBy[]>([]);
  const [reset, setReset] = useState(false);

  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const { data: damageReportsData, isFetching: damageReportsLoading } = useQueryGetDamageReports({
    aircraft_id: id,
    page,
    limit,
    sortBy,
    searchValue,
    filters,
  });

  const { data: damageMapsData, isFetching: damageMapsLoading } = useQueryGetDamageMaps({ id });

  const { data: totals, isFetching: totalsLoading } = useQueryDamageReportsTotals(id);

  const filterTotalsLabel = {
    location: formatMessage({ id: 'text.location' }),
    status: formatMessage({ id: 'text.status' }),
    type: formatMessage({ id: 'text.type' }),
  }

  const [filterModel, setFilterModel] =
    useState(damageReportFilterModel({ groupLabels: filterTotalsLabel, totals }));

  const handleToggleChange = (value: boolean) => {
    setTableView(value);
  }

  const updateDamageReportArray = () => {
    setReset(false);
  };

  const addNewToast = (title: string, message: string, type: string) => {
    dispatch(addToast({
      payload: {
        title,
        message,
        type: type === 'success' ? ToastTypes.SUCCESS : ToastTypes.ERROR,
        category: type === 'success' ? ToastCategories.FLASH : ToastCategories.FLAG,
      },
    }));
  };

  const handleEditSchematic = () => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({
      payload: {
        content: <DamageMapDrawer damageMapId={currentMapId} damageMaps={damageMapsData} />
      }
    }));
    dispatch(changeDrawerMode({ payload: 'edit' }));
  };

  const handleNewDamageReport = (resetSchematic?: boolean) => {
    // we only allow one new damage report to be created at a time when the map is selected
    if (newDamageReport) {
      addNewToast(
        formatMessage({ id: 'text.damageReportNotSaved' }),
        formatMessage({ id: 'text.damageReportNotSavedMessage' }),
        'error'
      );
      return;
    }
    let newCurrentMapId = currentMapId;
    if (!newCurrentMapId || resetSchematic) {
      setCurrentMapId(damageMapsData[0].id);
      newCurrentMapId = damageMapsData[0].id;
    }
    if (tableView) setTableView(false);

    setNewDamageReport({
      ...INITIAL_DAMAGE_REPORT,
      damage_map_id: newCurrentMapId,
    });
  };

  const handleReportCoordinatesChanged = (targetReport: DamageReport, coordinates: Coordinates) => {
    // we only allow the new damage report to be moved
    if (targetReport.id !== null) return;

    setNewDamageReport(prevReport => ({
      ...prevReport,
      x_coordinate: coordinates.xCoordinate,
      y_coordinate: coordinates.yCoordinate,
    }));
  };

  const handleSortChange = (value: SortBy[]) => {
    setSortBy(value);
  };

  useEffect(() => {
    if (totals) {
      setFilterModel(damageReportFilterModel({ groupLabels: filterTotalsLabel, totals }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals]);

  useEffect(() => {
    if (damageReportsData?.length && !originalDamageReports?.length) {
      setOriginalDamageReports(damageReportsData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damageReportsData]);

  useEffect(() => {
    // when the damage report query is invalidated, reset the new damage report so it doesn't appear twice
    setNewDamageReport(null);
  }, [damageReportsData]);

  useEffect(() => {
    if (newDamageReport) {
      const updatedReport = {
        ...newDamageReport,
        damage_map_id: currentMapId,
      };
      setNewDamageReport(updatedReport);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMapId]);

  return (
    <InnerMenuLayout>
      <HeaderWrapper>
        <Title>{formatMessage({ id: 'text.dentAndBuckle' })}</Title>
        <div>
          <TableGridToggle handleToggleChange={handleToggleChange} tableStyleSelected={tableView} reverseLayout />
        </div>
      </HeaderWrapper>
      <Card>
        <>
          <TFLoading loading={damageReportsLoading || damageMapsLoading || totalsLoading} />
          <DABTableHeader
            tableView={tableView}
            damageMapsData={damageMapsData}
            handleEditSchematic={handleEditSchematic}
            setCurrentMapId={setCurrentMapId}
            handleNewDamageReport={handleNewDamageReport}
            setSearchValue={setSearchValue}
            reset={reset}
          />
          {tableView ? (
            <TableWrapper>
              <DentAndBuckleTable
                data={damageReportsData || []}
                damageMapsData={damageMapsData}
                total={totals?.total}
                pageIndex={page - 1}
                pageSize={limit}
                onPaginationChange={(currentPage, numberOfItems): void => {
                  if (numberOfItems !== limit) {
                    setPage(1);
                    setLimit(numberOfItems);
                  } else if (currentPage !== page) {
                    setPage(currentPage);
                  }
                }}
                handleSortChange={(sort) => handleSortChange(sort)}
                aircraftId={id}
              />
              <FilterWrapper>
                <FilterSideBar
                  data={[]}
                  updateArray={updateDamageReportArray}
                  filterGroups={filterModel}
                  reset={reset}
                  onChange={(e) => {
                    if (e.length === 0) {
                      setFilters({ category: [], status: [], type: [] });
                    } else {
                      const newFilters = e.reduce((newObject, object) => {
                        const workingObject = newObject;
                        if (!workingObject[object.key]) {
                          workingObject[object.key] = [object.value];
                        } else {
                          workingObject[object.key].push(object.value);
                        }
                        return workingObject;
                      }, {});
                      setFilters(newFilters);
                    }
                  }}
                />
              </FilterWrapper>
            </TableWrapper>
          ) : (
            <DentAndBuckleSchematics
              damageMaps={damageMapsData}
              newDamageReport={newDamageReport}
              currentMapId={currentMapId}
              onReportCoordinatesChanged={handleReportCoordinatesChanged}
              aircraftId={id}
            />
          )}
        </>
      </Card>
    </InnerMenuLayout>
  )
};

export default DentAndBuckle;

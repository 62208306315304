import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { removeDamageMap } from "../../api";
import { addToast, ToastCategories, ToastTypes } from "../../../models/toasts";
import { DamageMap } from "../../../models/damageMaps";

const useMutationDeleteDamageMap = (): UseMutationResult<DamageMap, Error, string, unknown> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const deleteMap = useMutation({
    mutationFn: (id: string) => removeDamageMap(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['damageMaps'] });
      queryClient.invalidateQueries({ queryKey: ['damageReports'] });
      queryClient.invalidateQueries({ queryKey: ['damage_reports_totals'] });
      dispatch(
        addToast({
          payload: {
            title: 'Damage map deleted',
            message: 'Damage map has been deleted successfully',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
    },
    onError: (error: Error) => {
      dispatch(
        addToast({
          payload: {
            title: 'Damage map deletion failure',
            message: error.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    },
  });

  return deleteMap;
};

export default useMutationDeleteDamageMap;

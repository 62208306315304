import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { updateDamageReport } from '../../api';
import { addToast, ToastCategories, ToastTypes } from '../../../models/toasts';
import { Attachment, DamageReport } from '../../../models/damageReports';

interface UseMutationEditDamageReportProps {
  handleOnSuccessEditReport: () => void;
}

interface Payload {
  id: string;
  type: string;
  details: string;
  attachment_attributes: Attachment[];
}

const useMutationEditDamageReport =
  ({ handleOnSuccessEditReport }: UseMutationEditDamageReportProps):
  UseMutationResult<DamageReport, Error, unknown, unknown> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const editReport = useMutation({
    mutationFn: (payload: Payload) => updateDamageReport(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['damageReports'] });
      queryClient.invalidateQueries({ queryKey: ['damage_reports_totals'] });
      handleOnSuccessEditReport();
      dispatch(
        addToast({
          payload: {
            title: 'Damage report updated',
            message: 'Damage report was successfully updated',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          }
        })
      )
    },
    onError: (err: Error) => {
      dispatch(
        addToast({
          payload: {
            title: 'Damage report updated',
            message: err.message,
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          }
        })
      )
    },
  });

  return editReport;
};

export default useMutationEditDamageReport;

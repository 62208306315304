import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Magnifier from '../../components/Magnifier';
import useFetchImage from '../../services/hooks/attachments/useFetchImage';
import TFLoading from '../../components/TFLoading';
import { DamageMap } from '../../models/damageMaps';
import { DamageReport } from '../../models/damageReports';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility, DrawerMode } from '../../models/drawer';
import DamageReportMarker from '../../components/DamageReportMarker/DamageReportMarker';
import { Coordinates } from '../../components/DraggableMarker/coordinates';
import useQueryGetDamageReports from '../../services/hooks/damageMaps/useQueryGetDamageReports';
import DamageReportDrawer from './DamageReportDrawer';

interface DentAndBuckleProps {
  damageMaps: DamageMap[];
  newDamageReport: DamageReport;
  currentMapId: string;
  onReportCoordinatesChanged: (report: DamageReport, coordinates: Coordinates) => void;
  aircraftId: string;
}

const MaginfierWrapper = styled.div`
  position: relative;
`;

const DentAndBuckleSchematics: React.FC<DentAndBuckleProps> = ({
  damageMaps,
  newDamageReport,
  currentMapId,
  onReportCoordinatesChanged,
  aircraftId,
}) => {
  const [zoomedHeight, setZoomedHeight] = useState<number>(null);
  const [zoomedWidth, setZoomedWidth] = useState<number>(null);
  const [currentImageId, setCurrentImageId] = useState(null);
  const [foundReports, setFoundReports] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);

  const dispatch = useDispatch();

  const { data: imageData, isFetching } = useFetchImage({ id: currentImageId });

  const { data: damageReportsData, isFetching: damageReportsLoading } = useQueryGetDamageReports({
    aircraft_id: aircraftId,
    page: 1,
    limit: 500,
    sortBy: [],
    searchValue: '',
    filters: {
      category: [currentCategory],
    },
  });

  const handlePointClick = (report: DamageReport) => {
    const drawerMode = report.id ? DrawerMode.VIEW : DrawerMode.ADD;

    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerMode({ payload: drawerMode }));
    dispatch(changeDrawerContent({
      payload: { content: <DamageReportDrawer damageReport={report} damageMaps={damageMaps} /> },
    }));
  };

  const displayCoordinates = () => {
    if (!foundReports) return null;
    const coordinatePoints = foundReports.map((report) => {
      return (
        <DamageReportMarker
          report={report}
          zoomDimensions={{ width: zoomedWidth, height: zoomedHeight }}
          handlePointClick={handlePointClick}
          onReportCoordinatesChanged={onReportCoordinatesChanged}
          draggable={!report.id}
        />
      )
    });
    return coordinatePoints;
  };

  useEffect(() => {
    if (damageMaps?.length && currentMapId) {
      const currentMap = damageMaps.find(map => map.id === currentMapId);
      const mapImageId = currentMap?.attachment?.id;
      setCurrentImageId(mapImageId);
      setCurrentCategory(currentMap?.category);
    }
  }, [currentMapId, damageMaps]);

  useEffect(() => {
    if (damageReportsData && currentMapId) {
      const newReports = damageReportsData.filter((report) => report.damage_map_id === currentMapId);
      if (newDamageReport) {
        newReports.push(newDamageReport);
      }
      setFoundReports(newReports);
    }
  }, [currentMapId, damageReportsData, newDamageReport]);

  return (
    <div>
      {isFetching ? (
        <TFLoading loading={isFetching || damageReportsLoading} />
      ) : (
        <MaginfierWrapper>
          {imageData ? (
            <Magnifier
              file={imageData}
              height={window.innerHeight < 451 ? 300 : 500}
              getMagnifierImageDetails={(width, height) => {
                setZoomedHeight(height);
                setZoomedWidth(width);
              }}
              displayCoordinates={displayCoordinates}
            />
          ): null}
        </MaginfierWrapper>
      )}
    </div>
  );
};

export default DentAndBuckleSchematics;

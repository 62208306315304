import { BaseModel } from '..';

const initialState = {
  airportsMap: new Map(),
  lastFetched: 0,
};

export enum AirportsActionTypes {
  SAVE_ALL = 'airports/saveAll',
}

export interface SaveAllAirportsActionTypes {
  type: AirportsActionTypes.SAVE_ALL;
  payload: Airport[];
}

export default function (state = initialState, action: SaveAllAirportsActionTypes): AirportsState {
  switch (action.type) {
    case AirportsActionTypes.SAVE_ALL: {
      const airportsMap = new Map(state.airportsMap.entries());
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((airport) => {
          airportsMap.set(airport.ident, {
            ...airportsMap.get(airport.ident),
            ...airport,
          });
        });
      }
      return {
        ...state,
        airportsMap,
        lastFetched: Date.now(),
      };
    }
    default:
      return state;
  }
}

export interface AirportsState {
  airportsMap: Map<string, Airport>;
  lastFetched: number;
}

export interface Airport extends BaseModel {
  id: number;
  ident: string;
  country: string;
  airport_type: string;
  name: string;
  latitude: string;
  longitude: string;
  elevation: number;
  continent: string;
  region: string;
  municipality: string;
  scheduled_service: true;
  gps_code: string;
  iata_code: string;
  local_code: unknown;
  website: string;
  wikipedia: string;
  keywords: string;
  timezone: unknown;
  runways: AirportRunway[];
}

export interface AirportRunway {
  id: number;
  airport_id: number;
  length: number;
  width: number;
  surface: string;
  lit: true;
  closed: false;
  low_ident: string;
  high_ident: string;
  low_latitude: string;
  low_longitude: unknown;
  low_elevation: number;
  low_heading: string;
  low_displaced_threshold: number;
  high_latitude: string;
  high_longitude: string;
  high_elevation: number;
  high_heading: string;
  high_displaced_threshold: unknown;
}

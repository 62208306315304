import React, { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from '@arcflight/tf-component-library';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import { getPublicShareURL } from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';

interface CRSButtonProps {
  crsItemId: string;
  status: string;
  type: 'scheduled_mx_items' | 'defects' | 'workpack';
  passedButtonText?: string | ReactElement;
}
const StyledLinkButton = styled.button`
  padding: 0;
  border: none;
  background: transparent;
  display: flex;
  color: ${({ theme }): string => theme.colours.blue};
  cursor: pointer;
`;

const CRSButton: React.FC<CRSButtonProps> = ({ crsItemId, status, type, passedButtonText }) => {
  const [publicLinkLoading, setPublicLinkLoading] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const crsShortName = useGetOperatorSetting('crs_short_name', id) as string;
  const approvalReleaseMode = useGetOperatorSetting('approval_release_mode', id) as string;

  const getPublicUrlLink = async (): Promise<void> => {
    setPublicLinkLoading(true);
    try {
      const url = await getPublicShareURL({ type, id: crsItemId });
      if (url) {
        window.open(url, '_blank');
      }
    } catch (err) {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.failedToGetCRS' }, { field: crsShortName }),
            message: err.message || formatMessage({ id: 'text.thereWasAnIssueWithNetwork' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
          },
        }),
      );
    } finally {
      setPublicLinkLoading(false);
    }
  };

  let buttonText = formatMessage({ id: 'form.button.addCRS' }, { field: crsShortName });
  if (approvalReleaseMode === 'after_any_work_completed_release') {
    buttonText = formatMessage({ id: 'form.button.submitResolution' });
    if (status === 'resolved') buttonText = formatMessage({ id: 'form.button.addCRS' }, { field: crsShortName });
  } else if (status === 'resolved') {
    buttonText = formatMessage({ id: 'form.button.viewCRS' }, { field: crsShortName });
  }
  if (publicLinkLoading) {
    return <Loading loading={publicLinkLoading} contain hasTransparentBackground size={20} />;
  }
  return (
    <StyledLinkButton data-testid="CRSButton" onClick={() => getPublicUrlLink()}>
      {passedButtonText || buttonText}
    </StyledLinkButton>
  );
};

CRSButton.defaultProps = {
  passedButtonText: '',
};

export default CRSButton;

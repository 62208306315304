import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import GridIconDefault from '../../assets/icon-grid-view-default.svg';
import GridIconSelected from '../../assets/icon-grid-view-selected.svg';
import TableIconDefault from '../../assets/icon-table-view-default.svg';
import TableIconSelected from '../../assets/icon-table-view-selected.svg';

interface TableGridToggleProps {
  handleToggleChange: (value: boolean) => void;
  tableStyleSelected: boolean;
  reverseLayout?: boolean;
}

const ToggleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const StyledImage = styled.img`
  cursor: pointer;
`;

const TableGridToggle: React.FC<TableGridToggleProps> = ({ handleToggleChange, tableStyleSelected, reverseLayout }) => {
  const { formatMessage } = useIntl();

  return (
    <ToggleWrapper>
      <span>{formatMessage({ id: 'text.toggleView' })}</span>
      {reverseLayout && (
        <StyledImage
          onClick={() => {
            handleToggleChange(true)
          }}
          src={tableStyleSelected ? TableIconSelected : TableIconDefault}
        />
      )}
      <StyledImage
        onClick={() => {
          handleToggleChange(false)
        }}
        src={tableStyleSelected ? GridIconDefault : GridIconSelected}
      />
      {!reverseLayout && (
        <StyledImage
          onClick={() => {
            handleToggleChange(true)
          }}
          src={tableStyleSelected ? TableIconSelected : TableIconDefault}
        />
      )}
    </ToggleWrapper>
  )
};

TableGridToggle.defaultProps = {
  reverseLayout: false,
};

export default TableGridToggle;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { DashboardState } from '../../../models';
import { changeDrawerContent, changeDrawerMode, handleBackButtonClick, setDrawerId } from '../../../models/drawer';
import BackButton from '../../BackButton';
import { Defect } from '../../../models/defects';
import useGetOperatorSetting from '../../../utils/useGetOperatorSetting';
import { AircraftPermission, AircraftResource } from '../../../models/aircraft';
import AuthDropdownMenu from '../../AuthDropdownMenu/AuthDropdownMenu';
import { Label, VerticalDivider } from '../../CommonStyledComponents/CommonStyledComponents';
import FlightDrawer from '../../FlightDrawer/FlightDrawer';
import { Flight } from '../../../models/flights';
import DocumentLink from '../../DocumentLink/DocumentLink';
import { stringToTitleCase } from '../../../utils/utils';
import { AircraftAuthenticationWrapper } from '../../_utils/AuthenticationWrapper';
import TFLoading from '../../TFLoading';
import CRSButton from '../../CRSButton/CRSButton';
import StatusBadge from '../../Status/StatusBadge';
import PublicShareLink from '../../PublicShareLink/PublicShareLink';
import invalidIcon from '../../../assets/invalid-name.svg';
import chevron from '../../../assets/go-arrow.svg';
import { displayThirdPartyReferences, useDrawerBanner } from './utils';

interface ViewDefectDrawerHeaderProps {
  defectData: Defect;
  aircraftId: string;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  flightsData: Flight[];
  isLoadingFlights: boolean;
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
`;

const DropdownWrapper = styled.div`
  margin-left: 10px;
`;

const HeaderInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
  margin-bottom: 20px;
  @media (max-width: 781px) {
    flex-wrap: wrap;
  }
`;

const StyledButton = styled.button`
  color: #126fd6;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  padding: 0;
`;

const FlightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLinkWrapper = styled.div`
  border-left: ${({ tripId }): string => (tripId ? 'solid 1px rgba(36, 45, 65, 0.2)' : '')};
  padding-left: 6px;
  margin-left: 5px;
  position: relative;
  min-width: ${({ res }): string => (res ? '125px' : '25px')};
  a {
    color: #126fd6;
    flex-shrink: 1;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
`;

const PublicLinkWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 40px;
`;

const IconWrapper = styled.div`
  background-color: #ef5350;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0;

  img {
    width: 4px;
    height: 16px;
  }
`;

const BannerWrapper = styled.div`
  display: flex;
  align-items: stretch;
  background-color: #fce8e8;
  border: 1px solid #ef5350;
  color: #ef5350;
  padding: 0;
  margin: 12px 0;
  border-radius: 4px;
  font-weight: 500;
  min-height: 48px;
`;

const MessageWrapper = styled.div`
  padding: 12px 12px 12px 0;
  display: flex;
  align-items: center;
`;

const LinkButtonText = styled.span`
  color: ${({ theme }): string => theme.colours.blue};
  display: inline-flex;
  align-items: center;

  img {
    margin: 4px 0 0 4px;
    vertical-align: middle;
  }
`;

const CRSButtonWrapper = styled.div`
  padding: 12px 12px 12px 0;
  min-width: 130px;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const ViewDefectDrawerHeader: React.FC<ViewDefectDrawerHeaderProps> = ({
  defectData,
  aircraftId,
  updateDefectData,
  flightsData,
  isLoadingFlights,
}) => {
  const {
    drawer: { drawerHistory, editResolved },
    userSettings,
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const defectRename = useGetOperatorSetting('defect_name', id);
  const resolutionRename = useGetOperatorSetting('resolution_name', id);

  const aircraft = aircraftMap.get(aircraftId);
  const opId = aircraft?.operator_id;
  const isAdmin =
    userSettings?.details?.people.find((person) => person.organisation.id === opId)?.position === 'Admin';
  const editResolvedLocal = defectData?.status === 'resolved' && isAdmin;
  const chosenFlight = flightsData?.find((flight) => flight.id === defectData?.flight_id);

  const handleDeferDefect = (): void => {
    dispatch(changeDrawerMode({ payload: 'edit' }));
    const newDisplayData = {
      ...defectData?.display_data,
      category: null,
    };
    updateDefectData([{ value: true, key: 'deferred' }, { value: newDisplayData, key: 'display_data' }]);
  };

  const displayFlightInfo = () => {
    if (chosenFlight) {
      return `${chosenFlight.sector_number} ${`(Trip #${chosenFlight.trip?.number})`}`;
    }
    return '-';
  };

  const displayDefectStatus = (status: string): string => {
    if (!status) return '-';
    if (status === 'deferral_pending') {
      return formatMessage({ id: 'text.deferralPending' });
    }
    if (status === 'resolution_pending') {
      return formatMessage({ id: 'text.resolutionPending' }, { field: resolutionRename });
    }
    return stringToTitleCase(status);
  };

  const closurePending = defectData?.resolution_pending;
  const deferralPendingStatus = defectData?.status === 'deferral_pending';
  const displayCRSButtons = !closurePending && !deferralPendingStatus;

  let display3DotMenu = true;
  if ((defectData?.status === 'resolved' && editResolvedLocal) && !editResolved) display3DotMenu = false;
  if (closurePending) display3DotMenu = false;

  let defectStatus = defectData?.status;
  if (defectData?.resolution_pending) {
    defectStatus = 'resolution_pending';
  }

  const linkedMxItem = defectData?.linked_scheduled_mx_item_with_m_procedure;
  const linkedMxItemId = linkedMxItem?.id;
  const linkedMxItemStatus = linkedMxItem?.status;
  const isLinkedMxNotResolved = linkedMxItemId && linkedMxItemStatus !== 'resolved';

  const renderMaintenanceProcedureBanner = () => {
    if (!deferralPendingStatus || !linkedMxItem || !linkedMxItemId) {
      return null;
    }

    let messageId;
    const linkedMxItemIsApprovalRequired = linkedMxItem?.approval_release_required;
    const linkedMxItemHasApprovalRelease = linkedMxItem?.has_approval_release;
    if (isLinkedMxNotResolved) {
      messageId = 'text.deferralPendingMaintenanceProcedureUncompleted';
    } else if (!isLinkedMxNotResolved && linkedMxItemIsApprovalRequired && !linkedMxItemHasApprovalRelease) {
      messageId = 'text.deferralPendingMaintenanceProcedureRequiringApproval';
    } else {
      return null;
    }

    return (
      <BannerWrapper data-testid="maintenance-procedure-banner">
        <IconWrapper>
          <img src={invalidIcon} alt="Warning" />
        </IconWrapper>
        <MessageWrapper>
          {formatMessage({ id: messageId })}
        </MessageWrapper>
        {isLinkedMxNotResolved && !linkedMxItemIsApprovalRequired && (
          <CRSButtonWrapper>
            <CRSButton
              crsItemId={linkedMxItemId}
              status={linkedMxItemStatus}
              type="scheduled_mx_items"
              passedButtonText={
                <LinkButtonText>
                  {formatMessage({ id: 'text.addCRSToMaintenance' })}
                  <img src={chevron} alt="chevron" />
                </LinkButtonText>
              }
            />
          </CRSButtonWrapper>
        )}
      </BannerWrapper>
    );
  };

  return (
    <div data-testid="ViewDefectDrawerHeader">
      <HeaderWrapper>
        {drawerHistory?.length ? <BackButton onClick={() => dispatch(handleBackButtonClick())} /> : null}
        <Header data-testid="ViewDefectDrawerHeader--Header">
          <span>
            {defectRename}: <strong>{`${defectData?.number}`}</strong>
          </span>
        </Header>
        {displayThirdPartyReferences(defectData)}
        {display3DotMenu ? (
          <DropdownWrapper>
            <AuthDropdownMenu
              options={{
                read: false,
                update: true,
                delete: false,
                custom: !defectData?.deferred,
              }}
              menuStyle={{ right: 0, position: 'absolute', zIndex: 10 }}
              resource={AircraftResource.DEFECT}
              aircraftId={aircraftId}
              customText="Defer Defect"
              customCallback={(): any => handleDeferDefect()}
              editCallback={(): any =>
                dispatch(changeDrawerMode({ payload: defectData?.status === 'resolved' ? 'editResolved' : 'edit' }))
              }
              noOffset
            />
          </DropdownWrapper>
        ) : null}
      </HeaderWrapper>
      {renderMaintenanceProcedureBanner()}
      {useDrawerBanner(defectData)}
      <HeaderInfoWrapper>
        {document.location.pathname === '/defects' ? (
          <div data-testid="ViewDefectDrawerHeader--AircraftSection">
            <Label>{formatMessage({ id: 'title.aircraft' })}</Label>
            <div>{aircraft?.registration}</div>
          </div>
        ) : null}
        <div data-testid="ViewDefectDrawerHeader--SectorSection">
          <Label>{formatMessage({ id: 'title.sector' })}</Label>
          {chosenFlight ? (
            <FlightWrapper>
              <StyledButton
                type="button"
                onClick={(): void => {
                  dispatch(changeDrawerContent({ payload: { content: <FlightDrawer /> } }));
                  dispatch(setDrawerId({ payload: chosenFlight?.id }));
                }}
              >
                {displayFlightInfo()}
              </StyledButton>
              <VerticalDivider />
              <DocumentLink id={chosenFlight?.trip?.id} aircraftId={aircraftId} docType="srp" />
            </FlightWrapper>
          ) : (
            '-'
          )}
        </div>
        <div data-testid="ViewDefectDrawerHeader--DateRecordedSection">
          <Label>{formatMessage({ id: 'text.dateRecorded' })}</Label>
          <div>
            {defectData?.raised_at
              ? moment(defectData.raised_at).utc().format(`${userSettings.dateFormat} HH:mm`)
              : '-'}
          </div>
        </div>
        <div data-testid="ViewDefectDrawerHeader--StatusSection">
          <Label>{formatMessage({ id: 'text.status' })}</Label>
          <StatusWrapper>
            <StatusBadge status={defectStatus} />
            {displayDefectStatus(defectStatus)}
            {displayCRSButtons && (
              <StyledLinkWrapper>
                <AircraftAuthenticationWrapper
                  aircraftId={defectData?.aircraft?.id}
                  requiredResource={AircraftResource.RELEASE}
                  requiredPermissionLevel={
                    defectData?.status === 'resolved' ? AircraftPermission.READ : AircraftPermission.CREATE
                  }
                >
                  <TFLoading loading={isLoadingFlights} contain height={20} width={20} />
                  <CRSButton crsItemId={defectData?.id} status={defectData?.status} type="defects" />
                </AircraftAuthenticationWrapper>
              </StyledLinkWrapper>
            )}
          </StatusWrapper>
        </div>
        {defectData?.id && displayCRSButtons ? (
          <PublicLinkWrapper data-testid="ViewDefectDrawerHeader--PublicLinkSection">
            <PublicShareLink type="defects" id={defectData?.id} />
          </PublicLinkWrapper>
        ) : null}
      </HeaderInfoWrapper>
    </div>
  );
};

export default ViewDefectDrawerHeader;
